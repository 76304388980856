















































import { Component, Vue } from 'vue-property-decorator';
import ActionMenu from '@/components/molecules/ActionMenu.vue';
import Navigation from '@/components/Navigation.vue';
import { mapActions } from 'vuex';
import * as api from '@/api/spotty.ts';
import { SpottyVersion } from '@/domain/SpottyContract/SpottyVersion';
import { VersionUpForm } from './SpottyVersionUpForm';
import * as sessionStore from '@/stores/session';

@Component({
  components: {
    ActionMenu,
    Navigation,
  },
  methods: mapActions('alertArea', ['success', 'fail']),
})
export default class SpottyVersionUp extends Vue {
  private actionMenu = [
    {
      icon: 'arrow_back',
      text: '一覧に戻る',
      color: '',
      click: () => {
        this.returnList();
      },
    },
  ];

  private vm = new VersionUpForm();

  private success!: (message: string) => void;
  private fail!: (message: string) => void;

  private async created() {
    await this.reload();
  }

  private async reload() {
    const id = parseInt(this.$route.params.id, 10);
    const model = await api.detail(sessionStore.getToken(), id);
    this.vm = new VersionUpForm(
      model.spottyId,
      model.endUser.customerName,
      model.version.versionLabel,
      model.version.updateDate.substr(0, 10)
    );
  }
  private async submitVersion() {
    const model = new SpottyVersion(
      this.vm.updateVersion,
      this.vm.updateDate + 'T00:00:00Z'
    );
    try {
      await api.updateVersion(sessionStore.getToken(), this.vm.spottyId, model);
      this.success('バージョンを更新しました');
    } catch {
      this.fail('バージョンの更新に失敗しました');
    }

    await this.reload();
  }

  private returnList() {
    this.$router.push('../list');
  }
}
