export const RESERVED = 'reserved';
export const RESERVED_LABEL = '固定';
export const DEMO_BASIC = 'demo-basic';
export const DEMO_BASIC_LABEL = '個別';
export const DEMO_SPECIAL = 'demo-special';
export const DEMO_SPECIAL_LABLE = '特別';

export type DemoEnvironmentRole = 'reserved' | 'demo-basic' | 'demo-special';

export const ROLES_STLYES = [
  {
    value: DEMO_BASIC,
    label: DEMO_BASIC_LABEL,
    color: 'default',
  },
  {
    value: DEMO_SPECIAL,
    label: DEMO_SPECIAL_LABLE,
    color: 'default',
  },
  {
    value: RESERVED,
    label: RESERVED_LABEL,
    color: 'default',
  },
];

export function getRoleStyle(role: string) {
  const style = ROLES_STLYES.filter(item => {
    return item.value == role;
  });
  if (style.length != 0) return style[0];
  return {
    value: DEMO_BASIC,
    label: DEMO_BASIC_LABEL,
    color: 'default',
  };
}
