






























































import { Component, Vue, Prop, Watch, Inject } from 'vue-property-decorator';
import { ListCriteria } from './Types';
import { DemoEnvironment, DemoLending } from '@/domain/DemoEnv/Types';
import * as envstatus from '@/domain/DemoEnv/EnvironmentStatus';
import * as Table from '../../table';
import { ROLES_STLYES } from '@/domain/DemoEnv/Environment';

@Component
export default class EnvironmentListCriteria extends Vue {
  @Prop({ type: Object, required: true })
  criteria!: Table.EnvironmentListCriteria;
  @Prop({ type: Array, required: true })
  private demoEnvironments!: DemoEnvironment[];

  private roleStyles = ROLES_STLYES;
  private role!: string;
  private environmentStatusItems: { [key: string]: string | number }[] = [];
  private classPrefix = ListCriteria.classPrefix;

  private get items(): { [key: string]: string }[] {
    const filteredListIndependOnStatus = Table.environmentsFilter(
      this.demoEnvironments,
      'all',
      this.criteria.envName,
      this.criteria.envRole
    );
    return filteredListIndependOnStatus.map((env: DemoEnvironment) => {
      return {
        value: env.name,
        text: env.name,
      };
    });
  }
  private countEnvStatus(status: envstatus.EnvironmentStatus | 'all') {
    const filteredListIndependOnStatus = Table.environmentsFilter(
      this.demoEnvironments,
      'all',
      this.criteria.envName,
      this.criteria.envRole
    );
    if (status == 'all') {
      if (filteredListIndependOnStatus.length == 0) return '0';
      return filteredListIndependOnStatus.length;
    }

    const envs = filteredListIndependOnStatus.filter(env => {
      return env.environmentStatus == status;
    });
    if (envs.length == 0) return '0';
    return envs.length;
  }
  @Watch('demoEnvironments')
  private updateStatusItems() {
    this.environmentStatusItems.length = 0;
    envstatus.ENVIRONMENT_STATUS_ITEMS.forEach(item => {
      this.environmentStatusItems.push(
        Object.assign(item, {
          count: this.countEnvStatus(item.value as envstatus.EnvironmentStatus),
        })
      );
    });
    this.environmentStatusItems.push({
      label: '全て',
      value: 'all',
      count: this.countEnvStatus('all'),
    });
  }
  private envNameChange(envName: string) {
    this.$set(this.criteria, 'envName', envName);
    this.update();
  }
  @Inject('openDemoEnvironment')
  private clearDemoEnvironment!: (id: number) => Promise<void>;
  @Inject('openDemoLending')
  private clearDemoLending!: (lending: DemoLending) => Promise<void>;
  private lending: DemoLending = new DemoLending();
  private update() {
    this.$emit('input', this.criteria);
    this.updateStatusItems();
    this.clearDemoEnvironment(0);
    this.lending.demoLendingId = 0;
    this.clearDemoLending(this.lending);
  }
  private created() {
    this.updateStatusItems();
    this.update();
  }
}
