






































































































import { Component, Prop, Watch, Vue, Inject } from 'vue-property-decorator';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import * as types from './Types';
import ActionMenu from '@/components/molecules/ActionMenu.vue';
import LendingStatusChip from '@/components/demoenv/LendingStatusChip.vue';
import { Account } from '@/domain/Account';
import * as api from '@/api/demoenv';
import * as sessionStore from '@/stores/session';
import { DemoLending } from '@/domain/DemoEnv/Types';
import { form2modelLending } from '@/views/demoenv/form2model';
import { model2formLending } from '@/views/demoenv/model2form';
import * as lendstatus from '@/domain/DemoEnv/LendingStatus';
import LendingCardForm from './LendingCardForm.vue';
import {
  DemoEnvironmentApiInterface,
  AlertInterface,
} from '@/views/demoenv/interfaces';

@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
    ActionMenu,
    LendingStatusChip,
    LendingCardForm,
  },
})
export default class LendingCard extends Vue {
  @Prop({ type: Number, required: true })
  private product!: number;
  @Prop({ type: Object, required: true })
  private lendingData!: DemoLending;
  @Prop({ type: Boolean, required: true })
  private editMode!: boolean;
  @Prop({ type: Boolean, required: false, default: true })
  private editButton!: boolean;

  @Inject('alert')
  private alert!: AlertInterface;
  @Inject('demoEnvApiClient')
  private client!: DemoEnvironmentApiInterface;

  private demoLending = new DemoLending();
  private get editModeFlag() {
    return this.editMode;
  }
  private optionsTypesLoadFlag = false;
  private get lendingId() {
    if (!this.demoLending) return 0;
    return this.demoLending.demoLendingId;
  }
  private get lendingStatusItems() {
    return lendstatus.getLendingStatusItems(this.demoLending);
  }
  private myAccount!: Account;
  private classPrefix = types.LendingCard.classPrefix;

  private async created() {
    this.syncPropDemoLending();

    this.myAccount = sessionStore.getAccount();

    this.optionsTypesLoadFlag = true;
  }
  @Watch('lendingId')
  private async init() {
    this.optionsTypesLoadFlag = !this.optionsTypesLoadFlag;
  }

  private submit(validate: any) {
    validate().then((result: any) => {
      if (result) {
        this.updateDemoLending();
      }
    });
  }
  private async updateDemoLending() {
    try {
      await this.client.updateDemoLendings([
        form2modelLending(this.demoLending),
      ]);
      this.alert.success('表示中の貸出データの更新に成功しました。');
      this.editModeActivate(false, true);
      this.$emit('update', this.demoLending);
    } catch {
      this.alert.fail('表示中の貸出データの更新に失敗しました。');
    }
  }
  private async updateLendingStatus(lending: DemoLending) {
    try {
      if (lending.lendingStatus == lendstatus.ENDED) {
        await this.client.updateDemoLendings([form2modelLending(lending)]);
      }
      const ret = await this.client.updateLendingStatus(
        form2modelLending(lending)
      );
      this.updateModel(ret);
      this.alert.success('貸出ステータスを更新しました。');
    } catch {
      this.alert.fail('貸出ステータスの更新に失敗しました。');
      return false;
    }
    return true;
  }
  private updateModel(lending: DemoLending) {
    this.demoLending = model2formLending(lending);
    this.$emit('update', lending);
  }
  private async deleteLending() {
    if (
      !confirm(
        'この貸出情報を完全に削除しますか？ \n(貸出の終了の場合は、削除ではなくステータスの変更をして下さい。)'
      )
    ) {
      return;
    }
    try {
      await api.deleteLending(
        sessionStore.getToken(),
        this.demoLending.demoLendingId
      );
      this.$emit('delete', true);
      this.alert.success('貸出情報の削除に成功しました。');
    } catch {
      this.alert.fail('貸出情報の削除に失敗しました。');
    }
  }
  private get lendingDataId(): number {
    return this.lendingData.demoLendingId;
  }
  @Watch('lendingDataId')
  private syncPropDemoLending() {
    // 一覧から選択→データ切り替えが何度もおこなれる想定をしている(コンポーネントは使いまわし)
    // id変更をWatchして初期化を行う
    this.demoLending = new DemoLending();
    if (!this.lendingData) return;
    this.demoLending = model2formLending(
      JSON.parse(JSON.stringify(this.lendingData))
    );
  }
  private editModeActivate(action: boolean, force: boolean) {
    if (!action && !force && !confirm('編集内容を破棄して終了します。')) {
      return;
    }
    this.$emit('modechange-edit', action).$forceUpdate();
  }
}
