import { DemoLending } from '@/domain/DemoEnv';
import {
  NewDemoLendingInterface,
  DemoEnvironmentApiInterface,
  PaginatorInterface,
} from '@/views/demoenv/interfaces';

export class NewDemoLendingPaginator {
  constructor(public pageNum = 1) {}
  init() {
    this.pageNum = 1;
  }
  nextPage(validate: any) {
    validate().then(async (result: any) => {
      if (result) this.pageNum++;
    });
  }
  backPage() {
    if (this.pageNum < 2) return;
    this.pageNum--;
  }
  cancel() {
    return;
  }
}

export class NewDemoLendingAdaptor implements NewDemoLendingInterface {
  public paginator = new NewDemoLendingPaginator();
  constructor(
    public submit = async (client: DemoEnvironmentApiInterface) => {
      return new DemoLending();
    },
    public dialogEnd = () => {
      return;
    }
  ) {
    this.paginator.cancel = () => {
      this.dialogEnd();
    };
  }
}
