import { OptionData } from '@/components/options/OptionData';

export function sortItems(items: any[]) {
  let preNum = 0;
  let nowNum = 0;
  for (let i = 0; i < items.length; i++) {
    for (let j = items.length - 1; i < j; j--) {
      preNum = items[j - 1].value;
      nowNum = items[j].value;
      if (preNum > nowNum) {
        const deleteList = items.splice(j, 1);
        items.splice(j - 1, 0, deleteList[0]);
      }
    }
  }
}

export function optionVersionsItems(
  options: OptionData[],
  optionVersions: { [key: string]: string }[]
) {
  optionVersions.length = 0;
  const versionsDict: any = {};
  options.map(opt => {
    versionsDict[opt.version] = versionsDict;
  });
  Object.keys(versionsDict).forEach(v => {
    optionVersions.push({ label: v, value: v });
  });
  sortItems(optionVersions);
  return optionVersions;
}
