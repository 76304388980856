

































import { Component, Vue } from 'vue-property-decorator';
import { mapState, mapActions } from 'vuex';
import AlertArea from '@/components/AlertArea.vue';
import Navigation from '@/components/Navigation.vue';
import Notification from '@/components/Notification.vue';
import RelatedApplicationLinks from '@/components/RelatedApplicationLinks.vue';
import { SelfSetting } from './domain/SelfSetting';
import { Account } from './domain/Account';
import * as selfSettingApi from '@/api/selfsetting';
import * as accountApi from '@/api/account';
import * as sessionStore from '@/stores/session';

@Component({
  components: {
    AlertArea,
    Navigation,
    Notification,
    RelatedApplicationLinks,
  },
  computed: mapState('accountInfo', ['account']),
  methods: {
    ...mapActions('selfSettingStore', ['setSelfSetting']),
    ...mapActions('accountInfo', ['setAccount', 'clearAccount']),
    ...mapActions('alertArea', ['success', 'fail']),
  },
})
export default class App extends Vue {
  private setSelfSetting!: (opt: SelfSetting) => void;
  private account!: Account;
  private setAccount!: (account: string) => void;
  private clearAccount!: () => void;
  private success!: (message: string) => void;
  private fail!: (message: string) => void;
  private productionDomain = 'operaglass.lw-cloud.jp';
  private notificationInitFlag = false;

  private async created() {
    try {
      const opt = await selfSettingApi.selfSetting();
      this.setSelfSetting(opt);
    } catch (e) {
      console.log(e);
      this.fail('設定ファイルの取得ができません');
      return;
    }

    const token = sessionStore.getToken();
    if (token.length === 0) {
      return;
    }
    try {
      const account = await accountApi.verify(token);
      const profile = JSON.stringify(account);
      this.setAccount(profile);
    } catch {
      this.fail('認証情報がありません');
      this.fail('ログインしてご利用ください');
      this.$router.push('/login');
      return;
    }
  }

  private async logout() {
    const token = sessionStore.getToken();
    try {
      await accountApi.logout(token);
      sessionStore.deleteToken();
      this.clearAccount();
      this.$router.push('/login');

      // 通知リストクリア
      const notification = this.$refs['notification'] as Notification;
      notification.clearNewsList();

      this.success('ログアウトしました');
    } catch {
      this.fail('ログアウトに失敗しました');
    }
  }

  private headerColor() {
    if (this.productionDomain === document.domain) {
      return '';
    }
    return 'yellow accent-2';
  }
  private headerText() {
    if (this.productionDomain === document.domain) {
      return 'logosware OperaGlass';
    }
    return '※ おぺらぐらす【検証環境】 本番と間違えないようにご注意願います。 ※';
  }
}
