













































import { Component, Vue, Emit, Prop } from 'vue-property-decorator';
import { mapActions } from 'vuex';
import Select from '@/components/molecules/Select.vue';
import TextField from '@/components/molecules/TextField.vue';
import { Plan } from '@/views/spotty/SpottyNewBasic/Form';
import { plans, perUseType, setType } from '@/domain/SpottyContract/Plan';

@Component({
  components: {
    Select,
    TextField,
  },
  methods: mapActions('alertArea', ['success', 'fail']),
})
export default class SpottyPlanSelector extends Vue {
  private success!: (message: string) => void;
  private fail!: (message: string) => void;

  @Prop({ type: Object, required: true })
  private value!: Plan;

  @Emit()
  // eslint-disable-next-line
  private input(val: Plan) {
    // Parent action
  }

  private get plans() {
    return plans;
  }

  private get planType() {
    if (this.value.planId === 14) {
      return perUseType;
    }
    return [];
  }

  private get setType() {
    if (this.value.planId === 14 || this.value.planId === 15) {
      return setType;
    }
    return [];
  }

  /**
   * ライフサイクル
   */

  /**
   * 操作
   */
  private async changePlan(val: number) {
    this.input({ planId: val, planData: '' });
  }

  private async changePlanData(val: string) {
    this.input({ planId: this.value.planId, planData: val });
  }
}
