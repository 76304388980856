import { DemoLendingOption, DemoLendingOptionData } from '@/domain/DemoEnv';
import { PLATON, LIBRA, SPOTTY } from '@/domain/Product';
import {
  NewDemoLendingOptionInterface,
  OptionsApiInterface,
} from '@/views/demoenv/interfaces';
import { OptionData } from '@/components/options/OptionData';
import {
  newOptionTypesVersions,
  maxVerions,
} from '@/components/options/OptionsTypesHelper';

export class NewDemoLendingOptionAdaptor
  implements NewDemoLendingOptionInterface {
  constructor(
    public templates: OptionData[] = [],
    public optionDatas: DemoLendingOption[] = []
  ) {}
  public version = '';
  public async init(
    product: number,
    client: OptionsApiInterface
  ): Promise<NewDemoLendingOptionInterface> {
    let optionsTypesList: OptionData[] = [];
    if (product == PLATON) {
      optionsTypesList = await client.listOtegaruPlatonOptionsTypes('');
    }
    if (product == LIBRA) {
      optionsTypesList = await client.listOtegaruLibraOptionsTypes('');
    }
    if (product == SPOTTY) {
      optionsTypesList = await client.listSpottyOptionsTypes('');
    }
    this.templates.length = 0;
    optionsTypesList.map(ot => {
      this.templates.push(ot);
    });
    this._sortOptions();

    this.version = maxVerions(newOptionTypesVersions(this.templates));

    // フォーム初期化
    // オプションバージョン上書き
    this.templates = this.templates.filter(opt => {
      return opt.version == this.version;
    });

    // マスタデータをオプション型に変換してフォームオブジェクトに埋め込む
    this.optionDatas.length = 0;
    this.templates.forEach(opt => {
      const datamap: DemoLendingOptionData = { using: false };
      opt.template.items.forEach(item => {
        datamap[item.name] = '';
      });
      if (opt.optionTypeName == 'レポートツール') {
        datamap.using = true;
      }
      this.optionDatas.push(
        new DemoLendingOption(
          'new',
          0,
          opt.typeId,
          product,
          'otegaru',
          opt.version,
          0,
          datamap
        )
      );
    });
    return this;
  }

  private _sortOptions() {
    let i = 0;
    let j = 1;
    let preRowNum = 0;
    let nowRowNum = 0;
    for (i = 0; i < this.templates.length; i++) {
      for (j = this.templates.length - 1; i < j; j--) {
        preRowNum = this.templates[j - 1].template.rowNumber;
        nowRowNum = this.templates[j].template.rowNumber;
        if (preRowNum > nowRowNum) {
          const deleteList = this.templates.splice(j, 1);
          this.templates.splice(j - 1, 0, deleteList[0]);
        }
      }
    }
  }
}
