const weekDays = ['日', '月', ' 火', '水', '木', '金', '土'];
export class News {
  constructor(
    public notificationID = 0,
    public accountID = 0,
    public context = [],
    public message = '',
    public linkUrl = '',
    public notifiedAt = '' as any
  ) {}
}

export function labelNotifiedAt(date: Date): string {
  const today = new Date();
  if (
    today.getFullYear() == date.getFullYear() &&
    today.getMonth() == date.getMonth() &&
    today.getDate() == date.getDate()
  ) {
    const hour = date.getHours();
    const minutes = date.getMinutes();
    return ('00' + hour).slice(-2) + ':' + ('00' + minutes).slice(-2);
  }
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const weekDay = date.getDay();
  return month + '/' + day + '' + '(' + weekDays[weekDay] + ')';
}
