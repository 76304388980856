
import { Component, Vue } from 'vue-property-decorator';
import { mapActions } from 'vuex';
import * as accountApi from '@/api/account';
import * as sessionStore from '@/stores/session';

@Component({
  methods: {
    ...mapActions('accountInfo', ['setAccount']),
    ...mapActions('alertArea', ['success', 'fail']),
  },
})
export default class GoogleLoginCallback extends Vue {
  private setAccount!: (account: string) => void;
  private success!: (message: string) => void;
  private fail!: (message: string) => void;

  private async created() {
    const params = window.location.search;
    try {
      const body = await accountApi.googleLoginCallback(params);
      const token = body.token;
      const profile = JSON.stringify(body.account);

      sessionStore.storeToken(token);

      sessionStore.storeAccount(profile);
      this.setAccount(profile);

      let redirectURL = sessionStore.getRedirectURL();
      if (!redirectURL) {
        redirectURL = '/';
      }
      this.$router.push(redirectURL);

      this.success('ログインしました');
      sessionStore.deleteRedirectURL();
    } catch {
      this.fail('ログインに失敗しました');
      return;
    }
  }
}
