export const CUSTOMER = 'customer';
export const LW = 'lw';
export const OTHER = 'other';

export type UserClass = typeof CUSTOMER | typeof LW | typeof OTHER;
export function isUserClass(s: any): s is UserClass {
  return typeof s == 'string' && (s === CUSTOMER || s === LW || s === OTHER);
}

export const DEFAULT_LENDING_ITEM = {
  value: OTHER,
  label: 'その他',
  color: 'default',
};

export const USER_CLASS_ITEMS = [
  {
    value: CUSTOMER,
    label: '一般顧客',
    color: 'default',
  },
  {
    value: LW,
    label: 'LW',
    color: 'default',
  },
  DEFAULT_LENDING_ITEM,
];
