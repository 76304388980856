export class Server {
  constructor(
    public infrastructure = '',
    public os = '',
    public ipAddress = '',
    public serverDetailUrl = '',
    public installedPerson = '',
    public installedDate: string | null = null,
    public platonInstallDirectory = ''
  ) {}
}
