var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-layout',{staticStyle:{"height":"100%"},attrs:{"wrap":""}},[_c('v-flex',{staticClass:"pa-5",staticStyle:{"height":"100%","overflow":"auto"},attrs:{"md6":""}},[_c('v-layout',{staticClass:"mt-3",attrs:{"wrap":""}},[_c('v-flex',{staticClass:"px-2",attrs:{"xs12":""}},[_c('v-select',{class:[_vm.classPrefix + '-userclass'],staticStyle:{"width":"150px"},attrs:{"label":"利用者区分","items":_vm.userClassItems,"item-text":"label","item-value":"value"},model:{value:(_vm.demoLending.userClass),callback:function ($$v) {_vm.$set(_vm.demoLending, "userClass", $$v)},expression:"demoLending.userClass"}})],1),_c('v-flex',{staticClass:"d-flex px-2",attrs:{"xs12":""}},[(_vm.flag)?_c('LendingCustomerSelector',{attrs:{"demoLending":_vm.demoLending,"required":"","classes":[_vm.classPrefix + '-customer']},on:{"success":function($event){return _vm.$emit('success', $event)},"fail":function($event){return _vm.$emit('fail', $event)},"warning":function($event){return _vm.$emit('warning', $event)}}}):_vm._e()],1),_c('v-flex',{attrs:{"xs6":""}},[_c('ValidationProvider',{attrs:{"vid":_vm.classPrefix + '-startDateValue'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('DatePicker',{attrs:{"label":"貸出開始日","required":"","classes":[_vm.classPrefix + '-startdate']},on:{"change":_vm.setEndDate},model:{value:(_vm.demoLending.startDate),callback:function ($$v) {_vm.$set(_vm.demoLending, "startDate", $$v)},expression:"demoLending.startDate"}}),_c('p',{staticClass:"caption red--text"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('v-flex',{attrs:{"xs6":""}},[_c('ValidationProvider',{attrs:{"vid":"endDateValue","rules":'lending-end-date:' + _vm.classPrefix + '-startDateValue'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('DatePicker',{attrs:{"label":"貸出終了日","classes":[_vm.classPrefix + '-enddate']},model:{value:(_vm.demoLending.endDate),callback:function ($$v) {_vm.$set(_vm.demoLending, "endDate", $$v)},expression:"demoLending.endDate"}}),_c('p',{staticClass:"caption red--text"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('v-flex',{staticClass:"d-flex mt-2 px-2",attrs:{"xs12":""}},[_c('TextField',{class:[_vm.classPrefix + '-responsible'],attrs:{"label":"担当者","slim":"","readonly":_vm.demoLending.responsibleUserId == _vm.myAccount.accountId,"required":""},model:{value:(_vm.demoLending.responsibleUserName),callback:function ($$v) {_vm.$set(_vm.demoLending, "responsibleUserName", $$v)},expression:"demoLending.responsibleUserName"}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",class:[_vm.classPrefix + '-set-responsible-btn'],attrs:{"depressed":"","outlined":"","rounded":""},nativeOn:{"click":function($event){$event.preventDefault();return _vm.setResponsibleUser($event)}}},'v-btn',attrs,false),on),[_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isResponsible),expression:"!isResponsible"}]},[_vm._v("mdi-account")]),_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.isResponsible),expression:"isResponsible"}]},[_vm._v("mdi-account-multiple")])],1)]}}])},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isResponsible),expression:"!isResponsible"}]},[_vm._v("自分が担当")]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.isResponsible),expression:"isResponsible"}]},[_vm._v("自分以外が担当")])])],1),_c('v-flex',{staticClass:"mt-2 px-2",attrs:{"xs12":""}},[_c('v-textarea',{class:[_vm.classPrefix + '-memo'],attrs:{"label":"備考"},model:{value:(_vm.demoLending.memo),callback:function ($$v) {_vm.$set(_vm.demoLending, "memo", $$v)},expression:"demoLending.memo"}})],1)],1)],1),_c('v-flex',{staticStyle:{"height":"100%","overflow":"auto"},attrs:{"md6":""}},_vm._l((_vm.option.templates),function(opt,idx){return _c('OptionCardPlane',{key:idx,ref:'optionCards_' + idx,refInFor:true,class:[_vm.classPrefix + '-optioncard'],attrs:{"mode":'new',"optionIndex":idx,"optionData":opt,"mapping":_vm.option.optionDatas[idx].data,"siteId":'demo',"updateVM":_vm.updateOptions,"removeOption":function () {}}})}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }