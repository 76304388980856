import { DemoLending } from '@/domain/DemoEnv/Types';
import { DemoLendingOption } from '@/domain/DemoEnv';

function formatStartDate(date: string): string {
  if (date.indexOf('T00') != -1) return date;
  return (date = date + 'T00:00:00.000Z');
}

// フォームデータを更新データへ更新する
export const form2modelLending = (form: DemoLending): DemoLending => {
  const lending = JSON.parse(JSON.stringify(form)) as DemoLending;

  const options = lending.options.map((opt: DemoLendingOption) => {
    new DemoLendingOption(
      opt.mode,
      opt.optionId,
      opt.optionsTypeId,
      opt.productId,
      'otegaru',
      opt.optionVersion,
      opt.demoLendingId,
      opt.data
    );
    return opt;
  });
  return new DemoLending(
    lending.demoLendingId > 0 ? lending.demoLendingId : 0,
    Number(lending.customerId),
    lending.customerName,
    lending.userClass,
    lending.lendingStatus,
    formatStartDate(lending.startDate),
    lending.endDate ? formatStartDate(lending.endDate) : null,
    lending.responsibleUserName,
    options,
    lending.contractResult,
    lending.memo,
    lending.assigned,
    lending.demoEnvId
  );
};
