import { ActionContext } from 'vuex';
import { NewDemoLendingState } from '@/stores/types';
import { DateAttr, dateNumberFormat } from '@/domain/DemoEnv/Date';
import { DemoLending } from '@/domain/DemoEnv/Types';
import { form2modelLending } from '@/views/demoenv/form2model';
import { DemoEnvironmentApiInterface } from '@/views/demoenv/interfaces';

const state: NewDemoLendingState = {
  demoLending: new DemoLending(),
  dialog: false,
  callback: () => {
    return;
  },
};

const getters = {
  dialog(st: NewDemoLendingState): boolean {
    return st.dialog;
  },
};

const actions = {
  setTargetEnv(context: ActionContext<NewDemoLendingState, {}>, newId: number) {
    context.commit('setTargetEnvId', newId);
  },
  setStartDate(context: ActionContext<NewDemoLendingState, {}>, date: any) {
    context.commit('setStartDate', date);
  },
  dialogStart(
    context: ActionContext<NewDemoLendingState, {}>,
    callback: (lending: DemoLending) => void
  ) {
    context.commit('reset');
    context.commit('switchDialog', true);
    context.commit('setCallback', callback);
  },
  dialogEnd(context: ActionContext<NewDemoLendingState, {}>) {
    context.commit('switchDialog', false);
  },
  switchDialog(
    context: ActionContext<NewDemoLendingState, {}>,
    value: boolean
  ) {
    context.commit('switchDialog', value);
  },
  async submit(
    context: ActionContext<NewDemoLendingState, {}>,
    client: DemoEnvironmentApiInterface
  ): Promise<DemoLending> {
    const lending = await client.addDemoLending(
      form2modelLending(context.state.demoLending)
    );
    await client.updateLendingStatus(lending);
    context.state.callback(lending);
    return lending;
  },
};

const mutations = {
  setCallback(st: NewDemoLendingState, callback: () => void) {
    st.callback = callback;
  },
  setTargetEnvId(st: NewDemoLendingState, newId: number) {
    st.demoLending.demoEnvId = newId;
  },
  setStartDate(st: NewDemoLendingState, date: DateAttr) {
    st.demoLending.startDate = dateNumberFormat(
      date.year,
      date.month,
      date.day
    );
  },
  switchDialog(st: NewDemoLendingState, value: boolean) {
    st.dialog = value;
  },
  updateDemoLending(st: NewDemoLendingState, lending: DemoLending) {
    st.demoLending = lending;
  },
  reset(st: NewDemoLendingState) {
    st.demoLending = new DemoLending();
    st.dialog = false;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
