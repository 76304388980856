





































import { Component, Prop, Vue } from 'vue-property-decorator';
import ActionMenu from '@/components/molecules/ActionMenu.vue';
import CustomerSearchSelector from '@/components/customer/CustomerSearchSelector.vue';
import PlatonOptionEditor from '@/components/platon/PlatonOptionEditor.vue';
import DatePicker from '@/components/molecules/DatePicker.vue';
import { mapActions } from 'vuex';
import { DemoLending } from '@/domain/DemoEnv/Types';
import * as lendstatus from '@/domain/DemoEnv/LendingStatus';
@Component({
  components: {
    ActionMenu,
    CustomerSearchSelector,
    DatePicker,
    PlatonOptionEditor,
  },
  methods: {
    ...mapActions('demoLendingStatus', ['startLendingStatusUpdateDialog']),
  },
})
export default class LendingStatusChip extends Vue {
  @Prop({ type: Object, required: true })
  private demoLending!: DemoLending;
  @Prop({ type: String, required: false })
  private fixedTargetStatus!: string;
  @Prop({
    type: Array,
    required: false,
    default: () => {
      return [];
    },
  })
  private statusItems!: any[];
  @Prop({ type: Boolean, required: false })
  private disabled!: boolean;
  @Prop({ type: Boolean, required: false })
  private editMode!: boolean;
  @Prop({ type: Boolean, required: false, default: false })
  private onChangeUpdate!: boolean;

  private get lendingStatusItems() {
    if (this.statusItems.length != 0) return this.statusItems;
    return lendstatus.getLendingStatusItems(this.demoLending);
  }
  private startLendingStatusUpdateDialog!: (arg: any) => {};
  private get lendingStatusSetting() {
    return lendstatus.getLendingStatusSetting;
  }
  private get lendingStatusItem() {
    return (status: string) => {
      const item = this.lendingStatusItems.filter(l => {
        return l.value == status;
      });
      if (!status || item.length == 0) return '';
      return item[0].label;
    };
  }
  private changeStatus(newLendingStatus: lendstatus.LendingStatus) {
    // ステータス変更時に、同時にデータベース更新を行わない場合
    if (!this.onChangeUpdate) {
      this.demoLending.lendingStatus = newLendingStatus;
      return;
    }

    // データベース更新を行う場合
    const arg = {
      newLendingStatus,
      lending: this.demoLending,
      callback: () => {
        this.$emit('update', this.demoLending);
      },
    };
    this.startLendingStatusUpdateDialog(arg);
  }
}
