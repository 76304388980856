











































import { Component, Vue } from 'vue-property-decorator';
import { mapActions } from 'vuex';
import KeywordSearch from '@/components/molecules/KeywordSearch.vue';
import ActionMenu from '@/components/molecules/ActionMenu.vue';
import Navigation from '@/components/Navigation.vue';
import CustomerNewDialog from '@/components/customer/CustomerNewDialog.vue';
import CustomerEditArea from '@/components/customer/CustomerEditArea.vue';
import CustomerListTable from '@/components/customer/CustomerListTable.vue';
import * as api from '@/api/customer';
import { Customer } from '@/domain/Customer';
import * as sessionStore from '@/stores/session';

Component.registerHooks(['beforeRouteUpdate']);

@Component({
  components: {
    ActionMenu,
    Navigation,
    KeywordSearch,
    CustomerNewDialog,
    CustomerEditArea,
    CustomerListTable,
  },
  methods: mapActions('alertArea', ['success', 'fail']),
})
export default class Customers extends Vue {
  /**
   * プロパティ
   */
  private searchTargetLabel = ['顧客名', '顧客別称'];
  private actionMenu = [
    {
      icon: 'add',
      text: '顧客を追加する',
      color: 'primary',
      click: () => {
        this.openNewDialog();
      },
    },
  ];

  private items: Customer[] = [];
  private intervalId = -1;
  private keywords = '';
  private displayEditArea = false;
  private displayNewDialog = false;
  private editAreaProp = new Customer();

  private success!: (message: string) => void;
  private fail!: (message: string) => void;

  /**
   * ライフサイクル
   */
  private async created() {
    await this.search();
  }

  private mounted() {
    let prev = '';
    this.intervalId = setInterval(async () => {
      if (this.keywords !== prev) {
        prev = this.keywords;
        await this.search();
      }
    }, 1000);
  }

  private beforeRouteUpdate(_: any, __: any, next: any) {
    this.changeEditArea();
    next();
  }

  private beforeDestroy() {
    clearInterval(this.intervalId);
  }

  /**
   * 操作
   */
  private async search() {
    try {
      this.items = await api.list(sessionStore.getToken(), 0, 0, this.keywords);
    } catch {
      this.fail('顧客一覧の取得に失敗しました');
    }
  }

  private openNewDialog() {
    this.displayNewDialog = true;
  }

  private closeNewDialog() {
    this.displayNewDialog = false;
  }

  private async openEdit(customerId: number) {
    await this.$router.push('/customer/edit/' + customerId);
    await this.detailLoad();
    this.changeEditArea();
  }

  private async detailLoad() {
    const id = parseInt(this.$route.params.id, 10);

    try {
      this.editAreaProp = await api.detail(sessionStore.getToken(), id);
    } catch {
      this.fail('顧客情報の取得に失敗しました');
    }
  }

  private changeEditArea() {
    const mode = this.$route.params.mode;
    if (mode === 'edit') {
      this.displayEditArea = true;
    } else {
      this.displayEditArea = false;
    }
  }
}
