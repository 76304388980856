























































































































































































import { Component, Watch, Vue } from 'vue-property-decorator';
import OptionCardPlane from '@/components/options/OptionCardPlane.vue';
import OptionList from '@/components/options/OptionList.vue';
import { mapActions } from 'vuex';
import { OptionData } from '@/components/options/OptionData';
import * as optionsApi from '@/api/options';
import * as sessionStore from '@/stores/session';
import * as versions from '@/domain/OptionTypes/Platon/Versions';
@Component({
  components: {
    OptionCardPlane,
    OptionList,
  },
  methods: {
    ...mapActions('alertArea', ['success', 'fail']),
  },
})
export default class OptionsTemplate extends Vue {
  private model!: { [key: string]: {} };
  private options!: {};
  private otegaruPlatonOptions!: OptionData[];
  private otegaruLibraOptions!: OptionData[];
  private products!: { [key: string]: string }[];
  private formVersions: { [key: string]: string }[] = [];
  private newVersionDialogFlag = false;
  private versionupDialogScenes!: any;
  private currentScene = 'basicinfo';
  private optionsTypesHeader: any[] = [];
  private baseOptionsTypes!: any[];
  private newVersionInfo!: any;
  private newVersionOptionsTypes: OptionData[] = [];
  private singleSelect = false;
  private targetProduct!: string;
  private targetOptions: OptionData[] = [];
  private storedOptions: OptionData[] = [];
  private targetVersion = '';
  private preTargetVersion = '';
  private platonDvisions!: { [key: string]: string }[];
  private libraDvisions!: { [key: string]: string }[];
  private spottyDvisions!: { [key: string]: string }[];
  private optionListStatus: { [key: string]: any } = { isChanged: false };
  private success!: (message: string) => void;
  private fail!: (message: string) => void;
  private processing = false;

  private async created() {
    this.baseOptionsTypes = [];
    this.targetProduct = 'otegaru_platon';
    this.otegaruPlatonOptions = [];
    this.products = [
      { value: 'otegaru_platon', label: 'お手軽Platon' },
      { value: 'onpremise_platon', label: 'オンプレPlaton' },
      { value: 'otegaru_libra', label: 'お手軽Libra' },
      { value: 'onpremise_libra', label: 'オンプレLibra' },
      { value: 'spotty', label: 'Spotty' },
    ];
    this.otegaruLibraOptions = [];
    this.platonDvisions = [
      {
        value: 'otegaruPlaton',
        label: 'お手軽Platon',
      },
      {
        value: 'onprePlaton',
        label: 'オンプレPlaton',
      },
    ];
    this.optionsTypesHeader = [
      {
        text: 'オプション名',
        align: 'start',
        sortable: false,
        value: 'optionTypeName',
        groupable: false,
      },
      {
        text: 'バージョン',
        align: 'start',
        sortable: false,
        value: 'version',
        groupable: false,
      },
    ];
    this.versionupDialogScenes = {
      basicinfo: {},
      inheritance: {},
    };
    this.newVersionInfo = {
      versionPrefix: 'v',
      version: 'v000',
      n1: '0',
      n2: '0',
      n3: '0',
    };
    // オプションマスタからすべて取得しておく
    // バージョン一覧を取得する用途のみの処理になるので、バージョン一覧APIができれば置き換える
    this.targetOptions.length = 0;
    const optionsTypesList = await optionsApi.listOteagruPlatonOptionsTypes(
      sessionStore.getToken(),
      ''
    );
    optionsTypesList.map(ot => {
      this.targetOptions.push(ot);
    });
    this.formatNewVersionInfo();
    this.updateFormVersions(optionsTypesList);
    this.$forceUpdate();
  }
  @Watch('newVersionDialogFlag')
  private async newVersionsDialog() {
    this.currentScene = 'basicinfo';
    // 引継ぎリストの取得
    this.baseOptionsTypes = await optionsApi.listOteagruPlatonOptionsTypes(
      sessionStore.getToken(),
      ''
    );
    this.baseOptionsTypes = this.baseOptionsTypes.map((opt, idx) => {
      const o = JSON.parse(JSON.stringify(opt));
      o.index = idx;
      return o;
    });
    const latesVersion = this.formVersions.slice(-1)[0].value;
    this.newVersionInfo.versionPrefix = latesVersion[0];
    this.newVersionInfo.n1 = latesVersion[1];
    this.newVersionInfo.n2 = latesVersion[2];
    this.newVersionInfo.n3 = Number(latesVersion[3]) + 1;
    if (this.newVersionInfo.n3 == 10) {
      this.newVersionInfo.n2++;
      this.newVersionInfo.n3 = 0;
    }
    this.formatNewVersionInfo();
    this.$forceUpdate();
  }
  @Watch('newVersionInfo')
  private formatNewVersionInfo() {
    this.newVersionInfo.version =
      this.newVersionInfo.versionPrefix +
      this.newVersionInfo.n1 +
      this.newVersionInfo.n2 +
      this.newVersionInfo.n3;
    this.$forceUpdate();
  }
  private async addNewVersion() {
    if (
      !window.confirm(
        '以上の内容で新規バージョンを作成します。よろしいですか？'
      )
    )
      return;
    const model: OptionData[] = this.newVersionOptionsTypes.map((opt, idx) => {
      opt.version = this.newVersionInfo.version;
      opt.template.rowNumber = idx + 1;
      return opt;
    });
    this.processing = true;
    try {
      if (this.targetProduct == 'otegaru_platon') {
        await optionsApi.updateOteagruPlatonOptionsTypes(
          sessionStore.getToken(),
          model
        );
      }
    } catch (e) {
      this.fail('更新に失敗しました。');
      return;
    } finally {
      this.processing = false;
      this.newVersionDialogFlag = false;
    }
    this.success('更新しました。');

    const latestOptionsTypesList = await this.listOptionsTypes();
    this.updateFormVersions(latestOptionsTypesList);
  }
  private updateFormVersions(optionsTypes: OptionData[]) {
    versions.optionVersionsItems(optionsTypes, this.formVersions);
  }

  private async changeProduct(val: string) {
    this.targetProduct = val;
    const optionsTypesList = await this.listOptionsTypes();
    this.updateFormVersions(optionsTypesList);
    if (!this.targetVersion) return;
    this.loadOptionsTypes();
    this.$forceUpdate();
  }
  private changeVersion() {
    this.loadOptionsTypes();
    this.$forceUpdate();
  }
  private clickVerisonSelector() {
    if (this.optionListStatus.isChanged) {
      const listVue = this.$refs[this.targetProduct] as OptionList;
      listVue.reset();
    }
    if (this.optionListStatus.isChanged) return;
  }
  private async listOptionsTypes(): Promise<OptionData[]> {
    let optionsTypesList: OptionData[] = [];
    switch (this.targetProduct) {
      case 'otegaru_platon':
        optionsTypesList = await optionsApi.listOteagruPlatonOptionsTypes(
          sessionStore.getToken(),
          this.targetVersion
        );
        break;
      case 'onpremise_platon':
        optionsTypesList = await optionsApi.listOnPremisePlatonOptionsTypes(
          sessionStore.getToken(),
          this.targetVersion
        );
        break;
      case 'otegaru_libra':
        optionsTypesList = await optionsApi.listOteagruLibraOptionsTypes(
          sessionStore.getToken(),
          this.targetVersion
        );
        break;

      case 'onpremise_libra':
        optionsTypesList = await optionsApi.listOnPremiseLibraOptionsTypes(
          sessionStore.getToken(),
          this.targetVersion
        );
        break;
      case 'spotty':
        optionsTypesList = await optionsApi.listSpottyOptionsTypes(
          sessionStore.getToken(),
          this.targetVersion
        );
        break;
    }
    return Promise.resolve(optionsTypesList);
  }
  private async loadOptionsTypes() {
    const optionsTypesList = await this.listOptionsTypes();
    if (!optionsTypesList) return;

    this.storedOptions.length = 0;
    this.targetOptions.length = 0;
    optionsTypesList.map(ot => {
      this.targetOptions.push(ot);
    });
  }
}
