export enum LibraDivision {
  Otegaru = 1,
  OnPremise,
}

export enum LibraOptionType {
  additionalBytes = 3,
  eventSupport = 13,
  maintenance = 4,
  customize = 6,
  allowIpAddresses = 7,
  useApi = 8,
  ownDomain = 9,
  ssoWithSaml = 10,
  ssoWithJwt = 11,
  csvBatch = 12,
}

export function DefaultOptionType(division: number): number {
  if (division === LibraDivision.Otegaru) {
    return LibraOptionType.ownDomain;
  }
  if (division === LibraDivision.OnPremise) {
    return LibraOptionType.maintenance;
  }
  return 0;
}
