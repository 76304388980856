


































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import ActionMenu from '@/components/molecules/ActionMenu.vue';
import DatePicker from '@/components/molecules/DatePicker.vue';
import DemoEnvironmentCard from '@/components/demoenv/DemoEnvironmentCard.vue';
import LendingCard from '@/components/demoenv/LendingCard.vue';
import { mapActions, mapState } from 'vuex';
import * as api from '@/api/demoenv';
import * as sessionStore from '@/stores/session';
import {
  DemoEnvironment,
  isDemoEnvironment,
  DemoLending,
  isDemoLending,
} from '@/domain/DemoEnv';
import { ROLES_STLYES } from '@/domain/DemoEnv/Environment';
import {
  defaultGannttChartSetting,
  defaultGanttEventMenuSetting,
} from './settings';
import { model2formLending } from './model2form';
import * as lendstatus from '@/domain/DemoEnv/LendingStatus';
import {
  GanttChartSetting,
  DateProps,
  GanttEventMenu,
  LendingEvent,
} from './components/GanttChart/Types';
import GanttToolBar from './components/GanttChart/GanttToolBar.vue';
import { DisplayCondition } from './components/GanttChart/ToolBar';
import GanttDaysHeader from './components/GanttChart/GanttDaysHeader.vue';
import GanttEnvironments from './components/GanttChart/GanttEnvironments.vue';
import GanttCalendar from './components/GanttChart/GanttCalendar.vue';
import { dateNumberFormat } from '@/domain/DemoEnv/Date';

@Component({
  components: {
    ActionMenu,
    DatePicker,
    DemoEnvironmentCard,
    LendingCard,
    GanttToolBar,
    GanttDaysHeader,
    GanttEnvironments,
    GanttCalendar,
  },
  computed: {
    ...mapState('demoManagementBoard', {
      product: 'product',
      demoenvs: 'demoenvs',
      demoLendings: 'demoLendings',
      editMode: 'edit',
    }),
  },
  methods: {
    ...mapActions('alertArea', ['success', 'fail', 'warning']),
    ...mapActions('demoManagementBoard', [
      'editModeActivate',
      'listDemoEnvironments',
    ]),
    ...mapActions('demoLendingNew', {
      setNewDemoLendingTargetEnv: 'setTargetEnv',
      setNewDemoLendingStartDate: 'setStartDate',
      addLendingDialogStart: 'dialogStart',
    }),
    ...mapActions('demoLendingReservation', {
      setReserveDemoLendingTargetEnv: 'setTargetEnv',
      reserveLendingDialogStart: 'dialogStart',
    }),
  },
})
export default class DemoGanttChart extends Vue {
  private product!: number;
  private displayCondition: DisplayCondition = {
    assignedStatus: [
      {
        value: 'assigned',
        label: '利用中',
      },
      {
        value: 'reserved',
        label: '予約',
      },
    ],
    roles: ROLES_STLYES,
  };
  private demoenvs!: DemoEnvironment[];
  private get environmentList(): DemoEnvironment[] {
    if (this.demoenvs.length == 0) return [] as DemoEnvironment[];
    return this.demoenvs.filter(env => {
      return (
        env.productId == this.product &&
        (this.gantt.displayRoles.length == 0 ||
          this.gantt.displayRoles.find(role => String(role) === env.role))
      );
    });
  }
  private selectedDemoEnvironment: DemoEnvironment = new DemoEnvironment();
  private selectedDemoLending: DemoLending = new DemoLending();
  private editMode!: boolean;
  private editModeActivate!: (value: boolean) => {};
  private setNewDemoLendingTargetEnv!: (newId: number) => {};
  private setNewDemoLendingStartDate!: (date: any) => {};
  private addLendingDialogStart!: (
    callback: (lending: DemoLending) => void
  ) => {};
  private setReserveDemoLendingTargetEnv!: (newId: number) => {};
  private reserveLendingDialogStart!: () => {};
  private success!: (message: string) => void;
  private fail!: (message: string) => void;
  private warning!: (message: string) => void;

  private gantt: GanttChartSetting = defaultGannttChartSetting;
  private eventMenu: GanttEventMenu = defaultGanttEventMenuSetting;

  private get months() {
    return (start: any, end: any): any[] => {
      const ret = [];
      const d = new Date(start.year, start.month - 1, start.day);
      const eDate = new Date(end.year, end.month - 1, end.day);
      const data = {
        width: 0,
        year: 2020,
        month: 1,
      } as any;
      while (eDate > d) {
        data.width = data.width + this.gantt.dayWidth;
        data.year = d.getFullYear();
        const preMonth = d.getMonth();
        d.setDate(d.getDate() + 1);
        const currentMonth = d.getMonth();
        if (preMonth != currentMonth || eDate <= d) {
          data.month = preMonth + 1;
          ret.push(JSON.parse(JSON.stringify(data)));
          data.width = 0;
          data.year = 2020;
          data.month = currentMonth;
        }
      }
      return ret;
    };
  }
  private get days() {
    return (start: any, end: any): DateProps[] => {
      const ret = [];
      const d = new Date(start.year, start.month - 1, start.day);
      const eDate = new Date(end.year, end.month - 1, end.day);
      while (eDate > d) {
        ret.push({
          year: d.getFullYear(),
          month: d.getMonth() + 1,
          day: d.getDate(),
          weekend: d.getDay() == 0 || d.getDay() == 6,
        });
        d.setDate(d.getDate() + 1);
      }
      return ret;
    };
  }

  // カレンダーに表示する貸出情報データの作成
  private get makeLendingEvent() {
    return (
      env: DemoEnvironment,
      year: number,
      month: number,
      day: number
    ): LendingEvent[] => {
      let lendings = [] as DemoLending[];
      if (this.gantt.displayLending == 'assigned') {
        lendings = env.assignedFilter(true);
      } else if (this.gantt.displayLending == 'reserved') {
        lendings = env.assignedFilter(false);
      } else {
        lendings = env.lendings;
      }
      if (lendings.length == 0) return [];
      const targetDateString = dateNumberFormat(year, month, day);
      const ganttStartDateString = dateNumberFormat(
        this.gantt.start.year,
        this.gantt.start.month,
        this.gantt.start.day
      );
      const ganttStartDate = new Date(ganttStartDateString);
      const ganttEndDateString = dateNumberFormat(
        this.gantt.end.year,
        this.gantt.end.month,
        this.gantt.end.day
      );
      const ganttEndDate = new Date(ganttEndDateString);

      const ret = [] as any;
      const zIndex = 4;
      lendings.map(lend => {
        let startDateString = lend.startDate;
        const lendEndDate = lend.endDate
          ? new Date(lend.endDate)
          : new Date('2099-01-01');
        if (
          ganttStartDate > new Date(lend.startDate) &&
          ganttStartDate <= lendEndDate &&
          targetDateString == ganttStartDateString
        )
          startDateString = targetDateString;
        if (startDateString == targetDateString) {
          const lendEndDate = lend.endDate
            ? new Date(lend.endDate)
            : new Date();
          lendEndDate.setDate(lendEndDate.getDate() + 1);
          const eDate =
            lend.endDate && lend.endDate < ganttEndDateString
              ? lendEndDate
              : ganttEndDate;
          const targetDate = new Date(targetDateString);
          const diffTime = eDate.getTime() - targetDate.getTime();
          const diffDay = Math.floor(diffTime / (1000 * 60 * 60 * 24));
          ret.push({
            id: lend.demoLendingId,
            label: lend.customerName,
            respUserName: lend.responsibleUserName,
            width: diffDay * this.gantt.dayWidth,
            status: lend.lendingStatus,
            startDate: new Date(lend.startDate),
            endDate: eDate,
            bgcolor: lendstatus.class2rgb(
              lendstatus.getLendingStatusSetting(lend.lendingStatus).color,
              0.8
            ),
            zIndex,
          } as LendingEvent);
        }
      });
      return ret;
    };
  }

  // ライフサイクル
  private async created() {
    this.init();
  }

  private mounted() {
    this.ganttScrollSync();
  }
  @Watch('product')
  private init() {
    this.gantt.editEnvironment = false;
    this.gantt.editLending = false;
    this.selectedDemoEnvironment = new DemoEnvironment();
    this.selectedDemoLending = new DemoLending();
  }

  private ganttScrollSync() {
    // エレメントを取得
    const header = document.getElementById('demoenv-ganttchart-header');
    const environments = document.getElementById(
      'demoenv-ganttchart-environment'
    );
    const calendar = document.getElementById('demoenv-ganttchart-calendar');
    if (!header || !environments || !calendar) return null;

    // エレメントがスクロールされたときの関数。
    const noScroll = (event: Event) => {
      event.preventDefault();
    };
    header.addEventListener('touchmove', noScroll, { passive: false });
    header.addEventListener('mousewheel', noScroll, { passive: false });
    environments.addEventListener('touchmove', noScroll, { passive: false });
    environments.addEventListener('mousewheel', noScroll, { passive: false });

    calendar.addEventListener(
      'scroll',
      () => {
        environments.scrollTop = calendar.scrollTop;
        header.scrollLeft = calendar.scrollLeft;
      },
      true
    );
    return null;
  }

  // 操作
  private async openDemoEnvironment(environmentId: number) {
    if (this.editMode && !confirm('編集内容を破棄してもよろしいですか?'))
      return;
    this.editModeActivate(false);

    if (!environmentId || environmentId == 0) return;
    this.selectedDemoEnvironment = new DemoEnvironment();
    try {
      const ret = await api.describeEnvironment(
        sessionStore.getToken(),
        environmentId
      );
      if (!isDemoEnvironment(ret)) {
        throw 'error';
      }
      this.selectedDemoEnvironment = ret;
    } catch {
      this.fail('環境情報の取得に失敗しました。');
      this.selectedDemoEnvironment = new DemoEnvironment();
      return;
    }
    this.gantt.editEnvironment = true;
    this.gantt.editLending = false;
  }
  private async openDemoLending(lendingId: number) {
    this.mouseLeaveGantt();
    if (!lendingId || lendingId == 0) return;
    try {
      const ret = await api.describeDemoLending(
        sessionStore.getToken(),
        lendingId
      );
      if (!isDemoLending(ret)) throw 'error';
      if (ret.demoLendingId == 0) throw new Error('');
      this.selectedDemoLending = model2formLending(ret);
    } catch {
      this.fail('貸出情報の取得に失敗しました。');
      this.selectedDemoLending = new DemoLending();
      return;
    }
    this.gantt.editEnvironment = false;
    this.gantt.editLending = true;
  }
  private openDemoLendingWrapper(demoLending: DemoLending) {
    this.openDemoLending(demoLending.demoLendingId);
  }
  private ganttMenuFunction(menuItem: any) {
    if (menuItem.value == 'new') {
      this.addLendingDialogStart(this.openDemoLendingWrapper);
      this.setNewDemoLendingTargetEnv(this.eventMenu.selectedEnv.demoEnvId);
      const date = this.eventMenu.selectedDate;
      this.setNewDemoLendingStartDate(date);
    }
    if (menuItem.value == 'reservation') {
      this.reserveLendingDialogStart();
      this.setReserveDemoLendingTargetEnv(this.eventMenu.selectedEnv.demoEnvId);
    }
  }

  // マウスがガントチャートから離れた時の処理
  private mouseLeaveGantt() {
    this.eventMenu.show = false;
    this.eventMenu.selectedDate = { year: 0, month: 0, day: 0 };
  }

  // Emit
  private emitDelete(name: string) {
    this.selectedDemoLending = new DemoLending();
    this.$emit(name, true);
  }
  private emitUpdate(name: string) {
    this.$emit(name, true);
    this.openDemoEnvironment(this.selectedDemoEnvironment.demoEnvId);
  }
}
