export type SelfSetting = {
  displayDemoenvironment: boolean;
  displayOptionsTypes: boolean;
  notification: boolean;
  displayStatcenter: boolean;
  statcenterUrl: string;
  displayCustomerSuccess: boolean;
  customerSuccessUrl: string;
  displayAdvanceContent: boolean;
};

export const defaultSelfSetting = {
  displayDemoenvironment: false,
  displayOptionsTypes: false,
  notification: false,
  displayStatcenter: false,
  statcenterUrl: 'http://localhost:8010/statcenter/platon/home',
  displayCustomerSuccess: false,
  customerSuccessUrl: 'http://localhost:8010/customersuccess/platon/dashboard',
  displayAdvanceContent: false,
};
