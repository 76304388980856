import { AlertInterface } from '../interfaces';

export class AlertAdaptor implements AlertInterface {
  constructor(
    public success = (msg: string) => {
      return;
    },
    public fail = (msg: string) => {
      return;
    },
    public warning = (msg: string) => {
      return;
    }
  ) {}
}
