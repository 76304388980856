import { ActionContext } from 'vuex';
import { SpottyContractState } from '@/stores/types.ts';
import { SpottyNewBasicForm } from '@/views/spotty/SpottyNewBasic/Form';
import { SpottyNewOptionsForm } from '@/views/spotty/SpottyNewOptions/SpottyNewOptionsForm';
import {
  SpottyContract,
  defaultSpottyContract,
} from '@/domain/SpottyContract/SpottyContract';
import * as spottyApi from '@/api/spotty';
import { SpottyAccount } from '@/domain/SpottyContract/SpottyAccount';

const state: SpottyContractState = {
  model: defaultSpottyContract,
  endUserKeywords: '',
  salesAgentKeywords: '',
};

const getters = {
  // eslint-disable-next-line
  contractLabel(st: SpottyContractState): string {
    return 'Spotty';
  },
};

const actions = {
  updateModel(
    context: ActionContext<SpottyContractState, {}>,
    model: SpottyContract
  ) {
    context.commit('updateModel', model);
  },
  updateSearchKeywords(
    context: ActionContext<SpottyContractState, {}>,
    keywords: {
      endUserName: string;
      salesAgentName: string;
    }
  ) {
    context.commit('updateSearchKeywords', keywords);
  },
  updateBasic(
    context: ActionContext<SpottyContractState, {}>,
    form: SpottyNewBasicForm
  ) {
    context.commit('updateBasic', form);
  },
  updateOptions(
    context: ActionContext<SpottyContractState, {}>,
    form: SpottyNewOptionsForm
  ) {
    context.commit('updateOptions', form);
  },
  async submit(context: ActionContext<SpottyContractState, {}>, token: string) {
    await spottyApi.add(token, context.state.model);
    context.commit('reset');
  },
};

const mutations = {
  updateModel(st: SpottyContractState, model: SpottyContract) {
    st.model.closingDay = model.closingDay === 0 ? 24 : model.closingDay;
    st.model = model;

    // 申込書アップロード時に、SpottyAccountではなく連想配列になってしまったので、強制的に型変換する(原因わからず)
    const sa = st.model.initialAccount;
    st.model.initialAccount = new SpottyAccount(
      sa.spottyAccountId,
      sa.spottyId,
      sa.label,
      sa.id,
      sa.password
    );
    if (!st.model.options) st.model.options = [];
  },
  updateSearchKeywords(
    st: SpottyContractState,
    keywords: { endUserName: string; salesAgentName: string }
  ) {
    st.endUserKeywords = keywords.endUserName;
    st.salesAgentKeywords = keywords.salesAgentName;
  },
  updateBasic(st: SpottyContractState, form: SpottyNewBasicForm) {
    st.model.endUserId = parseInt(form.endUserId, 10);
    st.model.salesAgentId = parseInt(form.salesAgentId, 10);
    st.model.planId = form.plan.planId;
    st.model.plan.planId = form.plan.planId;
    st.model.planData = form.plan.planData;
    st.model.url = form.url;
    st.model.siteId = form.siteId;
    st.model.startDate = form.startDate + 'T00:00:00Z';
    st.model.billingStartDate = form.billingStartDate + 'T00:00:00Z';
    st.model.closingDay = form.closingDay === 0 ? 24 : form.closingDay;
    st.model.version.versionLabel = form.initialVersion;
    st.model.version.updateDate = form.startDate + 'T00:00:00Z';
    st.model.initialAccount = form.initialAccount;
    st.model.salesMemo = form.salesMemo;

    st.endUserKeywords = form.endUserKeywords;
    st.salesAgentKeywords = form.salesAgentKeywords;
  },

  updateOptions(st: SpottyContractState, form: SpottyNewOptionsForm) {
    st.model.options = [];
    form.optionData.forEach(opt => {
      if (!opt.data.using) return;
      opt.startDate =
        opt.data.startDate && opt.data.startDate != ''
          ? opt.data.startDate + 'T00:00:00Z'
          : st.model.startDate;
      opt.endDate = opt.data.endDate ? opt.data.endDate + 'T00:00:00Z' : null;
      st.model.options.push(opt);
    });
  },

  reset(st: SpottyContractState) {
    st.model = defaultSpottyContract;
    st.endUserKeywords = '';
    st.salesAgentKeywords = '';
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
