import { binary, binaryGet } from './io';
import dayjs from 'dayjs';

const rootUrl = process.env.VUE_APP_URL + '/base';

export const platonNetworkUsage = async (
  token: string,
  startLocalDate: string,
  endLocalDate: string
): Promise<boolean> => {
  const url = rootUrl + '/billingbasis/platon/networkusage/host/csv';
  const paramsObj = {
    /* eslint-disable */
    date_range: {
      start_local_date: startLocalDate,
      end_local_date: endLocalDate,
      timezone: '+09:00',
    },
    /* eslint-enable */
  };
  const json = JSON.stringify(paramsObj);
  const params = new URLSearchParams();
  params.append('target', json);

  const headers = { Authorization: 'Bearer ' + token };

  // eslint-disable-next-line
  const _ = await binaryGet(url + '?' + params.toString(), {
    headers,
    responseType: 'arraybuffer',
  });
  return Promise.resolve(true);
};

export const libraNetworkUsage = async (
  token: string,
  startLocalDate: string,
  endLocalDate: string
): Promise<boolean> => {
  const url = rootUrl + '/billingbasis/libra/networkusage/host/csv';
  const paramsObj = {
    /* eslint-disable */
    date_range: {
      start_local_date: startLocalDate,
      end_local_date: endLocalDate,
      timezone: '+09:00',
    },
    /* eslint-enable */
  };
  const json = JSON.stringify(paramsObj);
  const params = new URLSearchParams();
  params.append('target', json);

  const headers = { Authorization: 'Bearer ' + token };

  // eslint-disable-next-line
  const _ = await binaryGet(url + '?' + params.toString(), {
    headers,
    responseType: 'arraybuffer',
  });
  return Promise.resolve(true);
};

export const platonMonthlyUniqueLogin = async (
  token: string,
  year: number,
  month: number
): Promise<boolean> => {
  const url =
    rootUrl + '/billingbasis/platon/uniqueplatoncountmonthly/host/csv';
  const paramsObj = {
    /* eslint-disable */
    date_range: {
      start_local_date: dayjs()
        .set('year', year)
        .set('month', month)
        .format('YYYY-MM-DD'),
      timezone: '+09:00',
    },
    /* eslint-enable */
  };
  const json = JSON.stringify(paramsObj);
  const params = new URLSearchParams();
  params.append('target', json);

  const headers = { Authorization: 'Bearer ' + token };

  // eslint-disable-next-line
  const _ = await binaryGet(url + '?' + params.toString(), {
    headers,
    responseType: 'arraybuffer',
  });
  return Promise.resolve(true);
};

export const libraMonthlyUniqueLogin = async (
  token: string,
  year: number,
  month: number
): Promise<boolean> => {
  const url = rootUrl + '/billingbasis/libra/uniquelogincountmonthly/host/csv';
  const paramsObj = {
    /* eslint-disable */
    date_range: {
      start_local_date: dayjs()
        .set('year', year)
        .set('month', month)
        .format('YYYY-MM-DD'),
      timezone: '+09:00',
    },
    /* eslint-enable */
  };
  const json = JSON.stringify(paramsObj);
  const params = new URLSearchParams();
  params.append('target', json);

  const headers = { Authorization: 'Bearer ' + token };

  // eslint-disable-next-line
  const _ = await binaryGet(url + '?' + params.toString(), {
    headers,
    responseType: 'arraybuffer',
  });
  return Promise.resolve(true);
};

export const spottyMonthlyUniqueLogin = async (
  token: string,
  year: number,
  month: number
): Promise<boolean> => {
  const url = rootUrl + '/billingbasis/spotty/uniquelogincountmonthly/host/csv';
  const paramsObj = {
    /* eslint-disable */
    date_range: {
      start_local_date: dayjs()
        .set('year', year)
        .set('month', month)
        .format('YYYY-MM-DD'),
      timezone: '+09:00',
    },
    /* eslint-enable */
  };
  const json = JSON.stringify(paramsObj);
  const params = new URLSearchParams();
  params.append('target', json);

  const headers = { Authorization: 'Bearer ' + token };

  // eslint-disable-next-line
  const _ = await binaryGet(url + '?' + params.toString(), {
    headers,
    responseType: 'arraybuffer',
  });
  return Promise.resolve(true);
};

export const platonLastUniqueLogin = async (
  token: string,
  year: number,
  month: number
): Promise<boolean> => {
  const url =
    rootUrl + '/billingbasis/platon/uniqueplatoncountmonthly/lately/csv';
  const paramsObj = {
    /* eslint-disable */
    date_range: {
      end_local_date: dayjs()
        .set('year', year)
        .set('month', month)
        .format('YYYY-MM-DD'),
      timezone: '+09:00',
    },
    /* eslint-enable */
  };
  const json = JSON.stringify(paramsObj);
  const params = new URLSearchParams();
  params.append('target', json);

  const headers = { Authorization: 'Bearer ' + token };

  // eslint-disable-next-line
  const _ = await binaryGet(url + '?' + params.toString(), {
    headers,
    responseType: 'arraybuffer',
  });
  return Promise.resolve(true);
};

export const advanceContentsMonthlySummary = async (
  token: string,
  year: number,
  month: number
): Promise<boolean> => {
  const url =
    rootUrl + '/billingbasis/platon/advancecontentsreport/monthly-summary';
  const paramsObj = {
    /* eslint-disable */
    countMonth: dayjs()
      .set('year', year)
      .set('month', month)
      .format('YYYYMM'),
    /* eslint-enable */
  };

  const headers = { Authorization: 'Bearer ' + token };

  // eslint-disable-next-line
  const _ = await binary(url, paramsObj, {
    headers,
    responseType: 'arraybuffer',
  });
  return Promise.resolve(true);
};

export const advanceContentsMonthlyContents = async (
  token: string,
  year: number,
  month: number,
  siteId: string
): Promise<boolean> => {
  const url =
    rootUrl + '/billingbasis/platon/advancecontentsreport/monthly-contents';
  const paramsObj = {
    /* eslint-disable */
    countMonth: dayjs()
      .set('year', year)
      .set('month', month)
      .format('YYYYMM'),
    siteId: siteId,
    /* eslint-enable */
  };

  const headers = { Authorization: 'Bearer ' + token };

  // eslint-disable-next-line
  const _ = await binary(url, paramsObj, {
    headers,
    responseType: 'arraybuffer',
  });
  return Promise.resolve(true);
};

export const advanceContentsMonthlyUsers = async (
  token: string,
  year: number,
  month: number,
  siteId: string
): Promise<boolean> => {
  const url =
    rootUrl + '/billingbasis/platon/advancecontentsreport/monthly-users';
  const paramsObj = {
    /* eslint-disable */
    countMonth: dayjs()
      .set('year', year)
      .set('month', month)
      .format('YYYYMM'),
    siteId: siteId,
    /* eslint-enable */
  };

  const headers = { Authorization: 'Bearer ' + token };

  // eslint-disable-next-line
  const _ = await binary(url, paramsObj, {
    headers,
    responseType: 'arraybuffer',
  });
  return Promise.resolve(true);
};
