var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{directives:[{name:"show",rawName:"v-show",value:(_vm.demoenv.active),expression:"demoenv.active"}],staticClass:"pl-5",attrs:{"xs12":""}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","items":_vm.demoenv.lendings,"sort-by":"startDate","sort-asc":"","show-select":"","items-per-page":25,"hide-default-header":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',{directives:[{name:"show",rawName:"v-show",value:(item.lendingStatus != 'ended'),expression:"item.lendingStatus != 'ended'"}],class:[_vm.classPrefix + '-item'],staticStyle:{"cursor":"pointer"},style:(_vm.isRowSelected(item)
              ? { 'background-color': _vm.class2rgb('info', 0.3) }
              : {}),on:{"click":function($event){return _vm.open(item)}}},[_vm._l((_vm.lendingsHeader),function(key,i){return _c('td',{key:i,staticClass:"px-0"},[(key.value == 'lendingStatus')?_c('div',{staticStyle:{"width":"100px"}},[_c('v-chip',{staticClass:"ma-2",attrs:{"small":"","color":_vm.lendingStatusSetting(item.lendingStatus).color}},[_vm._v(" "+_vm._s(_vm.lendingStatusSetting(item.lendingStatus).label)+" ")])],1):(key.value == 'customerName')?_c('div',{staticClass:"body-2 mx-2",class:[_vm.classPrefix + '-customer'],staticStyle:{"width":"200px"}},[_vm._v(" "+_vm._s(item.customerName)+" ")]):(key.value == 'startDate')?_c('div',{staticClass:"mx-1 d-flex",staticStyle:{"width":"100px"}},[_c('div',{staticStyle:{"width":"80px"}}),_vm._v(" "+_vm._s(_vm.dateDisplay(item.startDate))+" "),_c('div',{staticClass:"ml-2"},[_vm._v("~")])]):(key.value == 'endDate')?_c('div',{staticClass:"mx-1",staticStyle:{"width":"80px"}},[_vm._v(" "+_vm._s(_vm.dateDisplay(item.endDate))+" ")]):(key.value == 'memo')?_c('div',{staticClass:"px-auto",staticStyle:{"width":"100px"}},[(_vm.isExpired(item.endDate) && !item.assigned)?_c('div',[_vm._v(" 貸出期間外 ")]):_c('div',[_c('LendingStatusChip',{class:[_vm.classPrefix + '-lendingstatus-chip'],attrs:{"demoLending":item,"fixedTargetStatus":_vm.nextLendingStatus(item.lendingStatus),"disabled":!_vm.isEnvAvailable(_vm.demoenv) ||
                      (!item.assigned && _vm.isUnderLeace(_vm.demoenv)) ||
                      _vm.editMode,"editMode":_vm.editMode,"onChangeUpdate":""},on:{"update":function($event){return _vm.$emit('update', $event)},"ended":function($event){return _vm.$emit('ended')}}})],1)]):_c('div',[_vm._v(" "+_vm._s(item[key.value])+" ")])])}),_c('td',{staticStyle:{"width":"20px"}},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(item.assigned),expression:"item.assigned"}],class:[_vm.classPrefix + '-item-menu'],attrs:{"color":"default","text":"","disabled":_vm.editMode}},'v-btn',attrs,false),on),[_c('v-icon',{staticStyle:{"width":"20px"}},[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.releaseLending(item)}}},[_c('v-list-item-title',[_vm._v("待機中にする")])],1)],1)],1)],1)],2)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }