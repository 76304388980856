import { DemoEnvironmentRole } from '@/domain/DemoEnv/Environment';
import { DemoEnvironment, DemoLending } from '@/domain/DemoEnv';

export class GanttChartSetting {
  constructor(
    public dayWidth: number = 29,
    public baseRowHeight: number = 40,
    public envColumnWidth: number = 300,
    public today: DateProps = { year: 0, month: 0, day: 0 },
    public start: DateProps = { year: 0, month: 0, day: 0 },
    public end: DateProps = { year: 0, month: 0, day: 0 },
    public rowNumber: any = {},
    public editEnvironment: boolean = false,
    public editLending: boolean = false,
    public displayLending: DisplayAssignmentStatus = undefined,
    public displayRoles: DemoEnvironmentRole[] = []
  ) {}
  public rowHeight(env: DemoEnvironment) {
    const ganttStartDate = new Date(
      this.start.year,
      this.start.month - 1,
      this.start.day
    );
    const ganttEndDate = new Date(
      this.end.year,
      this.end.month - 1,
      this.end.day
    );
    let rowNumberInGanttDateRange = 0;

    let lendings = [] as DemoLending[];
    if (this.displayLending == 'assigned') {
      lendings = env.assignedFilter(true);
    } else if (this.displayLending == 'reserved') {
      lendings = env.assignedFilter(false);
    } else {
      lendings = env.lendings;
    }

    const lendingsInGanttRange = lendings.filter(l => {
      const startDate = new Date(l.startDate);
      const endDate = l.endDate
        ? new Date(l.endDate)
        : new Date(this.end.year, this.end.month - 1, this.end.day - 1);

      // ガントチャートの表示範囲にかぶっているかチェック
      const isInRange =
        (startDate > ganttStartDate && startDate < ganttEndDate) ||
        (endDate > ganttStartDate && endDate < ganttEndDate) ||
        (startDate < ganttStartDate && endDate > ganttEndDate);

      if (isInRange) {
        if (!this.rowNumber[env.demoEnvId]) this.rowNumber[env.demoEnvId] = {};
        this.rowNumber[env.demoEnvId][
          l.demoLendingId
        ] = rowNumberInGanttDateRange;
        rowNumberInGanttDateRange++;
      }
      return isInRange;
    });
    if (lendingsInGanttRange.length == 0) return this.baseRowHeight;
    return lendingsInGanttRange.length * this.baseRowHeight;
  }
}

export type DisplayAssignmentStatus =
  | 'all'
  | 'assigned'
  | 'reserved'
  | undefined;

export type DateProps = {
  year: number;
  month: number;
  day: number;
  weekend?: boolean;
};

export class GanttEventMenu {
  constructor(
    public show: boolean = false,
    public style: GanttEventStyles = {
      top: '10px',
      left: '10px',
    },
    public items: GanttEventMenuItem[] = [],
    public selectedEnv: DemoEnvironment = new DemoEnvironment(),
    public selectedDate: DateProps = { year: 0, month: 0, day: 0 }
  ) {}
}

export interface GanttEventMenuItem {
  value: string;
  label: string;
}

export type MenuStyles = {
  top: string;
  left: string;
};

export interface GanttEventStyles extends MenuStyles {
  [key: string]: any;
}

export interface LendingEvent {
  id: number;
  label: string;
  respUserName: string;
  width: number;
  status: string;
  startDate: Date;
  endDate: Date;
  bgcolor: string;
  zIndex: number;
}
