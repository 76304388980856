import dayjs from 'dayjs';

export class SpottyOption {
  constructor(
    public mode = 'edit',
    public optionId = 0,
    public spottyId = 0,
    public optionsTypeId = 0,
    public optionVersion = '',
    public startDate = dayjs().toISOString(),
    public endDate: string | null = null,
    public data: any = null
  ) {}
}
