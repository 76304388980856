























































































import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { SelfSetting } from '@/domain/SelfSetting';
import * as sessionStore from '@/stores/session';
import * as apiNewsstack from '@/api/newsstack.ts';
import { News } from '@/domain/Notification';
import * as notification from '@/domain/Notification';

@Component({
  computed: {
    ...mapGetters('selfSettingStore', ['selfSetting']),
  },
})
export default class Notification extends Vue {
  private selfSetting!: SelfSetting;
  private initFlag!: boolean;
  private newsList: News[] = [];
  private notificationTargets!: any[];
  private notificationActivities!: any[];
  private notificationSearch!: any;
  private dispLimits!: any;
  private notificationListSetting = {
    style: {
      position: 'absolute',
      top: '10px',
      left: '10px',
      'max-width': '600px',
      'min-width:': '400px',
    },
  };
  private notificationListView = false;
  private open(path: string) {
    if (path !== this.$router.currentRoute.path) {
      this.$router.push(path);
    }
  }
  private get notifiedAt() {
    return notification.labelNotifiedAt;
  }
  private async created() {
    this.initFlag = false;
    this.notificationSearch = {
      limit: 20,
    };
    this.dispLimits = [
      { label: '20', value: 20 },
      { label: '50', value: 50 },
      { label: '100', value: 100 },
    ];

    // 定期的に通知の取得を行う
    // 定期取得の理由としては、単純にcreatedに処理を書くだけだと、
    // ログイン後にトークンがセットされる前に通知取得APIを叩いてしまい401が返される為
    window.setInterval(this.intervalLoadNews, 3000);
  }
  private NotificationButton(event: MouseEvent) {
    // 通知リストの位置
    const offset = 40;
    this.notificationListSetting.style.top =
      event.clientY + offset - event.offsetY + 'px';
    this.notificationListSetting.style.left = event.clientX - 600 + 'px';

    // 通知リストの表示/非表示の切り替え
    this.notificationListView = !this.notificationListView;
  }
  private intervalLoadNews() {
    if (this.initFlag || sessionStore.getToken().length == 0) return;
    this.loadNews();
    this.initFlag = true;
  }
  private async loadNews() {
    // お知らせ機能はまだ使えていないので、一旦削除
    return;
    // this.newsList.length = 0;
    // const myNews = await apiNewsstack.myNews(
    //   sessionStore.getToken(),
    //   this.notificationSearch.limit
    // );
    // if (!myNews) return;
    // myNews.map(news => {
    //   news.notifiedAt = new Date(news.notifiedAt);
    //   this.newsList.push(news);
    // });
  }
  public clearNewsList() {
    this.newsList.length = 0;
  }
  private async readNews(news: News) {
    if (news.linkUrl != '') window.open(news.linkUrl);
  }
}
