











































































import { Component, Prop, Vue } from 'vue-property-decorator';
import dayjs from 'dayjs';
import { SpottyContract } from '../../domain/SpottyContract/SpottyContract';

@Component
export default class SpottyListTable extends Vue {
  @Prop({ type: Array, required: true })
  private contracts!: SpottyContract[];

  private headers = [
    {
      text: 'エンドユーザー・代理店',
      value: 'endUserName',
      sortable: true,
    },
    {
      text: 'プラン(オプション)',
      value: 'plan.planName',
      sortable: true,
    },
    {
      text: 'URL',
      value: 'url',
      sortable: true,
    },
    {
      text: '利用開始日',
      value: 'startDate',
      sortable: true,
    },
    {
      text: '利用終了日',
      value: 'endDate',
      sortable: true,
    },
    {
      text: 'バージョン',
      value: 'currentVersion',
      sortable: true,
    },
    {
      text: '',
      value: 'control',
      sortable: false,
    },
  ];

  private openDetail(id: number) {
    this.$router.push('detail/' + id);
  }

  private openVersionUp(id: number) {
    this.$router.push('/spotty/versionup/' + id);
  }

  private dateFormatted(isoDate: string): string {
    return dayjs(isoDate).format('YYYY/MM/DD');
  }
}
