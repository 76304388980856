import { LibraDivision } from '@/domain/LibraContract/Types';

export class VersionUpForm {
  constructor(
    public libraId = 0,
    public division = LibraDivision.Otegaru,
    public endUserName = '',

    public currentVersionDivision = '',
    public currentVersion = '',
    public lastUpdateDate = '',
    public updateVersionDivision = '',
    public updateVersion = '',
    public updateDate = '',

    public currentInstallerDivision = '',
    public currentInstaller = '',
    public updateInstallerDivision = '',
    public updateInstaller = ''
  ) {}
}
