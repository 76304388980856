




































import { Component, Vue, Emit } from 'vue-property-decorator';
import { mapActions } from 'vuex';
import { ValidationObserver } from 'vee-validate';
import TextField from '@/components/molecules/TextField.vue';
import * as customerApi from '@/api/customer';
import { Customer } from '../../domain/Customer';
import * as sessionStore from '@/stores/session';

@Component({
  components: {
    ValidationObserver,
    TextField,
  },
  methods: mapActions('alertArea', ['success', 'fail']),
})
export default class CustomerNewDialog extends Vue {
  private newAreaProp = new Customer();

  private success!: (message: string) => void;
  private fail!: (message: string) => void;

  @Emit()
  private close() {
    // Parent action
  }

  @Emit()
  private reload() {
    // Parent action
  }

  private async submit(validate: any) {
    validate().then(async (result: any) => {
      if (!result.isValid) {
        return;
      }
      try {
        await customerApi.add(sessionStore.getToken(), this.newAreaProp);
        this.success('顧客を追加しました');
      } catch {
        this.fail('顧客の追加に失敗しました');
      }

      this.reset();
    });
  }

  private reset() {
    this.newAreaProp.customerName = '';
    this.newAreaProp.customerAlias = '';
    this.close();
    this.reload();
  }
}
