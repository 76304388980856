import { LibraContract } from '@/domain/LibraContract/LibraContract';
import { LibraDetailForm } from './LibraDetailForm';
// 契約詳細フォームからモデルへの変換
export const form2model = (
  model: LibraContract | null,
  form: LibraDetailForm
): LibraContract => {
  if (model === null) {
    throw new Error();
  }

  const obj = JSON.parse(JSON.stringify(form)) as LibraDetailForm;
  model.salesAgentId = parseInt(obj.salesAgentId, 10);
  model.planId = obj.plan.planId;
  model.planData = obj.plan.planData;
  model.url = obj.url;
  model.siteId = obj.siteId;
  model.startDate = obj.startDate + 'T00:00:00Z';
  model.billingStartDate = obj.billingStartDate + 'T00:00:00Z';
  model.endDate = obj.endDate ? obj.endDate + 'T00:00:00Z' : null;
  model.closingDay = obj.closingDay;
  model.salesMemo = obj.salesMemo;
  model.server = obj.server;
  model.server.installedDate = obj.server.installedDate
    ? obj.server.installedDate + 'T00:00:00Z'
    : null;
  model.options = obj.options
    .filter(opt => {
      return opt.data != null;
    })
    .map(opt => {
      opt.libraId = obj.libraId;
      if (!opt.startDate) {
        opt.startDate = obj.startDate + 'T00:00:00Z';
      }
      return opt;
    });
  return model;
};
