













































































































































































import { Component, Prop, Vue, Inject } from 'vue-property-decorator';
import { ValidationProvider } from 'vee-validate';
import PlatonOptionEditor from '@/components/platon/PlatonOptionEditor.vue';
import LibraOptionEditor from '@/components/libra/LibraOptionEditor.vue';
import LendingStatusChip from '@/components/demoenv/LendingStatusChip.vue';
import CustomerSearchSelector from '@/components/customer/CustomerSearchSelector.vue';
import DatePicker from '@/components/molecules/DatePicker.vue';
import TextField from '@/components/molecules/TextField.vue';
import FormSection from './FormSection.vue';
import { Account } from '@/domain/Account';
import { Customer } from '@/domain/Customer';
import { DemoLending } from '@/domain/DemoEnv/Types';
import { USER_CLASS_ITEMS } from '@/domain/DemoEnv/User';
import { newCheckPoints } from './LendingStatusCheckpoints';
import { ProductIDs } from './Product';
import LendingDatePicker from './LendingDatePicker.vue';
import {
  CustomerApiInterface,
  AlertInterface,
} from '@/views/demoenv/interfaces';

@Component({
  components: {
    ValidationProvider,
    CustomerSearchSelector,
    PlatonOptionEditor,
    LibraOptionEditor,
    LendingStatusChip,
    TextField,
    DatePicker,
    FormSection,
    LendingDatePicker,
  },
})
export default class LendingCardForm extends Vue {
  @Prop({ type: Number, required: true })
  private product!: number;
  @Prop({ type: Object, required: true })
  private demoLending!: DemoLending;

  @Prop({ type: Object, required: true })
  private myAccount!: Account;
  @Prop({ type: Boolean, required: true })
  private editMode!: boolean;
  @Prop({ type: Boolean, required: true })
  private optionsTypesLoadFlag!: boolean;

  @Inject('alert')
  private alert!: AlertInterface;
  @Inject('customerApiClient')
  private client!: CustomerApiInterface;

  private productIds = new ProductIDs();
  private userClassItems = USER_CLASS_ITEMS;
  private get userClassLabel() {
    return (value: string) => {
      const userClass = this.userClassItems.find(item => item.value == value);
      if (!userClass) return '無効な利用者区分';
      return userClass.label;
    };
  }

  private created() {
    this.searchEndUsers('');
  }

  private updateDemoUser() {
    const customerId = this.demoLending.customerId;
    if (customerId === undefined) {
      this.demoLending.customerId = '0';
      this.demoLending.customerName = '';
    }
    const user = this.endusers.filter(eu => {
      return eu.customerId == Number(customerId);
    });
    if (user.length == 0) return;
    this.demoLending.customerName = user[0].customerName;
  }
  // eslint-disable-next-line
  private async setResponsibleUser(account: Account) {
    if (this.myAccount.accountId == 0) {
      this.alert.fail('ユーザー情報の取得に失敗しました。');
      return;
    }

    // 自分以外の担当者を指定する場合の初期化処理
    if (this.isResponsible) {
      this.demoLending.responsibleUserName = '';
      return;
    }
    this.demoLending.responsibleUserName =
      this.myAccount.familyName + ' ' + this.myAccount.givenName;
  }

  private customerSelectorDialog = false;
  private endUserKeywords = '';
  private endusers: Customer[] = [];
  private async searchEndUsers(keywords: string) {
    try {
      this.endusers = await this.client.list(5, 0, keywords);
    } catch {
      this.alert.fail('顧客一覧(代理店)の取得に失敗しました');
      return;
    }
  }
  // eslint-disable-next-line
  private updateEndUserKeywords(keywords: string) {
    this.endUserKeywords = keywords;
  }
  // eslint-disable-next-line
  private async addCustomer(keywords: string) {
    if (keywords === '') {
      this.alert.fail('顧客名が入力されていません');
      return;
    }

    const customer = new Customer(0, keywords);
    try {
      await this.client.add(customer);
      this.alert.success('顧客を追加しました');
    } catch (e) {
      this.alert.fail(e.response.data.message);
    }
    this.searchEndUsers(this.endUserKeywords);
  }

  private get lendingCheckPoint() {
    const status = this.demoLending.lendingStatus;
    const cp = newCheckPoints(this.product);
    if (status == 'waiting') {
      return cp.waiting;
    }
    if (status == 'lending') {
      return cp.lending;
    }
    return {};
  }

  private get isResponsible() {
    return (
      this.demoLending.responsibleUserName ==
      this.myAccount.familyName + ' ' + this.myAccount.givenName
    );
  }
}
