import { extend } from 'vee-validate';
import { lendingEndDateValidation } from '@/domain/Rules/DemoEnvironment';
import { required, min, minmax } from '@/domain/Rules/Basic';
import { planValidation } from '@/domain/Rules/Contract';
export function extendAllRules() {
  extend('required', required);
  extend('min', min);
  extend('minmax', minmax);
  extend('lending-end-date', lendingEndDateValidation);
  extend('plan', planValidation);
}
