import { ActionContext } from 'vuex';
import { DemoLendingStatusDialogState } from '@/stores/types';
import { DemoLending } from '@/domain/DemoEnv/Types';
import * as api from '@/api/demoenv';
import * as sessionStore from '@/stores/session';
import * as lendstatus from '@/domain/DemoEnv/LendingStatus';
import { form2modelLending } from '@/views/demoenv/form2model';
import { today } from '@/domain/DemoEnv/Date';

const state: DemoLendingStatusDialogState = {
  newLendingStatus: '',
  demoLending: new DemoLending(),
  statusUpdateDialog: false,
  endDate: '',
  endedDialog: false,
  callback: () => {
    return;
  },
};

const getters = {
  endDate(st: DemoLendingStatusDialogState): string {
    return st.endDate;
  },
};

const actions = {
  startLendingStatusUpdateDialog(
    context: ActionContext<DemoLendingStatusDialogState, {}>,
    arg: {
      newLendingStatus: string;
      lending: DemoLending;
      callback: () => void;
    }
  ) {
    context.commit('setNewLendingStats', arg.newLendingStatus);
    context.commit('setDemoLending', arg.lending);
    context.commit('setCallback', arg.callback);
    context.commit('setEndDate', '');

    // ステータスに応じた処理(ダイアログ表示など)
    const status = arg.newLendingStatus;
    if (status == lendstatus.WAITING) {
      context.commit('switchStatusUpdateDialog', true);
      return;
    }
    if (status == lendstatus.LENDING) {
      context.commit('switchStatusUpdateDialog', true);
      return;
    }
    if (status == lendstatus.END_OF_USE) {
      const endDate = context.state.demoLending.endDate
        ? context.state.demoLending.endDate
        : today('YYYY-MM-DD');
      context.commit('setEndDate', endDate);
      context.commit('switchEndedDialog', true);
      return;
    }
    context.commit('switchStatusUpdateDialog', true);
  },
  setEndDate(
    context: ActionContext<DemoLendingStatusDialogState, {}>,
    endDate: string
  ) {
    context.commit('setEndDate', endDate);
  },
  switchStatusUpdateDialog(
    context: ActionContext<DemoLendingStatusDialogState, {}>,
    value: boolean
  ) {
    context.commit('switchStatusUpdateDialog', value);
  },
  switchEndedDialog(
    context: ActionContext<DemoLendingStatusDialogState, {}>,
    value: boolean
  ) {
    context.commit('switchEndedDialog', value);
  },
  async update(
    context: ActionContext<DemoLendingStatusDialogState, {}>,
    token: string
  ) {
    try {
      // 待機中から貸出中に変更する(=割当する)場合の処理
      if (
        context.state.newLendingStatus == lendstatus.LENDING &&
        context.state.demoLending.lendingStatus == lendstatus.WAITING
      ) {
        await api.assignLendingEnvironment(
          sessionStore.getToken(),
          context.state.demoLending.demoEnvId,
          context.state.demoLending.demoLendingId,
          true
        );
      }

      context.commit('setLendingStatus', context.state.newLendingStatus);
      context.commit('setLendingEndDate', context.state.endDate);

      // ステータスの変更と貸出情報の更新が同時に行われる場合の、トランザクションのはじめと終わりをどこにするかについて悩んだのでとりあえず別々で行う
      if (context.state.demoLending.lendingStatus == lendstatus.END_OF_USE) {
        await api.updateDemoLendings(token, [
          form2modelLending(context.state.demoLending),
        ]);
      }
      await api.updateLendingStatus(
        token,
        form2modelLending(context.state.demoLending)
      );

      // コールバック関数の実行
      context.state.callback();
    } catch {
      return false;
    }
    return true;
  },
};

const mutations = {
  setNewLendingStats(
    st: DemoLendingStatusDialogState,
    newLendingStatus: string
  ) {
    st.newLendingStatus = newLendingStatus;
  },
  setLendingStatus(
    st: DemoLendingStatusDialogState,
    newLendingStatus: lendstatus.LendingStatus
  ) {
    st.demoLending.lendingStatus = newLendingStatus;
  },
  setDemoLending(st: DemoLendingStatusDialogState, lending: DemoLending) {
    st.demoLending = lending;
  },
  setCallback(st: DemoLendingStatusDialogState, callback: () => void) {
    st.callback = callback;
  },
  setEndDate(st: DemoLendingStatusDialogState, endDate: string) {
    st.endDate = endDate;
  },
  setLendingEndDate(st: DemoLendingStatusDialogState, endDate: string) {
    st.demoLending.endDate = endDate;
  },
  switchStatusUpdateDialog(st: DemoLendingStatusDialogState, value: boolean) {
    st.statusUpdateDialog = value;
  },
  switchEndedDialog(st: DemoLendingStatusDialogState, value: boolean) {
    st.endedDialog = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
