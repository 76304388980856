import { PlatonDivision } from '@/domain/PlatonContract/Types';

export class VersionUpForm {
  constructor(
    public platonId = 0,
    public division = PlatonDivision.Otegaru,
    public endUserName = '',

    public currentVersion = '',
    public lastUpdateDate = '',
    public updateVersion = '',
    public updateDate = '',

    public currentInstaller = '',
    public updateInstaller = ''
  ) {}
}
