import { PLATON, LIBRA } from '@/domain/Product';

interface LendingCheckPointsInterface {
  waiting: any;
  lending: any;
}

// Platon
const PlatonWaitingCheckPoint = {
  checkpoints: ['初期化が完了しているか確認する。'],
  informations: ['初期化が完了し、利用準備をしている状態。'],
  steps: [
    '貸出用の管理者アカウントのパスワードを初期値から別のパスワードに変更する。(ステータス「貸出中にする」)',
    'レポート機能用アカウント及びパスワードを発行する。',
    '希望があれば、特別研修システム用アカウント及びパスワードを発行する。',
  ],
};

const PlatonLendingCheckPoint = {
  checkpoints: [
    '講座＞個人情報保護＞教材リスト＞4講座が閲覧できることを確認する',
  ],
  informations: [
    '貸出文面はこちら。https://docs.google.com/spreadsheets/d/1pnmYeH8lSSjEaiK2mS4_hnjif8o95eT_ttkiHqKTTb0/edit#gid=0',
  ],
  steps: [
    '貸出期限が過ぎたら環境を回収し、ステータスを「初期化依頼中」にする',
    '初期化依頼を出す際は、環境の管理画面にログインし、貸出用アカウントのパスワードを変更する（利用できない様にする為）',
  ],
};

export class PlatonLendingCheckPoints implements LendingCheckPointsInterface {
  constructor(
    public waiting = PlatonWaitingCheckPoint,
    public lending = PlatonLendingCheckPoint
  ) {}
}

// Libra
const LibraWaitingCheckPoint = {
  checkpoints: ['初期化が完了しているか確認する。'],
  informations: ['初期化が完了し、利用準備をしている状態。'],
  steps: [
    'コンテンツ管理、カテゴリー管理、ユーザ管理、組織グループ管理、コミュニティ管理 の項目で情報が初期化されているか確認する',
  ],
};

const LibraLendingCheckPoint = {
  checkpoints: [
    'コンテンツ管理、カテゴリー管理、ユーザ管理、組織グループ管理、コミュニティ管理 が初期化されていることを確認する',
  ],
  informations: [
    '貸出文面はこちら。https://docs.google.com/spreadsheets/d/1pnmYeH8lSSjEaiK2mS4_hnjif8o95eT_ttkiHqKTTb0/edit#gid=1565420425',
    'システム設定でfaviconがLibraの物になっている',
    '詳細カスタマイズ設定で「ヘッダーHTML」「フッターHTML」を初期化する',
    '稀にヘッダーロゴが追加されていることがあるので、追加されていたら削除する',
  ],
  steps: [
    '貸出期限が過ぎたら環境を回収し、ステータスを「初期化依頼中」にする',
    '初期化依頼を出す際は、環境の管理画面にログインし、貸出用アカウントのパスワードを変更する（利用できない様にする為）',
  ],
};

export class LibraLendingCheckPoints implements LendingCheckPointsInterface {
  constructor(
    public waiting = LibraWaitingCheckPoint,
    public lending = LibraLendingCheckPoint
  ) {}
}

// コンストラクタ 製品で場合分け
export function newCheckPoints(product: number): LendingCheckPointsInterface {
  if (product == PLATON) {
    return new PlatonLendingCheckPoints();
  }
  if (product == LIBRA) {
    return new LibraLendingCheckPoints();
  }
  return { waiting: {}, lending: {} };
}
