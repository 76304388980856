














































import { Component, Vue, Prop } from 'vue-property-decorator';
import { GanttChartSetting } from './Types';
import { DisplayCondition } from './ToolBar';

@Component({
  components: {},
})
export default class GanttToolBar extends Vue {
  @Prop({ type: Object, required: true })
  private setting!: GanttChartSetting;

  @Prop({ type: Object, required: false })
  private displayCondition!: DisplayCondition;

  @Prop({ type: Boolean, required: true })
  private editMode!: boolean;
}
