export const lendingEndDateValidation = {
  params: [
    {
      name: 'startDateValue',
      isTarget: true,
    },
  ],
  message: '終了日は開始日より後の日付を入力してください',
  validate(endDateValue: string, { startDateValue }: any) {
    if (!endDateValue) return true;
    const startDate = new Date(startDateValue);
    const endDate = new Date(endDateValue);
    return startDate <= endDate;
  },
};
