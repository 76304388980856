





























import { Component, Vue } from 'vue-property-decorator';
import { mapActions, mapState, mapMutations } from 'vuex';
import ActionMenu from '@/components/molecules/ActionMenu.vue';
import KeywordSearch from '@/components/molecules/KeywordSearch.vue';
import Navigation from '@/components/Navigation.vue';
import LibraListTable from '@/components/libra/LibraListTable.vue';
import * as api from '@/api/libra.ts';
import { LibraDivision } from '@/domain/LibraContract/Types';
import * as sessionStore from '@/stores/session';
import { LibraContract } from '../../domain/LibraContract/LibraContract';

@Component({
  components: {
    ActionMenu,
    KeywordSearch,
    Navigation,
    LibraListTable,
  },
  computed: {
    ...mapState('libraOtegaruList', ['stateKeywords', 'stateContractStatus']),
  },
  methods: {
    ...mapMutations('libraOtegaruList', ['setKeywords', 'setContractStatus']),
    ...mapActions('alertArea', ['success', 'fail', 'info']),
    ...mapActions('libraNew', ['updateModel', 'updateSearchKeywords']),
  },
})
export default class LibraList extends Vue {
  private items: LibraContract[] = [];
  private intervalId!: number;

  private searchTargetLabel = [
    '顧客名',
    '代理店名',
    'プラン',
    'URL',
    'SiteID',
    'オプション',
    '備考',
  ];

  private actionMenu = [
    {
      icon: 'add',
      text: '契約を追加する',
      color: 'primary',
      click: () => {
        this.openNew();
      },
    },
    {
      icon: 'note_add',
      text: '申込書を取り込む',
      color: 'primary',
      click: () => {
        this.uploadSheet();
      },
    },
    {
      icon: 'cloud_download',
      text: 'CSVでダウンロードする',
      color: 'secondary',
      click: () => {
        this.downloadList();
      },
    },
  ];

  private stateKeywords!: string;
  private setKeywords!: (keywords: string) => void;
  private get keywords(): string {
    return this.stateKeywords;
  }
  private set keywords(value: string) {
    this.setKeywords(value);
  }

  private stateContractStatus!: string;
  private setContractStatus!: (status: string) => void;
  private get contractStatus(): string {
    return this.stateContractStatus;
  }
  private set contractStatus(value: string) {
    this.setContractStatus(value);
  }

  private success!: (message: string) => void;
  private fail!: (message: string) => void;
  private info!: (message: string) => void;

  private updateModel!: (model: LibraContract) => void;
  private updateSearchKeywords!: (keywords: {
    endUserName: string;
    salesAgentName: string;
  }) => void;

  private get LibraDivision() {
    return LibraDivision;
  }

  private async created() {
    await this.search();
  }

  private mounted() {
    let prev = '';
    this.intervalId = setInterval(async () => {
      if (this.keywords !== prev) {
        prev = this.keywords;
        await this.search();
      }
    }, 1000);
  }

  private beforeDestroy() {
    clearInterval(this.intervalId);
  }

  private async search() {
    try {
      this.items = await api.listOtegaru(
        sessionStore.getToken(),
        0,
        0,
        this.keywords,
        this.contractStatus
      );
    } catch {
      this.fail('お手軽Libra一覧の取得に失敗しました');
    }
  }

  private openNew() {
    this.$router.push('new/basic');
  }

  private uploadSheet() {
    const file = document.querySelector('#file') as HTMLElement;
    if (!file) {
      return;
    }
    file.click();
  }

  private async changeFile(e: any) {
    const files: FileList = e.target.files;
    if (files.length === 0) {
      return;
    }

    try {
      const libra = await api.parseSheetOtegaru(
        sessionStore.getToken(),
        files[0]
      );
      this.updateModel(libra);
      this.updateSearchKeywords({
        endUserName: libra.endUser.customerName,
        salesAgentName: libra.salesAgent ? libra.salesAgent.customerName : '',
      });
    } catch {
      this.fail('申込書の取り込みに失敗しました');
      await this.info(
        'ファイルの作成方法により、セルの位置など問題ないのに取り込みに失敗する場合があります,'
      );
      await this.info(
        '何度やっても取り込みに失敗する場合は、お手数ですが手入力により登録ください'
      );
      return;
    }

    // vuex保存
    this.$router.push('new/basic');
  }

  private async downloadList() {
    try {
      await api.donwnloadOtegaru(
        sessionStore.getToken(),
        0,
        0,
        this.keywords,
        this.contractStatus
      );
    } catch {
      this.fail('お手軽Libra一覧のダウンロードに失敗しました');
    }
  }
}
