
















import { Component, Prop, Vue, Inject } from 'vue-property-decorator';
import { DemoLending } from '@/domain/DemoEnv/Types';
import { Customer } from '@/domain/Customer';
import CustomerSearchSelector from '@/components/customer/CustomerSearchSelector.vue';
import { CustomerApiInterface } from '../../interfaces';

@Component({
  components: {
    CustomerSearchSelector,
  },
})
export default class LendingCustomerSelector extends Vue {
  @Prop({ type: Object, required: true })
  private demoLending!: DemoLending;
  @Prop({
    type: Array,
    required: false,
    default: () => {
      [];
    },
  })
  private classes!: string[];
  @Prop({ type: Boolean, required: false, default: false })
  private required!: boolean;

  @Inject('customerApiClient')
  private client!: CustomerApiInterface;

  private value = '';
  private endUserKeywords = '';
  private endusers: Customer[] = [];
  public async created() {
    this.endUserKeywords = '';
    await this.searchEndUsers('');
  }

  private async searchEndUsers(keywords: string) {
    try {
      this.endusers = await this.client.list(5, 0, keywords);
    } catch {
      this.$emit('fail', '顧客一覧(代理店)の取得に失敗しました');
      return;
    }
  }
  // eslint-disable-next-line
  private updateEndUserKeywords(keywords: string) {
    this.endUserKeywords = keywords;
    //
  }
  // eslint-disable-next-line
  private async addCustomer(keywords: string) {
    if (keywords === '') {
      this.$emit('fail', '顧客名が入力されていません');
      return;
    }

    const customer = new Customer(0, keywords);
    try {
      await this.client.add(customer);
      this.$emit('success', '顧客を追加しました');
    } catch (e) {
      this.$emit('fail', e.response.data.message);
    }

    this.searchEndUsers(this.endUserKeywords);
  }
  private updateDemoUser() {
    if (this.value === undefined) {
      this.demoLending.customerId = 0;
      this.demoLending.customerName = '';
    }
    const customerId = Number(this.value);
    const user = this.endusers.filter(eu => {
      return eu.customerId == customerId;
    });
    if (user.length == 0) return;
    this.demoLending.customerId = customerId;
    this.demoLending.customerName = user[0].customerName;
  }
}
