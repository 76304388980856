import { PLATON, LIBRA, ProductId } from '@/domain/Product';
// export const PLATON = 'platon';
export const PLATON_LABEL = 'お手軽Platon';
// export const LIBRA = 'libra';
export const LIBRA_LABEL = 'お手軽Libra';
// export const SPOTTY = 'spotty';
export const SPOTTY_LABEL = 'Spotty';

export interface ProductStyle {
  value: ProductId;
  name: string;
  label: string;
  color: string;
  image: any;
  logo: any;
}

export const PRODUCTS_STYLES: ProductStyle[] = [
  {
    value: PLATON,
    name: 'platon',
    label: PLATON_LABEL,
    color: 'info',
    image: '/img/platonTextLogo.png',
    logo: '/img/platonLogo.jpg',
  },
  {
    value: LIBRA,
    name: 'libra',
    label: LIBRA_LABEL,
    color: 'danger',
    image: '/img/libraTextLogo.png',
    logo: '/img/libraLogo.jpg',
  },
  // {
  //   value: SPOTTY,
  //   name: 'spotty',
  //   label: SPOTTY_LABEL,
  //   color: 'success',
  //   image: require('@/assets/spottyTextLogo.png'),
  // },
];

export function getProductStyle(product: number): any {
  const style = PRODUCTS_STYLES.filter(item => {
    return item.value == product;
  });
  if (style.length != 0) return style[0];
  return {
    value: PLATON,
    label: PLATON_LABEL,
    color: 'info',
  };
}
