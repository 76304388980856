









import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class FormSection extends Vue {
  @Prop({ type: String, required: true })
  private title!: string;
  @Prop({ type: String, required: true })
  private text!: string;
}
