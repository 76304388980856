export class Account {
  constructor(
    public accountId = 0,
    public loginId = '',
    public email = '',
    public familyName = '',
    public givenName = '',
    public beginDate = '',
    public endDate = '',
    public departments: Array<Department> = [],
    public learningGroups = [],
    public attributes = []
  ) {}
}

export type Department = {
  departmentId: number;
  departmentCode: string;
  departmentName: string;
  isMain: boolean;
  isLeader: boolean;
};
