export class NewDemoLending {
  static classPrefix = 'dm-newlnd';
}

export class Form {
  static classPrefix = 'dm-newlnd-form';
}

export class Confirmation {
  static classPrefix = 'dm-newlnd-confirmation';
}
