export const required = {
  message: '{_field_}は必須項目です。',
  validate(value: any) {
    return {
      required: true,
      valid: ['', null, undefined].indexOf(value) === -1,
    };
  },
  computesRequired: true,
};
export const min = {
  params: ['minimum'],
  message: '{_field_}は{minimum}以上にする必要があります',
  validate(value: number, { minimum }: any) {
    minimum = Number(minimum);
    return minimum <= value;
  },
  computesRequired: true,
};

export const minmax = {
  params: ['minimum', 'maximum'],
  message: '{_field_}は{minimum}～{maximum}の範囲にする必要があります',
  validate(value: number, { minimum, maximum }: any) {
    minimum = Number(minimum);
    maximum = Number(maximum);
    return minimum <= value && value <= maximum;
  },
  computesRequired: true,
};
