import { ActionContext } from 'vuex';
import { AccountState } from '@/stores/types.ts';
import { Account } from '@/domain/Account';

const state: AccountState = {
  account: new Account(),
};

const getters = {};

const actions = {
  setAccount(context: ActionContext<Account, {}>, account: string) {
    const obj = JSON.parse(account);
    context.commit(
      'setAccount',
      new Account(obj.accountId, obj.email, obj.familyName, obj.givenName)
    );
  },
  clearAccount(context: ActionContext<Account, {}>) {
    context.commit('clearAccount');
  },
};

const mutations = {
  setAccount(st: AccountState, account: Account) {
    st.account = account;
  },
  clearAccount(st: AccountState) {
    st.account = new Account();
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
