













import { Component, Vue } from 'vue-property-decorator';
import Navigation from '@/components/Navigation.vue';

@Component({
  components: {
    Navigation,
  },
})
export default class Products extends Vue {
  private headers = [
    {
      text: '製品名',
      value: 'productName',
    },
    {
      text: 'プラン名',
      value: 'planName',
    },
    {
      text: 'タイプ名',
      value: 'typeName',
    },
  ];
  private items = [
    {
      productName: 'お手軽Platon',
      planName: '従量課金制',
      typeName: 'タイプA',
    },
    {
      productName: 'お手軽Platon',
      planName: '従量課金制',
      typeName: 'タイプB',
    },
    {
      productName: 'お手軽Platon',
      planName: '従量課金制',
      typeName: 'タイプC',
    },
    {
      productName: 'お手軽Platon',
      planName: '月額定額',
      typeName: '100',
    },
    {
      productName: 'お手軽Platon',
      planName: '月額定額',
      typeName: '500',
    },
    {
      productName: 'お手軽Platon',
      planName: '月額定額',
      typeName: '1000',
    },
  ];
}
