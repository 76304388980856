import { DemoEnvironment, DemoLending } from '@/domain/DemoEnv/Types';
import {
  DemoLendingOption,
  DemoLendingOptionData,
} from '@/domain/DemoEnv/LendingOption';

// APIから取得した連想配列データを同型オブジェクトへ変換する
// (型チェック、メソッドなどを使用できるようにするため)
export const model2formLending = (data: DemoLending): DemoLending => {
  data.customerId = String(data.customerId);
  if (!data.options) data.options = [];
  const options: DemoLendingOption[] = [];
  data.options.forEach(opt => {
    if (typeof opt.data === 'string') {
      opt.data = JSON.parse(String(opt.data)) as DemoLendingOptionData;
    }
    opt.data['using'] = 'true';

    const optObj = new DemoLendingOption(
      'edit',
      opt.optionId != undefined ? opt.optionId : -1,
      opt.optionsTypeId != undefined ? opt.optionsTypeId : -1,
      opt.productId != undefined ? opt.productId : -1,
      opt.division ? opt.division : '',
      opt.optionVersion ? opt.optionVersion : '',
      opt.demoLendingId != undefined ? opt.demoLendingId : -1,
      opt.data ? opt.data : { using: 'false' }
    );
    options.push(optObj);
  });
  data.startDate = data.startDate.substr(0, 10);
  if (!data.endDate) {
    data.endDate = '';
  } else {
    data.endDate = data.endDate.substr(0, 10);
  }

  return new DemoLending(
    data.demoLendingId,
    data.customerId,
    data.customerName,
    data.userClass,
    data.lendingStatus,
    data.startDate,
    data.endDate,
    data.responsibleUserName,
    options,
    data.contractResult,
    data.memo,
    data.assigned,
    data.demoEnvId
  );
};

// APIから取得した連想配列データを同型オブジェクトへ変換する
// (型チェック、メソッドなどを使用できるようにするため)
export function model2formEnvironment(model: DemoEnvironment): DemoEnvironment {
  const obj = JSON.parse(JSON.stringify(model)) as DemoEnvironment;

  return new DemoEnvironment(
    obj.demoEnvId,
    obj.productId,
    obj.name,
    obj.role,
    obj.environmentStatus,
    obj.adminId,
    obj.adminName,
    obj.memo,
    obj.createdAt,
    obj.updatedAt,
    obj.urls,
    obj.lendings.map(lending => {
      return model2formLending(lending);
    }),
    true
  );
}
