
























































import { Component, Vue, Watch } from 'vue-property-decorator';
import ActionMenu from '@/components/molecules/ActionMenu.vue';
import DatePicker from '@/components/molecules/DatePicker.vue';
import { Account } from '@/domain/Account';
import DemoEnvironmentCard from '@/components/demoenv/DemoEnvironmentCard.vue';
import LendingCard from '@/components/demoenv/LendingCard.vue';
import { mapActions, mapState } from 'vuex';
import * as sessionStore from '@/stores/session';
import { DemoEnvironment, DemoLending } from '@/domain/DemoEnv/Types';
import * as Table from '@/views/demoenv/table';
import { dateDisplay } from '@/domain/DemoEnv/Date';
import * as envstatus from '@/domain/DemoEnv/EnvironmentStatus';
import * as lendstatus from '@/domain/DemoEnv/LendingStatus';

@Component({
  components: {
    ActionMenu,
    DatePicker,
    DemoEnvironmentCard,
    LendingCard,
  },
  computed: {
    ...mapState('accountInfo', ['account']),
    ...mapState('demoManagementBoard', {
      product: 'product',
      demoenvs: 'demoenvs',
      demoLendings: 'demoLendings',
      editMode: 'edit',
    }),
    ...mapState('demoLendingReservation', [
      'demoEnvId',
      'demoLendingId',
      'dialog',
    ]),
  },
  methods: {
    ...mapActions('demoManagementBoard', [
      'editModeActivate',
      'listDemoEnvironments',
      'listDemoLendings',
    ]),
    ...mapActions('alertArea', ['success', 'fail', 'warning']),
    ...mapActions('demoLendingReservation', {
      setDemoLendingId: 'setDemoLendingId',
      reserveDemoLending: 'submit',
      dialogEnd: 'dialogEnd',
    }),
  },
})
export default class LendingReservation extends Vue {
  private demoEnvId!: number;
  private demoLendingId!: number;
  private demoenvs!: DemoEnvironment[];
  private get demoEnvironment() {
    return (demoEnvId: number) => {
      const env = this.demoenvs.filter(env => {
        return env.demoEnvId == demoEnvId;
      });
      if (env.length == 0) return new DemoEnvironment();
      return env[0];
    };
  }
  private demoenvsTableData: DemoEnvironment[] = [];
  private environmentItems = envstatus.ENVIRONMENT_STATUS_ITEMS;
  private environmentListCriteria!: Table.EnvironmentListCriteria;
  private lendingsHeader!: any[];
  private reservableLendingsHeader!: any[];
  private demoLendings!: DemoLending[];
  private selectedDemoLending: DemoLending = new DemoLending();
  private lendingSettings = lendstatus.LENDING_SETTING;
  private lendingStatusItems = lendstatus.LENDING_STATUS_ITEMS.slice(
    0,
    lendstatus.LENDING_STATUS_ITEMS.length - 1
  );
  private get dateDisplay() {
    return dateDisplay;
  }
  private lendingListCriteria!: Table.LendingListCriteria;
  private get reservedLendings() {
    return (lendings: DemoLending[]) => {
      return lendings.filter(l => {
        return l.lendingStatus != lendstatus.ENDED;
      });
    };
  }
  private reserveDialog = false;
  private reserveDialogScene = '';
  private get reserveTargetEnvironment(): DemoEnvironment {
    const lending = this.demoenvs.filter(env => {
      return env.demoEnvId == this.demoEnvId;
    });
    if (lending.length == 0) return new DemoEnvironment();
    return lending[0];
  }
  private reserveTargetLending = [] as DemoLending[];
  private get reservableLendings() {
    return this.demoLendings.filter(l => {
      return !l.assigned && l.lendingStatus == lendstatus.WAITING;
    });
  }
  private cancelReserveTargets: DemoLending[] = [];
  private addLendingDialogStart!: () => {};
  private addLendingDialogEnd!: () => {};
  private editMode!: boolean;
  private editModeActivate!: (value: boolean) => {};
  private dialog!: boolean;
  private account!: Account;
  private success!: (message: string) => void;
  private fail!: (message: string) => void;
  private warning!: (message: string) => void;
  private get lendingStatusSetting() {
    return lendstatus.getLendingStatusSetting;
  }
  private async created() {
    const lendingsTable = new Table.LendingsTable();
    this.lendingsHeader = lendingsTable.header.filter(item => {
      return item.value != 'demoEnvId';
    });
    this.lendingListCriteria = lendingsTable.criteria;

    this.reservableLendingsHeader = [];
    lendingsTable.header.map(h => {
      const item = {
        value: h.value,
        text: h.text,
      };
      if (h.value == 'demoEnvId') {
        item.text = '予約先';
      }
      this.reservableLendingsHeader.push(item);
    });
  }
  @Watch('dialog')
  private init() {
    if (!this.dialog) return;
    this.listDemoEnvironments(sessionStore.getToken());
    this.listDemoLendings(sessionStore.getToken());
  }
  private listDemoEnvironments!: (token: string) => {};
  private listDemoLendings!: (token: string) => {};
  private setDemoLendingId!: (id: number) => {};
  private reserveDemoLending!: (token: string) => Promise<void>;
  private dialogEnd!: () => {};
  private async reserve() {
    const lending = this.reserveTargetLending;
    try {
      this.setDemoLendingId(lending[0].demoLendingId);
      await this.reserveDemoLending(sessionStore.getToken());
      this.$emit('update', true);
    } catch {
      this.fail('貸出情報の予約に失敗しました。');
    }
    this.dialogEnd();
  }
  private cancel() {
    this.dialogEnd();
  }
}
