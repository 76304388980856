import { CustomerApiInterface } from '../interfaces/ICustomerAPI';
import { Customer } from '@/domain/Customer';
import * as customerApi from '@/api/customer';

export class CustomerAPI implements CustomerApiInterface {
  constructor(public getToken = () => '') {}
  public add(newCustomer: Customer) {
    return customerApi.add(this.getToken(), newCustomer);
  }
  public list(limit: number, offset: number, keywords: string) {
    return customerApi.list(this.getToken(), limit, offset, keywords);
  }
}
