














import { Component, Prop, Vue } from 'vue-property-decorator';
import { DemoEnvironment } from '@/domain/DemoEnv/Types';
import * as envstatus from '@/domain/DemoEnv/EnvironmentStatus';

@Component
export default class EnvironmentStatusChip extends Vue {
  @Prop({ type: Object, required: true })
  private demoenv!: DemoEnvironment;

  // その他のプロパティ
  @Prop({ type: Boolean, required: false, default: false })
  private large!: boolean;
  @Prop({ type: Boolean, required: false, default: false })
  private small!: boolean;

  private get envStatusSetting() {
    return envstatus.getEnvironmentStatusSetting;
  }
}
