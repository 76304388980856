import { post, binary } from './io';
import { clean } from './helper_keywords';
import { PlatonContract } from '@/domain/PlatonContract/PlatonContract';
import { PlatonVersion } from '@/domain/PlatonContract/PlatonVersion';
import { PlatonInstaller } from '@/domain/PlatonContract/PlatonInstaller';
import { PlatonContractLog } from '@/domain/PlatonContract/PlatonContractLog';

const rootUrl = process.env.VUE_APP_URL + '/base';

export const listOnPremise = async (
  token: string,
  limit: number,
  offset: number,
  keywords: string,
  contractStatus: string
): Promise<PlatonContract[]> => {
  const url = rootUrl + '/unites/platon/onpremise/list';
  const params = {
    limit,
    offset,
    keywords: clean(keywords),
    contractStatus,
  };
  const headers = { Authorization: 'Bearer ' + token };
  const ret = await post(url, params, { headers });
  return Promise.resolve(ret.platons);
};

export const listOtegaru = async (
  token: string,
  limit: number,
  offset: number,
  keywords: string,
  contractStatus: string
): Promise<PlatonContract[]> => {
  const url = rootUrl + '/unites/platon/otegaru/list';
  const params = {
    limit,
    offset,
    keywords: clean(keywords),
    contractStatus,
  };
  const headers = { Authorization: 'Bearer ' + token };
  const ret = await post(url, params, { headers });
  return Promise.resolve(ret.platons);
};

export const donwnloadOnPremise = async (
  token: string,
  limit: number,
  offset: number,
  keywords: string,
  contractStatus: string
): Promise<PlatonContract[]> => {
  const url = rootUrl + '/unites/platon/onpremise/list/download';
  const params = {
    limit,
    offset,
    keywords: clean(keywords),
    contractStatus,
  };
  const headers = { Authorization: 'Bearer ' + token };
  const ret = await binary(url, params, {
    headers,
    responseType: 'arraybuffer',
  });
  return Promise.resolve(ret.platons);
};

export const donwnloadOtegaru = async (
  token: string,
  limit: number,
  offset: number,
  keywords: string,
  contractStatus: string
): Promise<PlatonContract[]> => {
  const url = rootUrl + '/unites/platon/otegaru/list/download';
  const params = {
    limit,
    offset,
    keywords: clean(keywords),
    contractStatus,
  };
  const headers = { Authorization: 'Bearer ' + token };
  const ret = await binary(url, params, {
    headers,
    responseType: 'arraybuffer',
  });
  return Promise.resolve(ret.platons);
};

export const addOnPremise = async (
  token: string,
  platon: PlatonContract
): Promise<PlatonContract> => {
  const url = rootUrl + '/unites/platon/onpremise/add';
  const params = {
    platon,
  };
  const headers = { Authorization: 'Bearer ' + token };
  const ret = await post(url, params, { headers });
  return Promise.resolve(ret.platon);
};

export const addOtegaru = async (
  token: string,
  platon: PlatonContract
): Promise<PlatonContract> => {
  const url = rootUrl + '/unites/platon/otegaru/add';
  const params = {
    platon,
  };
  const headers = { Authorization: 'Bearer ' + token };
  const ret = await post(url, params, { headers });
  return Promise.resolve(ret.platon);
};

export const deleteOnPremise = async (
  token: string,
  platonId: number
): Promise<PlatonContract> => {
  const url = rootUrl + '/unites/platon/onpremise/delete';
  const params = {
    platonId,
  };
  const headers = { Authorization: 'Bearer ' + token };
  const ret = await post(url, params, { headers });
  return Promise.resolve(ret.platon);
};

export const deleteOtegaru = async (
  token: string,
  platonId: number
): Promise<PlatonContract> => {
  const url = rootUrl + '/unites/platon/otegaru/delete';
  const params = {
    platonId,
  };
  const headers = { Authorization: 'Bearer ' + token };
  const ret = await post(url, params, { headers });
  return Promise.resolve(ret.platon);
};

export const detailOnPremise = async (
  token: string,
  platonId: number
): Promise<PlatonContract> => {
  const url = rootUrl + '/unites/platon/onpremise/detail';
  const params = {
    platonId,
  };
  const headers = { Authorization: 'Bearer ' + token };
  const ret = await post(url, params, { headers });
  return Promise.resolve(ret.platon);
};

export const detailOtegaru = async (
  token: string,
  platonId: number
): Promise<PlatonContract> => {
  const url = rootUrl + '/unites/platon/otegaru/detail';
  const params = {
    platonId,
  };
  const headers = { Authorization: 'Bearer ' + token };
  const ret = await post(url, params, { headers });
  return Promise.resolve(ret.platon);
};

export const updateOnPremise = async (
  token: string,
  platon: PlatonContract
): Promise<PlatonContract> => {
  const url = rootUrl + '/unites/platon/onpremise/update';
  const params = {
    platon,
  };
  const headers = { Authorization: 'Bearer ' + token };
  const ret = await post(url, params, { headers });
  return Promise.resolve(ret.platon);
};

export const updateOtegaru = async (
  token: string,
  platon: PlatonContract
): Promise<PlatonContract> => {
  const url = rootUrl + '/unites/platon/otegaru/update';
  const params = {
    platon,
  };
  const headers = { Authorization: 'Bearer ' + token };
  const ret = await post(url, params, { headers });
  return Promise.resolve(ret.platon);
};

export const parseSheetOtegaru = async (
  token: string,
  file: File
): Promise<PlatonContract> => {
  const url = rootUrl + '/unites/platon/otegaru/parsesheet';
  const headers = {
    Authorization: 'Bearer ' + token,
    'Content-Type': 'multipart/form-data',
  };
  const params = new FormData();
  params.append('sheet', file);

  const ret = await post(url, params, { headers });
  return Promise.resolve(ret.platon);
};

export const listPlatonContractLogs = async (
  token: string,
  limit: number,
  offset: number,
  platonId: number
): Promise<PlatonContractLog[]> => {
  const url = rootUrl + '/unites/platon/logs';
  const params = {
    limit,
    offset,
    platonId,
  };
  const headers = { Authorization: 'Bearer ' + token };
  const ret = await post(url, params, { headers });
  return Promise.resolve(ret.logs);
};

export const updateVersion = async (
  token: string,
  platonId: number,
  version: PlatonVersion
): Promise<void> => {
  const url = rootUrl + '/unites/platon/version/update';
  const params = {
    platonId,
    version,
  };
  const headers = { Authorization: 'Bearer ' + token };
  await post(url, params, { headers });
  return Promise.resolve();
};

export const updateInstaller = async (
  token: string,
  platonId: number,
  installer: PlatonInstaller
): Promise<void> => {
  const url = rootUrl + '/unites/platon/installer/update';
  const params = {
    platonId,
    installer,
  };
  const headers = { Authorization: 'Bearer ' + token };
  await post(url, params, { headers });
  return Promise.resolve();
};
