


























import { Component, Prop, Vue } from 'vue-property-decorator';
import { ValidationProvider } from 'vee-validate';

@Component({
  components: {
    ValidationProvider,
  },
})
export default class TextField extends Vue {
  private format = /^\d{4}-\d{2}-\d{2}$/g;

  @Prop({ type: String })
  private vid!: string;

  @Prop({ type: String })
  private rules!: string;

  @Prop({ type: String, required: true })
  private label!: string;

  @Prop({ type: String })
  private type!: string;

  @Prop({ type: String })
  private placeHolder!: string;

  @Prop({ type: String })
  private step!: string;

  @Prop({ type: String })
  private min!: string;

  @Prop({ type: String })
  private max!: string;

  @Prop({ type: Boolean })
  private readonly!: boolean;

  @Prop({ type: Boolean })
  private required!: boolean;

  @Prop({ type: [String, Number], required: true })
  private value!: any;

  @Prop({ type: Boolean })
  private slim!: string;

  private get rulesProp() {
    let rules = this.rules ? this.rules : '';
    if (this.required) {
      rules = rules ? rules + '|' : '';
      rules += 'required';
    }
    return rules;
  }
}
