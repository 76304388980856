import { post, get } from './io';

const rootUrl = process.env.VUE_APP_URL + '/base';
const origin = window.location.protocol + '//' + window.location.host;

export const describeAccount = async (
  token: string,
  accountId: number
): Promise<Account> => {
  const url = rootUrl + '/userbase/account/describe';
  const params = { ID: accountId };
  const headers = { Authorization: 'Bearer ' + token };
  const ret = await post(url, params, { headers });
  return Promise.resolve(ret);
};

export const googleLogin = async (): Promise<string> => {
  const url = rootUrl + '/userbase/auth/google';

  const params = new URLSearchParams();
  params.set('origin', origin);

  const ret = await post(url + '?' + params.toString());
  return Promise.resolve(ret.redirectUrl);
};

export const googleLoginCallback = async (paramsStr: string): Promise<any> => {
  const url = rootUrl + '/userbase/auth/google/callback';

  const params = new URLSearchParams(paramsStr);
  params.set('origin', origin);

  const ret = await get(url + '?' + params.toString());
  return Promise.resolve(ret);
};

export const verify = async (token: string): Promise<Account> => {
  const url = rootUrl + '/userbase/auth/verify-uuid';
  const params = {
    token,
  };

  const ret = await post(url, params);
  return Promise.resolve(ret.account);
};

export const logout = async (token: string): Promise<void> => {
  const url = rootUrl + '/userbase/auth/logout';
  const params = {
    token,
  };

  await post(url, params);
  return Promise.resolve();
};
