import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './stores';
import vuetify from '@/plugins/vuetify';
import VueCompositionApi from '@vue/composition-api';
import { extendAllRules } from '@/domain/Validations';

Vue.use(VueCompositionApi);
extendAllRules();

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app');
