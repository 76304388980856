



















































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Account } from './Account';

@Component
export default class LibraOptionEditor extends Vue {
  @Prop({ type: Array, required: true })
  private accounts!: Account[];

  // newAccount アカウントを追加
  private newAccount() {
    this.accounts.push({ label: '', id: '', password: '' });
  }
  // removeAccount 選択したアカウントを削除
  private removeAccount(idx: number) {
    this.accounts.splice(idx, 1);
  }
}
