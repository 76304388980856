import { DEMO_BASIC } from '@/domain/DemoEnv';
import * as table from '../../table';
export type EditorDisplay = 'environment' | 'lending';

export interface DemoEnvironmentListInterface {
  editorDisplay: EditorDisplay;
  criteria: table.EnvironmentListCriteria;
  init: () => void;
}

export class DemoEnvironmentListProps implements DemoEnvironmentListInterface {
  constructor(
    public editorDisplay: EditorDisplay = 'environment',
    public criteria = new table.EnvironmentListCriteria()
  ) {}
  public init(): DemoEnvironmentListInterface {
    this.editorDisplay = 'environment';
    this.criteria = new table.EnvironmentListCriteria('all', null, DEMO_BASIC);
    return this;
  }
}
