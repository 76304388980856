






















































































































































































































import { Component, Vue } from 'vue-property-decorator';
import { mapActions, mapGetters } from 'vuex';
import { ValidationObserver } from 'vee-validate';
import { SelfSetting } from '@/domain/SelfSetting';
import DatePicker from '@/components/molecules/DatePicker.vue';
import TextField from '@/components/molecules/TextField.vue';
import Select from '@/components/molecules/Select.vue';
import Help from '@/components/billing/Help.vue';
import Navigation from '@/components/Navigation.vue';
import * as billingApi from '@/api/billing';
import * as platonApi from '@/api/platon';
import * as sessionStore from '@/stores/session';
import dayjs from 'dayjs';

@Component({
  components: {
    ValidationObserver,
    DatePicker,
    TextField,
    Select,
    Help,
    Navigation,
  },
  methods: mapActions('alertArea', ['success', 'fail']),
  computed: {
    ...mapGetters('selfSettingStore', ['selfSetting']),
  },
})
export default class Billing extends Vue {
  /**
   * プロパティ
   */
  private helpDialogShow = false;
  private serviceName = '';
  private networkBegin: string = dayjs().format('YYYY-MM-01');
  private networkEnd: string = dayjs()
    .add(1, 'month')
    .subtract(dayjs().date(), 'day')
    .format('YYYY-MM-DD');
  private uniqueLoginYear: number = dayjs().year();
  private uniqueLoginMonth: number = dayjs().month() + 1;
  private lastUniqueLoginYear: number = dayjs().year();
  private lastUniqueLoginMonth: number = dayjs().month() + 1;

  private siteIdExamples: Array<{ value: string; label: string }> = [];
  private advanceContentsYear: number = dayjs().year();
  private advanceContentsMonth: number = dayjs().month() + 1;
  private advanceContentsSiteId = '';
  private selfSetting!: SelfSetting;

  private success!: (message: string) => void;
  private fail!: (message: string) => void;

  private mounted() {
    this.advanceContentsServices();
  }

  private displayAdvanceContents(): boolean {
    return this.selfSetting.displayAdvanceContent;
  }

  /**
   * 操作
   */
  private toggleHelpDialog() {
    this.helpDialogShow = !this.helpDialogShow;
  }

  private changeService(serviceName: string) {
    this.serviceName = serviceName;
  }

  private async submitNetwork(validate: any) {
    validate().then(async (result: any) => {
      if (!result.isValid) {
        return;
      }
      if (this.serviceName === 'platon') {
        this.platonNetwork();
      }
      if (this.serviceName === 'libra') {
        this.libraNetwork();
      }
    });
  }

  private async submitUniqueLogin(validate: any) {
    validate().then(async (result: any) => {
      if (!result.isValid) {
        return;
      }
      if (this.serviceName === 'platon') {
        this.platonUniqueLogin();
      }
      if (this.serviceName === 'libra') {
        this.libraUniqueLogin();
      }
      if (this.serviceName === 'spotty') {
        this.spottyUniqueLogin();
      }
    });
  }

  private async submitLastUniqueLogin(validate: any) {
    validate().then(async (result: any) => {
      if (!result.isValid) {
        return;
      }
      if (this.serviceName === 'platon') {
        this.platonLastUniqueLogin();
      }
    });
  }

  private async submitAdvanceContents(validate: any) {
    validate().then(async (result: any) => {
      if (!result.isValid) {
        return;
      }

      if (this.serviceName === 'monthly-summary') {
        this.advanceContentsMonthlySummary();
      }
      if (this.serviceName === 'monthly-contents') {
        this.advanceContentsMonthlyContents();
      }
      if (this.serviceName === 'monthly-users') {
        this.advanceContentsMonthlyUsers();
      }
    });
  }

  private async platonNetwork() {
    try {
      await billingApi.platonNetworkUsage(
        sessionStore.getToken(),
        this.networkBegin,
        this.networkEnd
      );
    } catch {
      this.fail('お手軽Platon流量の取得に失敗しました');
    }
  }

  private async libraNetwork() {
    try {
      await billingApi.libraNetworkUsage(
        sessionStore.getToken(),
        this.networkBegin,
        this.networkEnd
      );
    } catch {
      this.fail('お手軽Libra流量の取得に失敗しました');
    }
  }

  private async platonUniqueLogin() {
    try {
      await billingApi.platonMonthlyUniqueLogin(
        sessionStore.getToken(),
        this.uniqueLoginYear,
        this.uniqueLoginMonth - 1
      );
    } catch {
      this.fail('お手軽Platonのユニークログイン数の取得に失敗しました');
    }
  }

  private async libraUniqueLogin() {
    try {
      await billingApi.libraMonthlyUniqueLogin(
        sessionStore.getToken(),
        this.uniqueLoginYear,
        this.uniqueLoginMonth - 1
      );
    } catch {
      this.fail('お手軽Libraのユニークログイン数の取得に失敗しました');
    }
  }

  private async spottyUniqueLogin() {
    try {
      await billingApi.spottyMonthlyUniqueLogin(
        sessionStore.getToken(),
        this.uniqueLoginYear,
        this.uniqueLoginMonth - 1
      );
    } catch {
      this.fail('Spottyのユニークログイン数の取得に失敗しました');
    }
  }

  private async platonLastUniqueLogin() {
    try {
      await billingApi.platonLastUniqueLogin(
        sessionStore.getToken(),
        this.lastUniqueLoginYear,
        this.lastUniqueLoginMonth - 1
      );
    } catch {
      this.fail('お手軽Platonの年間ユニークログイン数の取得に失敗しました');
    }
  }

  /**
   * アドバンス教材オプションを選択している契約の一覧を取得する
   */
  private async advanceContentsServices() {
    try {
      const list = await platonApi.listOtegaru(
        sessionStore.getToken(),
        0,
        0,
        'アドバンス教材',
        'all' // 全部取得していますが、本当は選択している年月で契約中のものだけ取るのが理想です
      );
      this.siteIdExamples = list.map(contract => {
        return {
          label: contract.endUserName + '(' + contract.siteId + ')',
          value: contract.siteId,
        };
      });
    } catch {
      this.fail(
        'アドバンス教材オプションを選択している契約の一覧取得に失敗しました'
      );
    }
  }

  private async advanceContentsMonthlySummary() {
    try {
      await billingApi.advanceContentsMonthlySummary(
        sessionStore.getToken(),
        this.advanceContentsYear,
        this.advanceContentsMonth - 1
      );
    } catch {
      this.fail('アドバンス教材閲覧数の全顧客サマリーの取得に失敗しました');
    }
  }

  private async advanceContentsMonthlyContents() {
    try {
      await billingApi.advanceContentsMonthlyContents(
        sessionStore.getToken(),
        this.advanceContentsYear,
        this.advanceContentsMonth - 1,
        this.advanceContentsSiteId
      );
    } catch {
      this.fail(
        'アドバンス教材閲覧数のサイト別レポート(個人情報なし)の取得に失敗しました'
      );
    }
  }

  private async advanceContentsMonthlyUsers() {
    try {
      await billingApi.advanceContentsMonthlyUsers(
        sessionStore.getToken(),
        this.advanceContentsYear,
        this.advanceContentsMonth - 1,
        this.advanceContentsSiteId
      );
    } catch {
      this.fail(
        'アドバンス教材閲覧数のサイト別レポート(個人情報あり)の取得に失敗しました'
      );
    }
  }

  private inputAdvanceContentsSiteId(val: string) {
    this.advanceContentsSiteId = val;
  }

  /**
   * SaaS事業部メンバーである
   */
  private authCloudTeam(): boolean {
    const account = sessionStore.getAccount();
    return account.departments.some(department => {
      return department.departmentCode === 'cloud-unit';
    });
  }
}
