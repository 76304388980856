export type OptionCardMode = 'new' | 'default' | 'edit';

export class DemoLendingOption {
  constructor(
    public mode: OptionCardMode = 'edit',
    public optionId = 0,
    public optionsTypeId = 0,
    public productId = -1,
    public division = '',
    public optionVersion = '',
    public demoLendingId: number = 0,
    public data: DemoLendingOptionData | string
  ) {}
}
export type BoolString = 'true' | 'false';
export interface DemoLendingOptionData extends Record<string, any> {
  using: BoolString | boolean;
}

function isDemoLendingOptionData(
  value: unknown
): value is DemoLendingOptionData {
  return (
    typeof (value as DemoLendingOptionData) === 'object' &&
    'using' in (value as DemoLendingOptionData)
  );
}

export function isDemoLendingOption(obj: unknown): obj is DemoLendingOption {
  return (
    typeof (obj as DemoLendingOption).optionId === 'number' &&
    typeof (obj as DemoLendingOption).optionsTypeId === 'number' &&
    typeof (obj as DemoLendingOption).productId === 'number' &&
    typeof (obj as DemoLendingOption).division === 'string' &&
    typeof (obj as DemoLendingOption).optionVersion === 'string' &&
    typeof (obj as DemoLendingOption).demoLendingId === 'number' &&
    (isDemoLendingOptionData((obj as DemoLendingOption).data) ||
      typeof (obj as DemoLendingOption).data === 'string')
  );
}
