import Vue from 'vue';
import Router, { RouteRecord } from 'vue-router';
import Home from './views/Home.vue';
import Login from './views/Login.vue';
import GoogleLoginCallback from './views/GoogleLoginCallback.vue';
import HomeDashboard from './views/dashboard/HomeDashboard.vue';
import Customers from './views/customer/Customers.vue';
import Products from './views/Products.vue';
/*
 * Platon
 */
import PlatonOtegaruList from './views/platon/PlatonOtegaruList.vue';
import PlatonOnPremiseList from './views/platon/PlatonOnPremiseList.vue';
import PlatonOnPremiseDetail from './views/platon/PlatonOnPremiseDetail.vue';
import PlatonOtegaruDetail from './views/platon/PlatonOtegaruDetail.vue';
import PlatonNewBasic from './views/platon/PlatonNewBasic.vue';
import PlatonNewOptions from './views/platon/PlatonNewOptions.vue';
import PlatonVersionUp from './views/platon/PlatonVersionUp.vue';

import DemoEnv from './views/demoenv/DemoEnv.vue';
/*
 * Libra
 */
import LibraOtegaruList from './views/libra/LibraOtegaruList.vue';
import LibraOnPremiseList from './views/libra/LibraOnPremiseList.vue';
import LibraOnPremiseDetail from './views/libra/LibraOnPremiseDetail.vue';
import LibraOtegaruDetail from './views/libra/LibraOtegaruDetail.vue';
import LibraNewBasic from './views/libra/LibraNewBasic.vue';
import LibraNewOptions from './views/libra/LibraNewOptions.vue';
import LibraVersionUp from './views/libra/LibraVersionUp.vue';
/*
 * Spotty
 */
import SpottyList from './views/spotty/SpottyList.vue';
import SpottyDetail from './views/spotty/SpottyDetail.vue';
import SpottyNewBasic from './views/spotty/SpottyNewBasic.vue';
import SpottyNewOptions from './views/spotty/SpottyNewOptions.vue';
import SpottyVersionUp from './views/spotty/SpottyVersionUp.vue';

import Billing from './views/billing/Billing.vue';
import Statistics from './views/statistics/Statistics.vue';
import RoadMap from './views/RoadMap.vue';
import Options from './views/Options.vue';
import * as sessionStore from '@/stores/session';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
    },
    {
      path: '/login/google-signin-callback',
      name: 'googlelogincallback',
      component: GoogleLoginCallback,
    },
    {
      path: '/dashboard',
      component: HomeDashboard,
      meta: { requiresAuth: true },
    },
    {
      path: '/customer/:mode/:id',
      component: Customers,
      meta: { requiresAuth: true },
    },
    {
      path: '/customer/list',
      component: Customers,
      meta: { requiresAuth: true },
    },
    {
      path: '/raisense/list',
      component: Products,
      meta: { requiresAuth: true },
    },

    /**
     * お手軽Platon契約
     */
    {
      path: '/platon/otegaru/list',
      component: PlatonOtegaruList,
      name: 'platonotegarulist',
      meta: { requiresAuth: true },
    },
    {
      path: '/platon/otegaru/detail/:id',
      component: PlatonOtegaruDetail,
      meta: { requiresAuth: true },
    },

    /**
     * オンプレPlaton契約
     */
    {
      path: '/platon/onpremise/list',
      component: PlatonOnPremiseList,
      meta: { requiresAuth: true },
    },
    {
      path: '/platon/onpremise/detail/:id',
      component: PlatonOnPremiseDetail,
      meta: { requiresAuth: true },
    },
    /**
     * Platon契約共通
     */
    {
      path: '/platon/:division/new/basic',
      component: PlatonNewBasic,
      meta: { requiresAuth: true },
    },
    {
      path: '/platon/:division/new/options',
      component: PlatonNewOptions,
      meta: { requiresAuth: true },
    },
    {
      path: '/platon/versionup/:division/:id',
      component: PlatonVersionUp,
      meta: { requiresAuth: true },
    },

    /**
     * お手軽Libra契約
     */
    {
      path: '/libra/otegaru/list',
      component: LibraOtegaruList,
      name: 'libraotegarulist',
      meta: { requiresAuth: true },
    },
    {
      path: '/libra/otegaru/detail/:id',
      component: LibraOtegaruDetail,
      meta: { requiresAuth: true },
    },

    /**
     * オンプレLibra契約
     */
    {
      path: '/libra/onpremise/list',
      component: LibraOnPremiseList,
      meta: { requiresAuth: true },
    },
    {
      path: '/libra/onpremise/detail/:id',
      component: LibraOnPremiseDetail,
      meta: { requiresAuth: true },
    },

    /**
     * Libra契約共通
     */
    {
      path: '/libra/:division/new/basic',
      component: LibraNewBasic,
      meta: { requiresAuth: true },
    },
    {
      path: '/libra/:division/new/options',
      component: LibraNewOptions,
      meta: { requiresAuth: true },
    },
    {
      path: '/libra/versionup/:division/:id',
      component: LibraVersionUp,
      meta: { requiresAuth: true },
    },

    /**
     * Spotty契約
     */
    {
      path: '/spotty/list',
      component: SpottyList,
      name: 'spottylist',
      meta: { requiresAuth: true },
    },
    {
      path: '/spotty/detail/:id',
      component: SpottyDetail,
      meta: { requiresAuth: true },
    },
    {
      path: '/spotty/new/basic',
      component: SpottyNewBasic,
      meta: { requiresAuth: true },
    },
    {
      path: '/spotty/new/options',
      component: SpottyNewOptions,
      meta: { requiresAuth: true },
    },
    {
      path: '/spotty/versionup/:id',
      component: SpottyVersionUp,
      meta: { requiresAuth: true },
    },

    /**
     * お手軽デモ
     */
    {
      path: '/demoenv/:product/:tabTarget',
      component: DemoEnv,
      name: 'demolist',
      meta: { requiresAuth: true },
    },

    /**
     * その他
     */
    {
      path: '/billing',
      component: Billing,
      meta: { requiresAuth: true },
    },
    {
      path: '/statistics',
      component: Statistics,
      meta: { requiresAuth: true },
    },
    {
      path: '/about',
      component: RoadMap,
      meta: { requiresAuth: true },
    },
    {
      path: '/options',
      component: Options,
      meta: { requiresAuth: true },
    },
  ],

  // eslint-disable-next-line
  scrollBehavior(_, __, ___) {
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, _, next) => {
  if (
    to.matched.some((record: RouteRecord) => record.meta.requiresAuth) &&
    !sessionStore.getToken()
  ) {
    sessionStore.storeRedirectURL(to.fullPath);
    next({ path: '/', query: { redirect: to.fullPath } });
  } else {
    next();
  }
});

export default router;
