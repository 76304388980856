














































































import { Component, Vue, Emit, Prop, Watch } from 'vue-property-decorator';
import { mapActions } from 'vuex';
import Select from '@/components/molecules/Select.vue';
import TextField from '@/components/molecules/TextField.vue';
import { PlatonDivision } from '@/domain/PlatonContract/Types';
import { Plan } from '@/views/platon/PlatonNewBasic/Form';
import { closingMonths } from '@/domain/ClosingMonth';
import {
  otegaruPlans,
  onpremisePlans,
  perUseType,
  OtegaruMonthlyFlatRateYearlyUsers,
} from '@/domain/PlatonContract/Plan';

@Component({
  components: {
    Select,
    TextField,
  },
  methods: mapActions('alertArea', ['success', 'fail']),
})
export default class PlatonPlanSelector extends Vue {
  private success!: (message: string) => void;
  private fail!: (message: string) => void;

  @Prop({ type: Number, required: true })
  private division!: number;

  @Prop({ type: Object, required: true })
  private value!: Plan;

  @Emit()
  // eslint-disable-next-line
  private input(val: Plan) {
    // Parent action
  }

  private get plans() {
    if (this.division === PlatonDivision.Otegaru) {
      return otegaruPlans;
    }
    if (this.division === PlatonDivision.OnPremise) {
      return onpremisePlans;
    }
    // undefined
    return [];
  }

  private get planType() {
    if (this.value.planId === 1) {
      return perUseType;
    }
    return [];
  }
  private otegaruMonthlyFlatRateYearlyUsers!: OtegaruMonthlyFlatRateYearlyUsers;

  private get closingMonths() {
    return closingMonths;
  }
  private get planId() {
    return this.value.planId;
  }
  @Watch('planId')
  private updateForm() {
    if (this.value.planId == 19 && this.value.planData) {
      const data = JSON.parse(this.value.planData);
      this.otegaruMonthlyFlatRateYearlyUsers.yearlyUsersNumber =
        data.yearlyUsersNumber;
      this.otegaruMonthlyFlatRateYearlyUsers.closingMonth = data.closingMonth;
    }
  }
  /**
   * ライフサイクル
   */
  private created() {
    if (this.value.planData) {
      const val = JSON.parse(this.value.planData);
      this.otegaruMonthlyFlatRateYearlyUsers = new OtegaruMonthlyFlatRateYearlyUsers(
        val.yearlyUsersNumber,
        val.closingMonth || 3
      );
      this.input({ planId: this.value.planId, planData: this.value.planData });
      return;
    }
    this.otegaruMonthlyFlatRateYearlyUsers = new OtegaruMonthlyFlatRateYearlyUsers();
  }
  /**
   * 操作
   */
  private async changePlan(val: number) {
    this.input({ planId: val, planData: '' });
  }

  private async changePlanData(val: string) {
    if (this.value.planId == 19) {
      val = JSON.stringify(this.otegaruMonthlyFlatRateYearlyUsers);
    }
    this.input({ planId: this.value.planId, planData: val });
  }
}
