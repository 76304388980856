














import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

@Component
export default class PasswordField extends Vue {
  @Prop({ type: String, required: true })
  private label!: string;

  @Prop({ type: Boolean })
  private required!: boolean;

  @Prop({ type: String, required: true })
  private value!: string;

  @Prop({ type: String, required: true })
  private mode!: string;

  @Emit()
  // eslint-disable-next-line
  private input(val: string) {
    // Parent action
  }

  /**
   * ライフサイクル
   */
  private mounted() {
    if (this.mode === 'new' || this.mode === 'default') {
      this.show = true;
    }
  }

  // true:パスワードを表示する, false:隠す
  private show = false;
}
