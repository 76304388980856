export type DateAttr = {
  year: number;
  month: number;
  day: number;
};
export type DateStringFormat = 'YYYY-MM-DD' | 'YYYY/MM/DD';

export interface RangeDate {
  startDate: string;
  endDate: string | null;
}

export function dateNumberFormat(
  year: number,
  month: number,
  day: number,
  sep?: string
): string {
  if (!sep) {
    sep = '-';
  }
  const y = String(year);
  const m = '00' + month;
  const d = '00' + day;
  return [y, m.substr(-2, 2), d.substr(-2, 2)].join(sep);
}

export function dateObjFormat(date: Date, sep?: string): string {
  if (!sep) {
    sep = '-';
  }
  const y = String(date.getFullYear());
  const m = '00' + (date.getMonth() + 1);
  const d = '00' + date.getDate();
  return [y, m.substr(-2, 2), d.substr(-2, 2)].join(sep);
}

export function dateDisplay(dateString: any): string {
  if (!dateString) return '';
  if (dateString.indexOf('T') != -1) {
    const dt = dateString.split('T');
    dateString = dt[0];
  }
  const pieces = dateString.split('-');
  return pieces.join('/');
}

export function today(format: string): string {
  if (format == 'YYYY-MM-DD') {
    const today = new Date();
    return dateObjFormat(today, '-');
  }
  return '0001-01-01';
}

export function parseDateString(
  dateString: string,
  format: DateStringFormat = 'YYYY-MM-DD'
): Date | null {
  if (format === 'YYYY-MM-DD') {
    if (dateString.indexOf('-') == -1) {
      return null;
    }
    if (dateString.split('-').length < 3) {
      return null;
    }
    const pieces = dateString.split('-');
    const y = Number(pieces[0]);
    const m = Number(pieces[1]);
    const d = Number(pieces[2]);
    return new Date(y, m - 1, d);
  }
  return null;
}

export function calcDays({ startDate, endDate }: RangeDate): number {
  if (!endDate) return -1;
  const start: Date | null = parseDateString(startDate);
  const end: Date | null = parseDateString(endDate);
  if (!start || !end) return -1;
  const diff = Number(end) - Number(start);
  if (diff < 0) return -1;
  return diff / 86400000; // ミリ秒から日に直す
}
