var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pa-3"},[_c('v-card-title',[_vm._v(_vm._s(_vm.reserveTargetEnvironment.name + ' への予約'))]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","headers":_vm.reservableLendingsHeader,"items":_vm.reservableLendings,"sort-by":"startDate","sort-desc":"","single-select":"","show-select":"","item-key":"demoLendingId"},scopedSlots:_vm._u([{key:"item.lendingStatus",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"ma-2",attrs:{"small":"","color":_vm.lendingStatusSetting(item.lendingStatus).color}},[_vm._v(" "+_vm._s(_vm.lendingStatusSetting(item.lendingStatus).label)+" ")])]}},{key:"item.demoEnvId",fn:function(ref){
var item = ref.item;
return [(item.demoEnvId && item.demoEnvId != 0)?_c('div',[_vm._v(" "+_vm._s(_vm.demoEnvironment(item.demoEnvId).name)+" ")]):_c('div')]}},{key:"item.userClass",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.userClassLabel(item.userClass))+" ")]}},{key:"item.startDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.dateDisplay(item.startDate))+" ")]}},{key:"item.endDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.dateDisplay(item.endDate))+" ")]}}],null,true),model:{value:(_vm.reserveTargetLending),callback:function ($$v) {_vm.reserveTargetLending=$$v},expression:"reserveTargetLending"}}),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.cancel}},[_vm._v("キャンセル")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":"","disabled":_vm.reserveTargetLending.length == 0},on:{"click":_vm.reserve}},[_vm._v("選択データの予約")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }