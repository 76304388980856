










































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { ValidationProvider } from 'vee-validate';

@Component({
  components: {
    ValidationProvider,
  },
})
export default class DatePicker extends Vue {
  private format = /^\d{4}-\d{2}-\d{2}$/g;

  @Prop({ type: String, required: false, default: '' })
  private vid!: string;

  @Prop({ type: String, required: true })
  private label!: string;

  @Prop({ type: Boolean })
  private required!: boolean;

  @Prop({ type: String, required: true })
  private value!: string;

  @Prop({
    type: Array,
    required: false,
    default: () => {
      [];
    },
  })
  private classes!: string[];

  @Emit()
  // eslint-disable-next-line
  private input(val: string) {
    // Parent action
  }

  private get rules(): any {
    const rules: any[] = [];

    if (this.value) {
      const rule = (v: string) => {
        return (
          !!(v || '').match(this.format) ||
          `フォーマットに沿った日付を入力してください`
        );
      };
      rules.push(rule);
    }

    return rules;
  }

  private fix(val: string) {
    val = val.replace(/(\d{4})\/(\d{2})\/(\d{2})/g, '$1-$2-$3');
    val = val.replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3');
    this.input(val);
  }
}
