export class ListCriteria {
  static classPrefix = 'dm-envl-criteria';
}

export class ListCard {
  static classPrefix = 'dm-envl-envlcard';
}
export class ReservedLendingListType {
  static classPrefix = 'dm-envl-reservedlist';
}
