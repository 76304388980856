import { Account } from '@/components/libra/Account';
import { LibraOption } from '@/domain/LibraContract/LibraOption';

export interface LibraDetailForm {
  libraId: number;
  endUserName: string;
  salesAgentId: string;
  plan: Plan;
  url: string;
  siteId: string;
  startDate: string;
  billingStartDate: string;
  endDate: string;
  closingDay: number;
  salesMemo: string;
  server: Server;
  options: LibraOption[];
  accounts: Account[];
  salesAgentKeywords: string;
}

export const defaultLibraDetailForm = {
  libraId: 0,
  endUserName: '',
  salesAgentId: '0',
  plan: { planId: 0, planData: '' },
  url: '',
  siteId: '',
  startDate: '',
  billingStartDate: '',
  endDate: '',
  closingDay: 24,
  salesMemo: '',
  server: {
    infrastructure: '',
    os: '',
    ipAddress: '',
    serverDetailUrl: '',
    installedPerson: '',
    installedDate: '',
    libraInstallDirectory: '',
  },
  options: [] as LibraOption[],
  accounts: [] as Account[],
  salesAgentKeywords: '',
};

export interface Plan {
  planId: number;
  planData: string;
}

export interface Server {
  infrastructure: string;
  os: string;
  ipAddress: string;
  serverDetailUrl: string;
  installedPerson: string;
  installedDate: string;
  libraInstallDirectory: string;
}
