import { DemoLending } from './Types';

export const WAITING = 'waiting';
export const LENDING = 'lending';
export const END_OF_USE = 'endofuse';
export const ENDED = 'ended';

export const WAITING_LABEL = '待機中';
export const LENDING_LABEL = '貸出中';
export const END_OF_USE_LABEL = '貸出終了';
export const ENDED_LABEL = '初期化完了';

export type LendingStatus =
  | typeof WAITING
  | typeof LENDING
  | typeof END_OF_USE
  | typeof ENDED;

export function isLendingStatus(s: any): s is LendingStatus {
  return (
    typeof s === 'string' &&
    (s === WAITING || s === LENDING || s === END_OF_USE || s === ENDED)
  );
}

export const DEFAULT_LENDING_STATUS = {
  value: WAITING,
  label: WAITING_LABEL,
  color: 'default',
};

export const WAITING_STYLE = {
  value: WAITING,
  label: WAITING_LABEL,
  color: 'success',
};
export const LENDING_STYLE = {
  value: LENDING,
  label: LENDING_LABEL,
  color: 'primary',
};
export const END_OF_USE_STYLE = {
  value: END_OF_USE,
  label: END_OF_USE_LABEL,
  color: 'warning',
};
export const ENDED_STYLE = {
  value: ENDED,
  label: ENDED_LABEL,
  color: 'default',
};

export const LENDING_STATUS_ITEMS = [
  WAITING_STYLE,
  LENDING_STYLE,
  END_OF_USE_STYLE,
  ENDED_STYLE,
];

export function isUnderLease(lendingStatus: string): boolean {
  return lendingStatus == LENDING || lendingStatus == END_OF_USE;
}

class StatusSetting {
  constructor(public label = '', public color = '') {}
}

export const LENDING_SETTING = {
  WAITING: new StatusSetting(WAITING_LABEL, 'default'),
  LENDING: new StatusSetting(WAITING_LABEL, 'default'),
  INIT_REQUESTING: new StatusSetting(WAITING_LABEL, 'default'),
  INITIALIZED: new StatusSetting(WAITING_LABEL, 'default'),
  ENDED: new StatusSetting(WAITING_LABEL, 'default'),
};

export const INITIAL_LENDING_STATUS_ITEMS = [LENDING_STYLE, WAITING_STYLE];

export function getLendingStatusItems(lending: DemoLending): any[] {
  if (lending.lendingStatus == LENDING || lending.assigned) {
    return [LENDING_STYLE, END_OF_USE_STYLE, ENDED_STYLE];
  }
  return [WAITING_STYLE, ENDED_STYLE];
}

export function getLendingStatusSetting(lendingStatus: string): any {
  const setting = LENDING_STATUS_ITEMS.filter(item => {
    return item.value == lendingStatus;
  });
  if (setting.length != 0) return setting[0];
  return DEFAULT_LENDING_STATUS;
}

export function getNextLendingStatus(lendingStatus: string): string {
  let targetIndex = -1;
  LENDING_STATUS_ITEMS.forEach((item, index) => {
    if (item.value == lendingStatus) {
      targetIndex = index + 1;
    }
  });
  if (targetIndex == -1 || LENDING_STATUS_ITEMS.length == targetIndex)
    return '';
  return LENDING_STATUS_ITEMS[targetIndex].value;
}

export function class2rgb(classString: string, opacity: number): string {
  if (classString == 'default') {
    return 'rgb(224,224,224,' + opacity + ')';
  }
  if (classString == 'success') {
    return 'rgb(76,175,80,' + opacity + ')';
  }
  if (classString == 'primary') {
    return 'rgb(25,118,210,' + opacity + ')';
  }
  if (classString == 'info') {
    return 'rgb(33,150,243,' + opacity + ')';
  }
  if (classString == 'warning') {
    return 'rgb(251,140,0,' + opacity + ')';
  }
  return 'rgb(0, 0, 0, 1)';
}
