



















































































import { Component, Prop, Watch, Vue, Inject } from 'vue-property-decorator';
import * as types from './Types';
import EnvironmentStatusChip from '@/components/demoenv/EnvironmentStatusChip.vue';
import DemoEnvironmentCardForm from './DemoEnvironmentCardForm.vue';
import LendingCard from './LendingCard.vue';
import { DemoEnvironment, DemoLending, DemoURL } from '@/domain/DemoEnv/Types';
import { model2formLending } from '@/views/demoenv/model2form';
import * as lendstatus from '@/domain/DemoEnv/LendingStatus';
import { newUpdateDemoEnvironmentParam } from './apiHelper';
import { getProductStyle } from '@/domain/DemoEnv/Product';
import {
  DemoEnvironmentApiInterface,
  AlertInterface,
} from '@/views/demoenv/interfaces';

@Component({
  components: {
    EnvironmentStatusChip,
    DemoEnvironmentCardForm,
    LendingCard,
  },
})
export default class DemoEnvironmentCard extends Vue {
  @Prop({ type: Object, required: true })
  private environmentData!: DemoEnvironment;
  @Prop({ type: Boolean, required: true })
  private editMode!: boolean;
  private demoenv: DemoEnvironment = new DemoEnvironment();

  @Inject('alert')
  private alert!: AlertInterface;
  @Inject('demoEnvApiClient')
  private client!: DemoEnvironmentApiInterface;

  private get getProductStyle() {
    return getProductStyle;
  }
  private get environmentDataId() {
    return this.environmentData.demoEnvId;
  }
  private get lending() {
    const lending = this.demoenv.lendings.filter(l => {
      return (
        l.lendingStatus == lendstatus.LENDING ||
        l.lendingStatus == lendstatus.END_OF_USE
      );
    });
    if (lending.length == 0) return new DemoLending();
    return model2formLending(lending[0]);
  }
  private reservedLendings = [] as DemoLending[];
  private envEditMode = false;
  private lendingEditMode = false;
  private lendingLoading = false;
  private classPrefix = types.DemoEnvironmentCard.classPrefix;

  private async created() {
    this.syncPropDemoEnvironment();
  }
  @Watch('environmentDataId')
  private syncPropDemoEnvironment() {
    // 一覧から選択→データ切り替えが何度もおこなれる想定をしている(コンポーネントは使いまわし)
    // id変更をWatchして初期化を行う
    this.demoenv = JSON.parse(JSON.stringify(this.environmentData));
  }
  private update() {
    this.$emit('update', this.demoenv);
  }
  private eventLendingDeleted() {
    this.$emit('update', this.demoenv);
  }
  private editModeActivate(
    target: string,
    action: boolean,
    force: boolean
  ): void {
    if (!action && !force) {
      if (confirm('編集内容を破棄して終了します。')) {
        this.syncPropDemoEnvironment();
      } else {
        return;
      }
    }
    if (target == 'demoEnv') {
      this.envEditMode = action;
      this.$emit('modechange-edit', action);
      return;
    }
    if (target == 'demoLending') {
      this.lendingEditMode = action;
      this.$emit('modechange-edit', action).$forceUpdate();
      return;
    }
  }
  private addDemoURL() {
    this.demoenv.urls.push(new DemoURL());
  }
  private deleteDemoURL(index: number) {
    const urlLabel = this.demoenv.urls[index].label;
    const url = this.demoenv.urls[index].url;
    if (!confirm('以下のURLを削除します。\n' + urlLabel + '\n' + url)) return;
    this.demoenv.urls.splice(index, 1);
  }
  private async updateDemoEnvironment() {
    try {
      const env = newUpdateDemoEnvironmentParam(this.demoenv);
      await this.client.updateEnvironments([env]);
      this.alert.success('環境情報を更新しました。');
      this.editModeActivate('demoEnv', false, true);
      this.$emit('update', this.demoenv);
    } catch {
      this.alert.fail('環境情報の更新に失敗しました。');
    }
  }
}
