






import { Component, Vue } from 'vue-property-decorator';
import { mapActions } from 'vuex';
import LoginBox from '@/components/LoginBox.vue';
import * as accountApi from '@/api/account';
import * as sessionStore from '@/stores/session';

@Component({
  components: {
    LoginBox,
  },
  methods: {
    ...mapActions('accountInfo', ['setAccount', 'clearAccount']),
  },
})
export default class Login extends Vue {
  private clearAccount!: () => void;

  private async created() {
    // ログイン画面を開いた時点で、暗黙にログアウト扱いに
    const token = sessionStore.getToken();
    if (token === '') {
      return;
    }
    await accountApi.logout(token);
    sessionStore.deleteToken();
    this.clearAccount();
  }
}
