import { PLATON, LIBRA } from '@/domain/Product';
import { PlatonDivision } from '@/domain/PlatonContract/Types';
import { LibraDivision } from '@/domain/LibraContract/Types';

export class ProductIDs {
  constructor(
    readonly platon = PLATON,
    readonly otegaruPlaton = PlatonDivision.Otegaru,
    readonly libra = LIBRA,
    readonly otegaruLibra = LibraDivision.Otegaru
  ) {}
}
