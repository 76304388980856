import { LibraListState } from '@/stores/types.ts';

const state: LibraListState = {
  stateKeywords: '',
  stateContractStatus: 'undercontracts',
};

const getters = {};
const actions = {};
const mutations = {
  setKeywords(st: LibraListState, keywords: string) {
    st.stateKeywords = keywords;
  },
  setContractStatus(st: LibraListState, status: string) {
    st.stateContractStatus = status;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
