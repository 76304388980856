import { render, staticRenderFns } from "./GanttEnvironments.vue?vue&type=template&id=05af7b66&scoped=true&"
import script from "./GanttEnvironments.vue?vue&type=script&lang=ts&"
export * from "./GanttEnvironments.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05af7b66",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
installComponents(component, {VCard,VChip})
