function randomString(length: number): string {
  const S =
    'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789@~!+-=_|#$&';
  return Array.from(Array(length))
    .map(() => S[Math.floor(Math.random() * S.length)])
    .join('');
}

export class SpottyAccount {
  constructor(
    public spottyAccountId = 0,
    public spottyId = 0,
    public label = '本部用アカウント',
    public id = '',
    public password = '',
    public setPw = () => {
      this.password = randomString(12);
    }
  ) {}
}
// export class SpottyAccount_ {
//   spottyAccountId: number;
//   spottyId: 0;
//   public label = "本部用アカウント",
//   public id = "",
//   public password = "",
//   public setPw = () => {
//     this.password = randomString(12);
//   }
//   constructor(

//   ) {}
// }
