
import { Component, Vue } from 'vue-property-decorator';
import * as sessionStore from '@/stores/session';

@Component
export default class Home extends Vue {
  private created() {
    const token = sessionStore.getToken();
    if (token === '') {
      this.$router.push('/login');
    } else {
      this.$router.push({ name: 'platonotegarulist' });
    }
  }
}
