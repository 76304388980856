























































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import DatePicker from '@/components/molecules/DatePicker.vue';
import PasswordField from '@/components/molecules/PasswordField.vue';
import { randomString, todayString } from './itemStringsHelper';
import { OptionData, OptionItem } from './OptionData';
@Component({
  components: {
    DatePicker,
    PasswordField,
  },
})
export default class OptionCardPlane extends Vue {
  @Prop({ type: Number, required: false, default: -1 })
  private cardKey!: number;
  @Prop({ type: String, default: 'new' })
  private mode!: string;
  @Prop({ type: Number, required: true })
  private optionIndex!: number;
  @Prop({ type: Object, required: true })
  private optionData!: OptionData;
  @Prop({ type: Object, required: true })
  private mapping!: any;
  @Prop({ type: String, required: false })
  private siteId!: string;
  @Prop({ type: Function })
  private updateVM!: () => void;
  @Prop({ type: Function, required: true })
  private removeOption!: (typeId: number, idx: number) => void;
  @Prop({ type: Boolean, required: false })
  private readonly!: boolean;
  private get elProp() {
    return (item: OptionItem, propName: string) => {
      let setting = item.setting;
      if (typeof item.setting == 'string') {
        setting = JSON.parse(item.setting);
      }
      if (!setting[propName]) return false;

      // settingに初期値指定があれば上書き
      if (setting.default) item.default = 'fixed_string-' + setting.default;

      const propSet = setting[propName];
      if (typeof propSet == 'string') {
        const v = this.mapping[propSet];
        // disabledかつ指定先が真値のときは、初期値を入れてあげる
        if (
          propName == 'disabled' &&
          this.mapping[propSet] &&
          !this.mapping[item.name]
        ) {
          this.mapping[item.name] = this.setDefaultValues(item.default);
          return !v;
        }
        if (
          propName == 'disabled' &&
          !this.mapping[propSet] &&
          this.mapping[item.name]
        ) {
          this.mapping[item.name] = '';
          return !v;
        }
        if (propName == 'disabled') return !v;
        return v;
      }
      return this.mapping[propSet.name] == propSet.value;
    };
  }
  private get display() {
    return (item: OptionItem, propName: string) => {
      let setting = item.setting;
      if (typeof setting == 'string') {
        setting = JSON.parse(setting);
      }
      if (!this.modeAssert(setting.mode)) return false;
      if (!setting[propName]) return true;
      return this.elProp(item, propName);
    };
  }
  private get options() {
    return (json: any) => {
      let setting = json;
      if (typeof json == 'string') setting = JSON.parse(json);
      if (setting.options) return setting.options;
      return [];
    };
  }
  private get radios() {
    return (json: any) => {
      let setting = json;
      if (typeof json == 'string') setting = JSON.parse(json);
      if (setting.radios) return setting.radios;
      return [];
    };
  }
  private created() {
    if (
      this.modeAssert(['new', 'default']) &&
      this.optionData.template.items != null
    ) {
      this.optionData.template.items.forEach(item => {
        if (this.mapping[item.name]) return;
        // settingに初期値指定があれば上書き
        if (item.setting.default)
          item.default = 'fixed_string-' + item.setting.default;
        if (item.name == 'using') return;
        if (item.name && !this.elProp(item, 'disabled')) {
          this.mapping[item.name] = this.setDefaultValues(item.default);
        }
      });
    }
    this.updateVM();
    this.$forceUpdate();
  }
  // モードによる分岐確認
  private modeAssert(mode: string[]) {
    if (!mode) return true;
    if (mode.indexOf(this.mode) > -1) return true;
    return false;
  }
  private update(): void {
    if (this.updateVM) this.updateVM();
    this.$forceUpdate();
  }
  // 初期値生成
  private setDefaultValues(input: string): any {
    const pieces = input.split('-');
    const randomType = pieces[0];
    const prop = pieces[1];
    let text: string;
    switch (randomType) {
      case 'fixed_string':
        return pieces.slice(1).join();
      case 'random_string':
        return randomString(Number(prop), true, true, true, false);
      case 'random_symbol_string':
        return randomString(Number(prop), true, true, true, true);
      case 'lower_number_string':
        return randomString(Number(prop), false, true, true, false);
      case 'report_tool_id':
        return this.siteId + '_report';
      case 'api_id':
        return this.siteId + '_api';
      case 'today':
        text = todayString();
        return text;
      case 'true':
        return true;
      case 'false':
        return false;
      case 'dropdown':
        return pieces.slice(1).join();
      case 'empty':
        return '';
      case '':
        return '';
    }
    return '';
  }
  private get itemVid() {
    return (itemKey: number): string => {
      return this.cardKey + '_' + itemKey;
    };
  }
}
