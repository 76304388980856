export const weekday = [0, 1, 2, 3, 4, 5, 6];

export const calenderTypes = ['month', 'week', 'day', '4day'];

export function dateString(date: Date, format: string): string {
  if (format == 'yyyy-MM-dd') {
    const year = date.getFullYear();
    const month = '00' + (date.getMonth() + 1);
    const day = '00' + date.getDate();
    return year + '-' + month.slice(-2) + '-' + day.slice(-2);
  }
  if (format == 'yyyy年M月') {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    return year + '年' + month + '月';
  }
  return '';
}

export class Event {
  constructor(
    public type = '',
    public name = '',
    public start = '',
    public color = '',
    public product = '',
    public division = '',
    public id = 0,
    public contract = {}
  ) {}
}
