
















































































































































import { Component, Prop, Vue, Inject } from 'vue-property-decorator';
import DatePicker from '@/components/molecules/DatePicker.vue';
import TextField from '@/components/molecules/TextField.vue';
import { DemoEnvironment } from '@/domain/DemoEnv/Types';
import * as dateHelper from '@/domain/DemoEnv/Date';
import { ROLES_STLYES } from '@/domain/DemoEnv/Environment';
import { AlertInterface } from '@/views/demoenv/interfaces';

@Component({
  components: {
    TextField,
    DatePicker,
  },
})
export default class DemoEnvironmentCardForm extends Vue {
  @Prop({ type: Object, required: true })
  private demoenv!: DemoEnvironment;

  @Prop({ type: Boolean, required: true })
  private editMode!: boolean;

  @Inject('alert')
  private alert!: AlertInterface;

  private roleStyles = ROLES_STLYES;

  private copy2clipboard(copyText: string) {
    window.navigator.clipboard.writeText(copyText).then(() => {
      this.alert.success('クリップボードにコピーしました。');
    });
  }

  private get dateDisplaySlash(): (dateString: string) => string {
    return dateHelper.dateDisplay;
  }

  private get siteId() {
    if (this.demoenv.urls.length == 0) return '';
    const host = this.demoenv.urls[0].url.split('//');
    if (host.length < 2) return '';
    const siteId = host[1].split('.');
    if (siteId.length == 0) return '';
    return siteId[0];
  }
}
