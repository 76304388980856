
























































































































import { Component, Vue } from 'vue-property-decorator';
import { mapActions } from 'vuex';
import DatePicker from '@/components/molecules/DatePicker.vue';
import Help from '@/components/billing/Help.vue';
import Navigation from '@/components/Navigation.vue';
import dayjs from 'dayjs';

@Component({
  components: {
    DatePicker,
    Help,
    Navigation,
  },
  methods: mapActions('alertArea', ['success', 'fail']),
})
export default class Statistics extends Vue {
  /**
   * プロパティ
   */
  private dummyAggregate = [
    {
      siteID: 'siteA',
      closingDay: 24,
      endUserName: '顧客A',
      salesAgentName: '',
      count: 1000,
      planName: '従量課金制 プランA',
      beginDate: '2018-01-01',
    },
    {
      siteID: 'siteB',
      closingDay: 24,
      endUserName: '顧客B',
      salesAgentName: '代理店X',
      count: 500,
      planName: '従量課金制 プランB',
      beginDate: '2018-04-01',
    },
    {
      siteID: 'siteC',
      closingDay: 99,
      endUserName: '顧客C',
      salesAgentName: '',
      count: 100,
      planName: '月額定額制 同時接続数 100',
      beginDate: '2018-10-01',
    },
  ];

  private services = ['お手軽Platon', 'お手軽Libra', 'Spotty'];
  private serviceName = 'お手軽Platon';
  private aggregateItems = ['ユニークログイン数', '流量(課金根拠から分離予定)'];
  private aggreggateItemName = 'ユニークログイン数';

  private checkboxes = {
    platon: {
      plans: [true, true, true, true],
      closingDay: [true, true],
    },
    libra: {
      plans: [],
      closingDay: [],
    },
    spotty: {
      plans: [],
      closingDay: [],
    },
  };
  private begin: string = dayjs().format('YYYY-MM-01');
  private end: string = dayjs()
    .add(1, 'month')
    .subtract(dayjs().date(), 'day')
    .format('YYYY-MM-DD');

  private success!: (message: string) => void;
  private fail!: (message: string) => void;

  /**
   * 操作
   */
}
