export class UpcomingTable {
  header: any[];
  data: ContractData[];
  constructor(header: any[], data: ContractData[]) {
    this.header = header;
    this.data = data;
  }
}

export class ContractData {
  constructor(
    public product = '',
    public division = '',
    public id = 0,
    public contract = {}
  ) {}
}
export const ContractTableHeader = [
  {
    text: 'PlatonID',
    value: 'platonId',
  },
  {
    text: 'ユーザー名',
    value: 'endUserName',
  },
  { text: 'URL', value: 'url' },
  { text: '開始', value: 'startDate' },
  { text: '終了', value: 'endDate' },
];

export const UpcomingTableHeader = [
  {
    text: '製品',
    value: 'product',
  },
  {
    text: '顧客名',
    value: 'contract.endUserName',
  },
  {
    text: '開始',
    value: 'contract.startDate',
  },
];
