import { DemoEnvironment } from '@/domain/DemoEnv';
import {
  GanttChartSetting,
  GanttEventMenu,
} from './components/GanttChart/Types';

function initSetting(func: () => {}): any {
  return func();
}

export const defaultGannttChartSetting: GanttChartSetting = initSetting(() => {
  const set = new GanttChartSetting();

  set.dayWidth = 29;
  set.baseRowHeight = 40;

  const today = new Date();
  set.today.year = today.getFullYear();
  set.today.month = today.getMonth() + 1;
  set.today.day = today.getDate();
  set.start.year = set.today.year;
  set.start.month = set.today.month;
  set.start.day = set.today.day;
  today.setMonth(today.getMonth() + 2);
  set.end.year = today.getFullYear();
  set.end.month = today.getMonth() + 1;
  set.end.day = today.getDate();

  set.displayLending = 'assigned';
  set.displayRoles = ['demo-basic']; // 個別
  return set;
});

export const defaultGanttEventMenuSetting: GanttEventMenu = initSetting(() => {
  const set = new GanttEventMenu();
  set.show = false;
  set.style = {
    position: 'fixed',
    top: '10px',
    left: '10px',
    width: '320px',
    'z-index': 20,
  };
  set.items = [
    { value: 'new', label: 'ここに貸出情報を作成して予約' },
    { value: 'reservation', label: 'この環境に貸出情報を移す' },
  ];
  set.selectedEnv = new DemoEnvironment();
  return set;
});
// {
//   show: false,
//   style: {
//     position: 'absolute',
//     top: '10px',
//     left: '10px',
//     width: '320px',
//     'z-index': 20,
//   },
//   items: [
//     { value: 'new', label: 'ここに貸出情報を作成して予約' },
//     { value: 'reservation', label: 'この環境に貸出情報を移す' },
//   ],
//   selectedEnv: new DemoEnvironment(),
//   selectedDate: { year: 0, month: 1, day: 1 },
// // };

// const today = new Date();
// this.gantt.today.year = today.getFullYear();
// this.gantt.today.month = today.getMonth() + 1;
// this.gantt.today.day = today.getDate();
// this.gantt.start.year = this.gantt.today.year;
// this.gantt.start.month = this.gantt.today.month;
// this.gantt.start.day = this.gantt.today.day;
// today.setMonth(today.getMonth() + 2);
// this.gantt.end.year = today.getFullYear();
// this.gantt.end.month = today.getMonth() + 1;
// this.gantt.end.day = today.getDate();
