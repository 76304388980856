import { ActionContext } from 'vuex';
import { DemoLendingReservationState } from '@/stores/types';
import * as api from '@/api/demoenv';
const state: DemoLendingReservationState = {
  demoEnvId: 0,
  demoLendingId: 0,
  dialog: false,
};

const getters = {
  dialog(st: DemoLendingReservationState): boolean {
    return st.dialog;
  },
};

const actions = {
  setDemoLendingId(
    context: ActionContext<DemoLendingReservationState, {}>,
    newId: number
  ) {
    context.commit('setDemoLendingId', newId);
  },
  setTargetEnv(
    context: ActionContext<DemoLendingReservationState, {}>,
    newId: number
  ) {
    context.commit('setTargetEnvId', newId);
  },
  setStartDate(
    context: ActionContext<DemoLendingReservationState, {}>,
    date: any
  ) {
    context.commit('setStartDate', date);
  },
  dialogStart(context: ActionContext<DemoLendingReservationState, {}>) {
    context.commit('reset');
    context.commit('switchDialog', true);
  },
  dialogEnd(context: ActionContext<DemoLendingReservationState, {}>) {
    context.commit('switchDialog', false);
  },
  switchDialog(
    context: ActionContext<DemoLendingReservationState, {}>,
    value: boolean
  ) {
    context.commit('switchDialog', value);
  },
  async submit(
    context: ActionContext<DemoLendingReservationState, {}>,
    token: string
  ): Promise<void> {
    await api.assignLendingEnvironment(
      token,
      context.state.demoEnvId,
      context.state.demoLendingId,
      false
    );
    return;
  },
};

const mutations = {
  setDemoLendingId(st: DemoLendingReservationState, newId: number) {
    st.demoLendingId = newId;
  },
  setTargetEnvId(st: DemoLendingReservationState, newId: number) {
    st.demoEnvId = newId;
  },
  switchDialog(st: DemoLendingReservationState, value: boolean) {
    st.dialog = value;
  },
  reset(st: DemoLendingReservationState) {
    st.demoEnvId = 0;
    st.demoLendingId = 0;
    st.dialog = false;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
