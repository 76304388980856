



























import { Component, Vue } from 'vue-property-decorator';
import { mapState, mapMutations, mapActions } from 'vuex';
import ActionMenu from '@/components/molecules/ActionMenu.vue';
import KeywordSearch from '@/components/molecules/KeywordSearch.vue';
import Navigation from '@/components/Navigation.vue';
import PlatonListTable from '@/components/platon/PlatonListTable.vue';
import * as api from '@/api/platon.ts';
import { PlatonDivision } from '@/domain/PlatonContract/Types';
import * as sessionStore from '@/stores/session';
import { PlatonContract } from '../../domain/PlatonContract/PlatonContract';

@Component({
  components: {
    ActionMenu,
    KeywordSearch,
    Navigation,
    PlatonListTable,
  },
  computed: {
    ...mapState('platonOnPremiseList', [
      'stateKeywords',
      'stateContractStatus',
    ]),
  },
  methods: {
    ...mapMutations('platonOnPremiseList', [
      'setKeywords',
      'setContractStatus',
    ]),
    ...mapActions('alertArea', ['success', 'fail']),
  },
})
export default class PlatonList extends Vue {
  private items: PlatonContract[] = [];
  private intervalId = -1;

  private searchTargetLabel = [
    '顧客名',
    '代理店名',
    'プラン',
    'URL',
    'オプション',
    'サーバー設定',
    '備考',
  ];

  private actionMenu = [
    {
      icon: 'add',
      text: '契約を追加する',
      color: 'primary',
      click: () => {
        this.openNew();
      },
    },
    {
      icon: 'cloud_download',
      text: 'CSVでダウンロードする',
      color: 'secondary',
      click: () => {
        this.downloadList();
      },
    },
  ];

  private stateKeywords!: string;
  private setKeywords!: (keywords: string) => void;
  private get keywords(): string {
    return this.stateKeywords;
  }
  private set keywords(value: string) {
    this.setKeywords(value);
  }

  private stateContractStatus!: string;
  private setContractStatus!: (status: string) => void;
  private get contractStatus(): string {
    return this.stateContractStatus;
  }
  private set contractStatus(value: string) {
    this.setContractStatus(value);
  }

  private success!: (message: string) => void;
  private fail!: (message: string) => void;

  private get PlatonDivision() {
    return PlatonDivision;
  }

  private async created() {
    await this.search();
  }

  private mounted() {
    let prev = '';
    this.intervalId = setInterval(async () => {
      if (this.keywords !== prev) {
        prev = this.keywords;
        await this.search();
      }
    }, 1000);
  }

  private beforeDestroy() {
    clearInterval(this.intervalId);
  }

  private async search() {
    try {
      this.items = await api.listOnPremise(
        sessionStore.getToken(),
        0,
        0,
        this.keywords,
        this.contractStatus
      );
    } catch {
      this.fail('オンプレPlaton一覧の取得に失敗しました');
    }
  }

  private openNew() {
    this.$router.push('/platon/onpremise/new/basic');
  }

  private async downloadList() {
    try {
      await api.donwnloadOnPremise(
        sessionStore.getToken(),
        0,
        0,
        this.keywords,
        this.contractStatus
      );
    } catch {
      this.fail('オンプレPlaton一覧のダウンロードに失敗しました');
    }
  }
}
