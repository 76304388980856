














































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { ValidationProvider } from 'vee-validate';

@Component({
  components: {
    ValidationProvider,
  },
})
export default class CustomerSearchSelector extends Vue {
  /**
   * プロパティ
   */
  private intervalId = -1;

  /**
   * 親から受け取る
   */
  @Prop({ type: String, required: true })
  private value!: string;

  @Prop({ type: String, required: true })
  private initKeyword!: string;

  @Prop({ type: String, required: true })
  private label!: string;

  @Prop({ type: String, required: true })
  private placeHolder!: string;

  @Prop({ type: String, required: true })
  private addButtonLabel!: string;

  @Prop({ type: Array, required: true })
  private items!: string;

  @Prop({
    type: Array,
    required: false,
    default: () => {
      [];
    },
  })
  private classes!: string[];

  @Prop({ type: Boolean })
  private required!: boolean;

  @Emit()
  // eslint-disable-next-line
  private input(val: string) {
    // Parent action
  }
  @Emit()
  // eslint-disable-next-line
  private keywordUpdate(val: string) {
    // Parent action
  }
  @Emit()
  // eslint-disable-next-line
  private search(keywords: string) {
    // Parent action
  }
  @Emit()
  // eslint-disable-next-line
  private add(keywords: string) {
    // Parent action
    // 検索キーワードのデータを簡易追加
    // 本来は検索前にデータがあるように業務フローが構築されているべきだが、
    // 2019年秋、現在そうなっていないのでこの契約の追加時に顧客登録を可能にしている。
  }

  /**
   * ライフサイクル
   */
  private mounted() {
    let prev = '';
    this.intervalId = setInterval(async () => {
      if (this.initKeyword !== prev) {
        prev = this.initKeyword;
        await this.search(this.initKeyword);
      }
    }, 1000);
  }
  private created() {
    // vee-validate
    // extend('required', required);
  }

  private beforeDestroy() {
    clearInterval(this.intervalId);
  }
}
