




























































































import { Component, Prop, Vue, Provide, Inject } from 'vue-property-decorator';
import { ValidationObserver } from 'vee-validate';
import * as types from './Types';
import Form from './Form.vue';
import Confirmation from './Confirmation.vue';
import { DemoEnvironment, DemoLending } from '@/domain/DemoEnv/Types';
import { NewDemoLendingInterface } from '@/views/demoenv/interfaces';
import { NewDemoLendingOptionAdaptor } from './NewDemoLendingAdaptor';
import {
  DemoEnvironmentApiInterface,
  NewDemoLendingOptionInterface,
  OptionsApiInterface,
} from '@/views/demoenv/interfaces';

@Component({
  components: {
    ValidationObserver,
    Form,
    Confirmation,
  },
})
export default class NewDemoLending extends Vue {
  @Prop({ type: Object, required: true, default: {} })
  private demoLending!: DemoLending;
  @Prop({ type: Array, required: true, default: [] })
  readonly demoenvs!: DemoEnvironment[];
  @Prop({ type: Array, required: true, default: [] })
  private demoLendings!: DemoLending[];
  @Prop({ type: Object, required: true, default: {} })
  private props!: NewDemoLendingInterface;
  @Prop({ type: Boolean, required: true })
  private dialog!: boolean;
  @Prop({ type: Number, required: true })
  readonly product!: number;

  @Inject()
  demoEnvApiClient!: DemoEnvironmentApiInterface;
  @Inject('optionsApiClient')
  optionsApiClient!: OptionsApiInterface;

  private option!: NewDemoLendingOptionInterface;
  private processing = true;
  private classPrefix = types.NewDemoLending.classPrefix;

  @Provide()
  get reservationTargetEnvironment(): DemoEnvironment {
    if (this.demoLending.demoEnvId != 0) {
      const target = this.demoenvs.filter(env => {
        return env.demoEnvId == this.demoLending.demoEnvId;
      });
      if (target.length != 0) return target[0];
    }
    return new DemoEnvironment();
  }

  private submit(validate: any) {
    validate().then(async (result: any) => {
      if (result) {
        try {
          await this.props.submit(this.demoEnvApiClient);
          this.$emit('success', '新規貸出情報の追加が完了しました。');
          this.$emit('submit', true);
        } catch {
          this.$emit('fail', '表示中の貸出データの更新に失敗しました。');
        }
        this.props.dialogEnd();
      } else {
        this.props.paginator.init();
      }
    });
  }

  private async created() {
    const option = await new NewDemoLendingOptionAdaptor().init(
      this.product,
      this.optionsApiClient
    );
    this.option = option;

    this.props.paginator.init();

    this.processing = false;
  }
}
