


































import { Component, Vue, Emit, Prop } from 'vue-property-decorator';
import { mapActions } from 'vuex';
import { ValidationObserver } from 'vee-validate';
import TextField from '@/components/molecules/TextField.vue';
import * as customerApi from '@/api/customer';
import { Customer } from '@/domain/Customer';
import * as sessionStore from '@/stores/session';

Component.registerHooks(['beforeRouteUpdate']);

@Component({
  components: {
    ValidationObserver,
    TextField,
  },
  methods: mapActions('alertArea', ['success', 'fail']),
})
export default class CustomerEditArea extends Vue {
  @Prop({ type: Customer, required: true })
  private editAreaProp!: Customer;

  private success!: (message: string) => void;
  private fail!: (message: string) => void;

  /**
   * ライフサイクル
   */

  /**
   * 操作
   */
  private async submit(validate: any) {
    validate().then(async (result: any) => {
      if (!result.isValid) {
        return;
      }
      try {
        await customerApi.update(sessionStore.getToken(), this.editAreaProp);
        this.success('顧客情報を更新しました');
        this.parentload();
      } catch {
        this.fail('顧客情報の更新に失敗しました');
      }
    });
  }

  private async remove(customerId: number) {
    if (!window.confirm(this.editAreaProp.customerName + 'を削除しますか?')) {
      return;
    }

    try {
      await customerApi.remove(sessionStore.getToken(), customerId);
      this.success('顧客情報を削除しました');
      this.parentload();
      this.close();
    } catch {
      this.fail('顧客情報の削除に失敗しました');
      return;
    }
  }

  private async close() {
    await this.$router.push('../list');
    this.display(false);
  }

  @Emit()
  private parentload() {
    // Parent action
  }
  @Emit()
  // eslint-disable-next-line
  private display(display: boolean) {
    // Parent action
  }
}
