export class Plan {
  constructor(public planId = 1, public productId = 1, public planName = '') {}
}

export const perUseType = ['タイプA', 'タイプB', 'タイプC'];

export const setType = ['スタンダード', '容量増大'];

export const plans = [
  { value: 14, label: '従量課金制' },
  { value: 15, label: '月額定額制' },
  { value: 16, label: '特別プラン' },
];
