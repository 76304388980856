




























































































import { Component, Prop, Vue, Inject } from 'vue-property-decorator';
import * as types from './Types';
import LendingStatusSelector from './LendingStatusSelector.vue';
import * as dateHelper from '@/domain/DemoEnv/Date';
import { DemoEnvironment, DemoLending } from '@/domain/DemoEnv';
import { NewDemoLendingOptionInterface } from '@/views/demoenv/interfaces';
import { isTargetEnvironmentOnLoan } from '../../statusHelper';
import * as statusHelper from '../../statusHelper';

@Component({
  components: {
    LendingStatusSelector,
  },
})
export default class Confirmation extends Vue {
  @Prop({ type: Object, required: true })
  private option!: NewDemoLendingOptionInterface;
  @Prop({ type: Object, required: true })
  private demoLending!: DemoLending;
  @Prop({ type: Array, required: true })
  private demoLendings!: DemoLending[];
  @Prop({ type: Boolean, required: true, default: false })
  private flag!: boolean;

  @Inject()
  readonly reservationTargetEnvironment!: DemoEnvironment;

  private classPrefix = types.Confirmation.classPrefix;

  private get dateDisplay() {
    return dateHelper.dateDisplay;
  }
  private get isTargetEnvrionmentOnPendingVerification() {
    return statusHelper.isPendingVerification(
      this.reservationTargetEnvironment.environmentStatus
    );
  }
  private get isTargetEnvioronmentOnLoan() {
    return isTargetEnvironmentOnLoan(
      this.reservationTargetEnvironment.demoEnvId,
      this.demoLendings
    );
  }

  private get isOptionSelected() {
    return this.selectedOptions.length != 0;
  }
  private selectedOptions: number[] = [];

  private get optionTypeName() {
    return (id: number) => {
      const opts = this.option.templates.filter(opt => opt.typeId == id);
      if (!opts.length) return '';
      return opts[0].optionTypeName;
    };
  }
  private created() {
    this.selectedOptions.length = 0;
    this.option.optionDatas.forEach(opt => {
      if (typeof opt.data !== 'string' && opt.data.using) {
        this.selectedOptions.push(opt.optionsTypeId);
      }
    });
  }
}
