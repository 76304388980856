



























































































































































































































































































































































































































































































import { Component, Watch, Prop, Vue } from 'vue-property-decorator';
import OptionCardPlane from '@/components/options/OptionCardPlane.vue';
import { mapActions } from 'vuex';
import { OptionData, OptionItem, OptionTemplate } from './OptionData';
import * as helper from './OptionsTypesHelper';
import * as optionsApi from '@/api/options';
import * as sessionStore from '@/stores/session';
import * as lodash from 'lodash';
@Component({
  components: {
    OptionCardPlane,
  },
  methods: {
    ...mapActions('alertArea', ['success', 'fail']),
  },
})
export default class OptionList extends Vue {
  @Prop({ type: String, required: true })
  private productName!: string;
  @Prop({ type: String, required: true })
  private version!: string;
  @Prop({ type: Array, required: true })
  private options!: OptionData[];
  @Prop({ type: Array, required: true })
  private storedOptions!: OptionData[];
  @Prop({ type: Object, required: true })
  private status!: any;
  private preProductName = '';
  private preVersion = '';
  private allOptionsTypes: OptionData[] = [];
  private optionsJSON!: string[][];
  private optionJSONStatus!: boolean[][];
  private sampleModel: any[] = [];
  private idx!: number;
  private viewFormatToggle: any = 0;
  private target!: { [key: string]: string };
  private valueTypeList!: { [key: string]: string }[];
  private defaultTypeList!: { [key: string]: any[] };
  private viewMode!: string;
  private resetFlags: boolean[] = [];
  private addOptionDialogFlag = false;
  private importOptionDialogFlag = false;
  private importTargetOptions: OptionData[] = [];
  private newOptionData!: OptionData;
  private optionsTypesHeader: any[] = [];
  private singleSelect = false;
  private selectedOptions: OptionData[] = [];
  private editOptionDialogFlag = false;
  private editOptionIndex = -1;
  private editOPtionData: any = {};
  private tableLoading = false;
  private processing = false;
  private success!: (message: string) => void;
  private fail!: (message: string) => void;
  private get defaultOptionItems() {
    return (valueType: string, json: any) => {
      if (
        valueType === 'text' ||
        valueType === 'password' ||
        valueType === 'textarea'
      )
        return this.defaultTypeList.textinput;
      if (valueType === 'checkbox') return this.defaultTypeList.checkbox;
      if (valueType === 'date') return this.defaultTypeList.date;
      if (valueType === 'dropdown') return this.dropdownOptions(json);
      return [{ value: 'empty', label: 'なし' }];
    };
  }
  private get dropdownOptions() {
    return (setting: any) => {
      try {
        if (typeof setting == 'string') setting = JSON.parse(setting);
        if (setting.options) {
          let options = setting.options as { [key: string]: string }[];
          options = options.map(opt => {
            const opt_ = Object.assign({}, opt);
            const prefix = 'dropdown-';
            if (!opt_.value.startsWith(prefix))
              opt_.value = 'dropdown-' + opt_.value;
            return opt_;
          });
          options.unshift({ value: '', label: 'なし' });
          return options;
        }
      } catch {
        return [];
      }
      return [];
    };
  }
  private created() {
    this.viewMode = 'new';
    this.storedOptions = [];
    this.optionsJSON = [];
    this.optionJSONStatus = [];
    this.valueTypeList = [
      { value: 'text', label: 'テキスト' },
      { value: 'password', label: 'パスワード' },
      { value: 'number', label: '数値' },
      { value: 'textarea', label: 'テキストエリア' },
      { value: 'checkbox', label: 'チェックボックス' },
      { value: 'date', label: '日付カレンダー' },
      { value: 'startDate', label: '開始日' },
      { value: 'endDate', label: '終了日' },
      { value: 'dropdown', label: 'ドロップダウン' },
      { value: 'multiradio', label: 'ラジオボタン' },
    ];
    this.defaultTypeList = {
      textinput: [
        { value: 'empty', label: 'なし' },
        { value: 'random_string-12', label: '大小英数字(12)' },
        { value: 'random_string-20', label: '大小英数字(20)' },
        { value: 'random_symbol_string-20', label: '大小英数字 記号(12)' },
        { value: 'lower_number_string-12', label: '小英数字(12)' },
        { value: 'report_tool_id', label: 'レポートツールID' },
      ],
      checkbox: [
        { value: 'true', label: '真値' },
        { value: 'false', label: '偽値' },
      ],
      date: [
        { value: 'empty', label: 'なし' },
        { value: 'today', label: '当日日付(YYYY-MM-DD)' },
      ],
    };
    this.optionsTypesHeader = [
      {
        text: 'ID',
        align: 'start',
        value: 'typeId',
        groupable: false,
      },
      {
        text: 'オプション名称',
        align: 'start',
        value: 'optionTypeName',
        groupable: false,
      },
      {
        text: 'バージョン',
        align: 'start',
        sortable: false,
        value: 'version',
        groupable: false,
      },
      { text: '', value: 'actions', sortable: false },
      { text: '', value: 'status', sortable: false },
    ];
    this.newOptionData = this.defaultOptionData();
  }
  private get optionsList() {
    this.sortOptions();
    this.model2form(this.options);
    this.validateOptions(this.options);

    // 製品・バージョンが変わったときの処理
    if (
      this.storedOptions.length == 0 ||
      this.productName != this.preProductName ||
      this.version != this.preVersion ||
      this.processing
    )
      this.storeOptions(this.options);
    this.preProductName = this.productName;
    this.preVersion = this.version;

    this.isOptionsChanged(this.options);
    this.updateSampleModel();
    return this.options;
  }
  private editOptionDialog(option: OptionData) {
    this.editOptionDialogFlag = true;
    this.editOPtionData = Object.assign({}, option);
    this.editOptionIndex = option.template.rowNumber - 1;
  }
  private updateOption(option: OptionData) {
    this.options.forEach(opt => {
      if (opt.template.rowNumber == option.template.rowNumber) {
        opt.typeId = option.typeId;
        opt.optionTypeName = option.optionTypeName;
      }
    });
    this.editOptionDialogFlag = false;
  }
  @Watch('addOptionDialogFlag')
  private addOptionDialog() {
    this.newOptionData.typeId = helper.maxOptionTypeID(this.options) + 1;
  }
  @Watch('importOptionDialogFlag')
  private async importOptionDialog() {
    // インポートリストの取得
    this.allOptionsTypes = await optionsApi.listOteagruPlatonOptionsTypes(
      sessionStore.getToken(),
      ''
    );
    this.allOptionsTypes = this.allOptionsTypes
      .filter(opt => {
        return opt.version != this.version;
      })
      .map((opt, idx) => {
        const o = JSON.parse(JSON.stringify(opt));
        o.index = idx;
        return o;
      });
  }
  private importOptions() {
    this.importTargetOptions.map(opt => {
      opt.version = this.version;
      this.options.push(opt);
    });
    this.importOptionDialogFlag = false;
    this.reloadOptionsTable();
    this.success(
      'インポートが成功しました(' + this.importTargetOptions.length + '件)。'
    );
    this.importTargetOptions.length = 0;
  }
  private addNewOption() {
    const newOptionData = this.newOptionData;
    newOptionData.version = this.version;
    if (!this.ValidateNewOption(newOptionData)) {
      alert('入力内容に不備があります。');
      return;
    }
    this.options.push(newOptionData);
    this.newOptionData = this.defaultOptionData();
    this.addOptionDialogFlag = false;
    this.updateSampleModel();
    this.reloadOptionsTable();
    this.success(
      'オプションを追加しました。ID: ' +
        newOptionData.typeId +
        ' ' +
        newOptionData.optionTypeName
    );
  }
  private defaultOptionData() {
    return new OptionData(0, '', '', new OptionTemplate('v1', '', []));
  }
  private ValidateNewOption(option: OptionData): boolean {
    if (this.checkDuplicateTypeId(option.typeId, -1) != true) return false;
    if (this.checkOptionName(option.optionTypeName) != true) return false;
    return true;
  }
  private validateOption(option: OptionData): boolean {
    if (
      this.checkDuplicateTypeId(option.typeId, option.template.rowNumber - 1) !=
      true
    )
      return false;
    if (this.checkOptionName(option.optionTypeName) != true) return false;
    return true;
  }
  private validateOptions(options: OptionData[]) {
    options.map((option, idx) => {
      try {
        option.valid.length = 0;
      } catch {
        option.valid = [];
      }
      const idDuplicate = this.checkDuplicateTypeId(option.typeId, idx);
      if (idDuplicate != true) option.valid.push(idDuplicate);
      const optionNameRequired = this.checkOptionName(option.optionTypeName);
      if (optionNameRequired != true) option.valid.push(optionNameRequired);
    });
  }
  private checkOptionName(optionName: string) {
    if (!optionName) return 'オプション名称を入力してください';
    return true;
  }
  private checkDuplicateTypeId(id: number, exceptIdx: number) {
    return (
      helper.checkDuplicateTypeId(id, this.options, exceptIdx) ||
      'ID重複(' + id + ')'
    );
  }
  private changeSettingJSON(idx: number, itemIdx: number) {
    try {
      this.options[idx].template.items[itemIdx] = JSON.parse(
        this.optionsJSON[idx][itemIdx]
      );
    } catch {
      this.optionJSONStatus[idx][itemIdx] = true;
      this.$forceUpdate();
      return;
    }
    this.optionJSONStatus[idx][itemIdx] = false;
    this.$forceUpdate();
  }
  private isOptionsChanged(options: OptionData[]) {
    this.status.isChanged = false;
    options.forEach((opt, idx) => {
      let isChanged = -1;
      try {
        const sopt = this.storedOptions[idx];
        if (opt.typeId != sopt.typeId) isChanged = 1;
        if (opt.optionTypeName != sopt.optionTypeName) isChanged = 2;
        if (opt.version != sopt.version) isChanged = 3;
        // TODO: 項目すべての変更確認
        // if (JSON.stringify(opt.template.items) != JSON.stringify(sopt.template.items)) isChanged = 4;
      } catch {
        isChanged = 99;
      } finally {
        if (isChanged > 0) this.status.isChanged = true;
      }
    });
  }
  private reloadOptionsTable() {
    this.viewFormatToggle = -1;
    this.tableLoading = true;
    setTimeout(() => {
      this.viewFormatToggle = 0;
      this.tableLoading = false;
    }, 1000);
  }
  private deleteOptions(targets: OptionData[]) {
    if (targets.length == 0) {
      alert('削除対象が選択されていません。');
      return;
    }
    if (!confirm('削除しますがよろしいですか?')) return;
    for (let i = 0; i < targets.length; i++) {
      const idx = this.options
        .map(opt => {
          return opt.typeId;
        })
        .indexOf(targets[i].typeId);
      if (idx == -1) continue;
      this.options.splice(idx, 1);
    }
    targets.length = 0;
    this.viewFormatToggle = -1;
    this.reloadOptionsTable();
  }
  private addItem(opt: OptionData, idx: number) {
    if (opt.template.items == null) opt.template.items = [];
    opt.template.items.push(
      new OptionItem('', '', 'text', 'empty', false, false, 12, {})
    );
    this.renderingOptionCard(idx);
  }
  private deleteItem(items: OptionItem[], itemIdx: number, idx: number) {
    if (window.confirm('オプション入力項目を削除しますか？')) {
      items.splice(itemIdx, 1);
      this.renderingOptionCard(idx);
    }
  }
  private swapOptionsRowNumber(
    options: OptionData[],
    idx1: number,
    idx2: number
  ) {
    const row1 = options[idx1].template.rowNumber;
    options[idx1].template.rowNumber = options[idx2].template.rowNumber;
    options[idx2].template.rowNumber = row1;
  }
  private optionMoveBefore(options: OptionData[], optionIdx: number) {
    if (optionIdx == 0) return;
    const deleteList = options.splice(Number(optionIdx), 1);
    options.splice(optionIdx - 1, 0, deleteList[0]);
    this.swapOptionsRowNumber(options, optionIdx, optionIdx - 1);
  }
  private optionMoveForward(options: OptionData[], optionIdx: number) {
    if (options.length == optionIdx + 1) return;
    const deleteList = options.splice(Number(optionIdx), 1);
    options.splice(optionIdx + 1, 0, deleteList[0]);
    this.swapOptionsRowNumber(options, optionIdx, optionIdx + 1);
  }
  private itemMoveBefore(items: OptionItem[], itemIdx: number) {
    if (itemIdx == 0) return;
    const deleteList = items.splice(Number(itemIdx), 1);
    items.splice(itemIdx - 1, 0, deleteList[0]);
  }
  private itemMoveForward(items: OptionItem[], itemIdx: number) {
    if (items.length == itemIdx + 1) return;
    const deleteList = items.splice(Number(itemIdx), 1);
    items.splice(itemIdx + 1, 0, deleteList[0]);
  }
  private editMode(opt: OptionData): void {
    opt.edit = !opt.edit;
    this.$forceUpdate();
  }
  private setRowNumber() {
    let idx = 1;
    this.options.forEach(opt => {
      opt.template.rowNumber = idx;
      idx++;
    });
  }
  private sortOptions() {
    let i = 0;
    let j = 1;
    let preRowNum = 0;
    let nowRowNum = 0;
    for (i = 0; i < this.options.length; i++) {
      for (j = this.options.length - 1; i < j; j--) {
        preRowNum = this.options[j - 1].template.rowNumber;
        nowRowNum = this.options[j].template.rowNumber;
        if (preRowNum > nowRowNum) {
          const deleteList = this.options.splice(j, 1);
          this.options.splice(j - 1, 0, deleteList[0]);
        }
      }
    }
  }
  // 編集前の値を保持しておく
  private storeOptions(options: OptionData[]) {
    this.storedOptions.length = 0;
    options.forEach(opt => {
      this.storedOptions.push(lodash.cloneDeep(opt));
    });
  }
  private changeModeEvent() {
    if (this.viewMode == 'new') {
      this.sampleModel.forEach(m => {
        if (m.data) m.data.using = false;
      });
    }
    if (this.viewMode == 'edit') {
      this.sampleModel.forEach(m => {
        if (m.data) m.data.using = true;
      });
    }
    this.$forceUpdate();
  }
  private renderingOptionCard(idx: number): void {
    const optionCard = this.$refs['optionCards_' + idx] as Vue[];
    if (!optionCard) return;
  }

  // eslint-disable-next-line
  private removeOption(optNum: number, idx: number) {
    return; // do nothing
  }

  private updateSampleModel() {
    this.sampleModel.length = 0;
    if (this.options == null) return;
    this.options.map(opt => {
      const optData = {} as { [key: string]: string };
      if (opt.template.items != null) {
        opt.template.items.forEach(item => {
          optData[item.name] = '';
        });
        this.sampleModel.push({
          data: optData,
        });
        return;
      } else {
        this.sampleModel.push({
          data: {},
        });
        return;
      }
    });
  }
  private get modifyJsonFormat() {
    // 設定JSONに不備があるときに、再度元データからJSONを生成する。
    // 不正なJSONのままパースされないようにする役割。
    return (json: string, idx: number, itemIdx: number) => {
      try {
        JSON.parse(json);
      } catch {
        this.optionsJSON[idx][itemIdx] = JSON.stringify(
          this.options[idx].template.items[itemIdx],
          null,
          2
        );
        return;
      }
    };
  }
  private form2model() {
    return this.options;
  }
  private model2form(models: OptionData[]) {
    models = models.slice();
    this.options.length = 0;
    this.optionsJSON.length = 0;
    models.forEach(opt => {
      if (!opt.valid) opt.valid = [];
      if (!opt.template.items) opt.template.items = [];
      this.optionsJSON.push(
        opt.template.items.map(item => {
          return JSON.stringify(item, null, 2);
        })
      );
      this.optionJSONStatus.push(
        opt.template.items.map(item => {
          try {
            JSON.stringify(item, null, 2);
            return false;
          } catch {
            return true;
          }
        })
      );
      this.options.push(opt);
    });
  }
  public reset() {
    if (!confirm('編集内容を破棄して元に戻しますか？')) return;
    this.options.length = 0;
    this.storedOptions.forEach(opt => {
      this.options.push(lodash.cloneDeep(opt));
    });
    this.storeOptions(this.options);
    this.reloadOptionsTable();
  }
  public async update() {
    // データ内容の確認
    this.validateOptions(this.options);
    const validStatus = this.options.filter(opt => {
      return opt.valid.length != 0;
    });
    if (validStatus.length > 0) {
      window.confirm('入力に不備があります。確認してください。');
      return;
    }

    if (!window.confirm('現在の内容でオプションマスタを更新します。')) return;

    this.setRowNumber();
    this.processing = true;
    const model = this.form2model();
    try {
      let updatedOptions: OptionData[] = [];
      if (this.productName == 'onpremise_platon') {
        updatedOptions = await optionsApi.updateOnPremisePlatonOptionsTypes(
          sessionStore.getToken(),
          model
        );
      }
      if (this.productName == 'otegaru_platon') {
        updatedOptions = await optionsApi.updateOteagruPlatonOptionsTypes(
          sessionStore.getToken(),
          model
        );
      }
      if (this.productName == 'onpremise_libra') {
        updatedOptions = await optionsApi.updateOnPremiseLibraptionsTypes(
          sessionStore.getToken(),
          model
        );
      }
      if (this.productName == 'otegaru_libra') {
        updatedOptions = await optionsApi.updateOteagruLibraOptionsTypes(
          sessionStore.getToken(),
          model
        );
      }
      if (this.productName == 'spotty') {
        updatedOptions = await optionsApi.updateSpottyOptionsTypes(
          sessionStore.getToken(),
          model
        );
      }
      this.options.length = 0;
      updatedOptions.map(opt => {
        this.options.push(opt);
      });
    } catch (e) {
      this.fail('更新に失敗しました。');
      return;
    } finally {
      this.processing = false;
    }
    this.success('更新しました。');
  }
}
