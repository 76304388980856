import { ActionContext } from 'vuex';
import { DemoManagementBoardState } from '@/stores/types';
import { PRODUCTS_STYLES } from '@/domain/DemoEnv/Product';
import * as api from '@/api/demoenv';
import { DemoLending, DemoEnvironment } from '@/domain/DemoEnv/Types';
import { model2formEnvironment } from '@/views/demoenv/model2form';

const state: DemoManagementBoardState = {
  product: PRODUCTS_STYLES[0].value,
  demoenvs: [],
  demoLendings: [],
  edit: false,
  environmentStatusHelper: false,
  lendingStatusHelper: false,
};

const getters = {
  product(st: DemoManagementBoardState): number {
    return st.product;
  },
  environmentStatusHelper(st: DemoManagementBoardState): boolean {
    return st.environmentStatusHelper;
  },
  lendingStatusHelper(st: DemoManagementBoardState): boolean {
    return st.lendingStatusHelper;
  },
};

const actions = {
  changeProduct(
    context: ActionContext<DemoManagementBoardState, {}>,
    product: number
  ) {
    context.commit('updateProduct', product);
  },
  async listDemoEnvironments(
    context: ActionContext<DemoManagementBoardState, {}>,
    token: string
  ) {
    const list = await api.listDemoEnvironments(token, context.state.product);
    context.commit('listDemoEnvironments', list);
  },
  async listDemoLendings(
    context: ActionContext<DemoManagementBoardState, {}>,
    token: string
  ) {
    const list = await api.listDemoLendings(token, context.state.product);
    context.commit('listDemoLendings', list);
  },
  editModeActivate(
    context: ActionContext<DemoManagementBoardState, {}>,
    value: boolean
  ) {
    context.commit('switchEditFlag', value);
  },
  displayLendingStatusHelper(
    context: ActionContext<DemoManagementBoardState, {}>,
    value: boolean
  ) {
    context.commit('switchLendingStatusHelper', value);
  },
};

const mutations = {
  listDemoEnvironments(st: DemoManagementBoardState, list: DemoEnvironment[]) {
    st.demoenvs.length = 0;
    list.map(env => {
      const form = model2formEnvironment(env);
      form.sortLendingsByStartDate();
      st.demoenvs.push(form);
    });
  },
  listDemoLendings(st: DemoManagementBoardState, list: DemoLending[]) {
    st.demoLendings.length = 0;
    list.map(l => {
      if (!l.options) l.options = [];
      l.startDate = l.startDate.substr(0, 10);
      if (!l.endDate) {
        l.endDate = '';
      } else {
        l.endDate = l.endDate.substr(0, 10);
      }
      st.demoLendings.push(l);
    });
  },
  updateProduct(st: DemoManagementBoardState, product: number) {
    st.product = product;
  },
  switchEditFlag(st: DemoManagementBoardState, value: boolean) {
    st.edit = value;
  },
  switchLendingStatusHelper(st: DemoManagementBoardState, value: boolean) {
    st.lendingStatusHelper = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
