import { Customer } from '../Customer';
import { Plan } from './Plan';
import { SpottyOption } from './SpottyOption';
import { SpottyAccount } from './SpottyAccount';
import { SpottyVersion } from './SpottyVersion';

export interface SpottyContract {
  spottyId: number;
  endUserId: number;
  endUser: Customer;
  endUserName: string;
  salesAgentId: number;
  salesAgent: Customer | null;
  planId: number;
  plan: Plan;
  planData: string;
  url: string;
  siteId: string;
  startDate: string;
  billingStartDate: string;
  endDate: string | null;
  closingDay: number;
  timezone: string;
  salesMemo: string;
  options: SpottyOption[];
  initialAccount: SpottyAccount;
  version: SpottyVersion;
}

export const defaultSpottyContract = {
  spottyId: 0,
  endUserId: 0,
  endUser: new Customer(),
  endUserName: '',
  salesAgentId: 0,
  salesAgent: new Customer(),
  planId: 0,
  plan: new Plan(),
  planData: '',
  url: 'https://',
  siteId: '',
  startDate: '',
  billingStartDate: '',
  endDate: null,
  closingDay: 99,
  timezone: 'Asia/Tokyo',
  salesMemo: '',
  options: [] as SpottyOption[],
  initialAccount: new SpottyAccount(),
  version: new SpottyVersion(),
};
