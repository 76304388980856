





















































































































import { Component, Prop, Vue, Inject } from 'vue-property-decorator';
import * as types from './Types';
import { ValidationProvider } from 'vee-validate';
import LendingCustomerSelector from './LendingCustomerSelector.vue';
import DatePicker from '@/components/molecules/DatePicker.vue';
import TextField from '@/components/molecules/TextField.vue';
import OptionCardPlane from '@/components/options/OptionCardPlane.vue';
import { Account } from '@/domain/Account';
import { DemoLending, DemoLendingOption } from '@/domain/DemoEnv';
import { USER_CLASS_ITEMS } from '@/domain/DemoEnv/User';
import { NewDemoLendingOptionInterface } from '@/views/demoenv/interfaces';

@Component({
  components: {
    ValidationProvider,
    DatePicker,
    TextField,
    OptionCardPlane,
    LendingCustomerSelector,
  },
})
export default class Form extends Vue {
  @Prop({ type: Object, required: true })
  private option!: NewDemoLendingOptionInterface;
  @Prop({ type: Object, required: true })
  private demoLending!: DemoLending;
  @Prop({ type: Boolean, required: true })
  private flag!: boolean;

  @Inject()
  readonly myAccount!: Account;

  private userClassItems = USER_CLASS_ITEMS;
  private classPrefix = types.Form.classPrefix;

  private get isResponsible() {
    return (
      this.demoLending.responsibleUserName ==
      this.myAccount.familyName + ' ' + this.myAccount.givenName
    );
  }
  private setEndDate() {
    if (!this.demoLending.startDate) return;
    const date = new Date(this.demoLending.startDate);
    const startDate = new Date(this.demoLending.startDate);
    startDate.setMonth(startDate.getMonth() + 1);
    if (date.getDate() > startDate.getDate()) {
      startDate.setDate(0);
    }
    this.demoLending.endDate = startDate.toISOString().substr(0, 10);
  }

  private updateOptions() {
    this.demoLending.options.length = 0;
    this.option.optionDatas.forEach(optionData => {
      if (typeof optionData.data === 'string' || !optionData.data.using) return;
      const option = new DemoLendingOption(
        optionData.mode,
        optionData.optionId,
        optionData.optionsTypeId,
        optionData.productId,
        'otegaru',
        optionData.optionVersion,
        this.demoLending.demoLendingId,
        optionData.data
      );
      this.demoLending.options.push(option);
    });
  }
  private async setResponsibleUser() {
    if (this.myAccount.accountId == 0) {
      this.$emit('fail', 'ユーザー情報の取得に失敗しました。');
      return;
    }

    // 自分以外の担当者を指定する場合の初期化処理
    if (this.isResponsible) {
      this.demoLending.responsibleUserName = '';
      return;
    }
    this.demoLending.responsibleUserName =
      this.myAccount.familyName + ' ' + this.myAccount.givenName;
  }
}
