








































import { Component, Vue, Prop } from 'vue-property-decorator';
import { DemoEnvironment } from '@/domain/DemoEnv/Types';
import { GanttChartSetting } from './Types';
import * as envstatus from '@/domain/DemoEnv/EnvironmentStatus';

@Component({
  components: {},
})
export default class GanttEnvironments extends Vue {
  @Prop({ type: Object, required: true })
  private setting!: GanttChartSetting;

  @Prop({ type: Array, required: true })
  private environmentList!: DemoEnvironment[];

  @Prop({ type: Function, required: true })
  private envClickEvent!: (demoEnvId: number) => any;

  private get envStatusSetting() {
    return envstatus.getEnvironmentStatusSetting;
  }

  private HoverSetting = {
    hoverFlag: false,
    style: {
      position: 'absolute',
      top: '10px',
      left: '10px',
      'z-index': 10,
    },
  };
  private hoverCard = {
    statusUpdated: '',
  } as any;
  private cardHoverEvent(
    hoverEvent: MouseEvent,
    env: DemoEnvironment,
    leave: boolean
  ) {
    if (leave) {
      this.HoverSetting.hoverFlag = false;
      return;
    }
    // ホバー表示の位置
    const offsetX = -200;
    const offsetY = -30;
    this.HoverSetting.style.top =
      hoverEvent.pageY + offsetY - hoverEvent.offsetY + 'px';
    this.HoverSetting.style.left =
      hoverEvent.pageX + offsetX - hoverEvent.offsetX + 'px';

    const convertJST = new Date(env.updatedAt);
    const updatedTime = convertJST.toLocaleString('ja-JP').slice(0, -3);
    this.hoverCard.statusUpdated = updatedTime;

    this.HoverSetting.hoverFlag = true;
  }
}
