import { Account } from '@/domain/Account';

const tokenName = 'token';
const redirectName = 'redirect';

export const storeToken = (token: string) => {
  window.sessionStorage.setItem(tokenName, token);
};

export const getToken = (): string => {
  const token = window.sessionStorage.getItem(tokenName);
  if (!token) {
    return '';
  }
  return token;
};

export const deleteToken = () => {
  window.sessionStorage.removeItem(tokenName);
};

export const storeRedirectURL = (url: string) => {
  window.sessionStorage.setItem(redirectName, url);
};

export const getRedirectURL = (): string => {
  const redirectURL = window.sessionStorage.getItem(redirectName);
  if (!redirectURL) {
    return '';
  }
  return redirectURL;
};

export const deleteRedirectURL = () => {
  window.sessionStorage.removeItem(redirectName);
};

export const storeAccount = (account: string) => {
  window.sessionStorage.setItem('account', account);
};

export const getAccount = (): Account => {
  const account = window.sessionStorage.getItem('account');
  if (!account) {
    return new Account();
  }
  const obj = JSON.parse(account) as any;
  return new Account(
    obj.accountId,
    obj.LoginId,
    obj.email,
    obj.familyName,
    obj.givenName,
    obj.beginDate,
    obj.endDate,
    obj.departments,
    obj.learningGroups,
    obj.attributes
  );
};
