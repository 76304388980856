import axios, { AxiosResponse, AxiosError } from 'axios';
import { SelfSetting } from '@/domain/SelfSetting';

export const selfSetting = async (): Promise<SelfSetting> => {
  const url = '/selfsetting.json';
  const ret = await axios
    .get(url)
    .then((items: AxiosResponse<SelfSetting>) => {
      return items.data;
    })
    .catch((error: AxiosError) => {
      throw error;
    });

  return Promise.resolve(ret);
};
