// 環境ステータスや貸出ステータスの選択肢の場合分けや制御をここにまとめる
// (環境ステータスが○○の場合には貸出ステータスが××にできる、みたいなことをまとめる)

import { DemoLending } from '@/domain/DemoEnv';
import * as envstatus from '@/domain/DemoEnv/EnvironmentStatus';

// 指定IDの環境が貸出中かどうか
export function isTargetEnvironmentOnLoan(
  demoEnvId: number,
  demoLendings: DemoLending[]
) {
  return (
    demoLendings.filter(l => {
      return l.demoEnvId == demoEnvId && l.assigned;
    }).length != 0
  );
}

// 初期化後、手動での確認待ちかどうか
export function isPendingVerification(status: string): boolean {
  if (status == envstatus.INITIAL_STATE) {
    return true;
  }
  return false;
}
