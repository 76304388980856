import { post } from './io';
import { DemoEnvironment, DemoLending } from '@/domain/DemoEnv/Types';
import { binary } from './io';

const rootUrl = process.env.VUE_APP_URL + '/base';

export const addDemoLending = async (
  token: string,
  demoLending: DemoLending
): Promise<DemoLending> => {
  const url = rootUrl + '/unites/demolending/add';
  const params = {
    demoLending,
  };
  const headers = { Authorization: 'Bearer ' + token };
  const ret = await post(url, params, { headers });
  return Promise.resolve(ret.demoLending);
};

export const deleteLending = async (
  token: string,
  demoLendingId: number
): Promise<DemoLending> => {
  const url = rootUrl + '/unites/demolending/delete';
  const params = { demoLendingId };
  const headers = { Authorization: 'Bearer ' + token };
  const ret = await post(url, params, { headers });
  return Promise.resolve(ret);
};

export const describeEnvironment = async (
  token: string,
  demoEnvironmentId: number
): Promise<unknown> => {
  const url = rootUrl + '/unites/demoenv/describe';
  const params = { demoEnvironmentId };
  const headers = { Authorization: 'Bearer ' + token };
  const ret = await post(url, params, { headers });
  return Promise.resolve(ret.demoEnvironmentDetail);
};

export const describeDemoLending = async (
  token: string,
  demoLendingId: number
): Promise<unknown> => {
  const url = rootUrl + '/unites/demolending/describe';
  const params = { demoLendingId };
  const headers = { Authorization: 'Bearer ' + token };
  const ret = await post(url, params, { headers });
  return Promise.resolve(ret.demoLending);
};

export const listDemoEnvironments = async (
  token: string,
  productId: number
): Promise<unknown[]> => {
  const url = rootUrl + '/unites/demoenv/list';
  const params = { productId };
  const headers = { Authorization: 'Bearer ' + token };
  const ret = await post(url, params, { headers });
  return Promise.resolve(ret.demoEnvironmentDetails);
};

export const listDemoLendings = async (
  token: string,
  productId: number
): Promise<unknown[]> => {
  const url = rootUrl + '/unites/demolending/list';
  const params = { productId };
  const headers = { Authorization: 'Bearer ' + token };
  const ret = await post(url, params, { headers });
  return Promise.resolve(ret.demoLendings);
};

export const donwnloadDemo = async (
  token: string,
  productId: number
): Promise<unknown[]> => {
  const url = rootUrl + '/unites/demoenv/list/download';
  const params = { productId };
  const headers = { Authorization: 'Bearer ' + token };
  // const ret = await post(url, params, { headers });
  const ret = await binary(url, params, {
    headers,
    responseType: 'arraybuffer',
  });
  return Promise.resolve(ret.demoEnvironmentDetails);
};

export const donwnloadLending = async (
  token: string,
  productId: number
): Promise<unknown[]> => {
  const url = rootUrl + '/unites/demoenv/lending/download';
  const params = { productId };
  const headers = { Authorization: 'Bearer ' + token };
  // const ret = await post(url, params, { headers });
  const ret = await binary(url, params, {
    headers,
    responseType: 'arraybuffer',
  });
  return Promise.resolve(ret.demoEnvironmentDetails);
};

export const updateEnvironments = async (
  token: string,
  environments: DemoEnvironment[]
): Promise<void> => {
  const url = rootUrl + '/unites/demoenv/update';
  const params = {
    environments,
  };
  const headers = { Authorization: 'Bearer ' + token };
  await post(url, params, { headers });
  return Promise.resolve();
};

export const updateDemoLendings = async (
  token: string,
  demoLendings: DemoLending[]
): Promise<void> => {
  const url = rootUrl + '/unites/demolending/update';
  const params = {
    demoLendings,
  };
  const headers = { Authorization: 'Bearer ' + token };
  await post(url, params, { headers });
  return Promise.resolve();
};

export const assignLendingEnvironment = async (
  token: string,
  demoEnvironmentId: number,
  demoLendingId: number,
  assign: boolean
): Promise<any> => {
  const url = rootUrl + '/unites/demolending/assign';
  const params = {
    demoEnvironmentId,
    demoLendingId,
    assign,
  };
  const headers = { Authorization: 'Bearer ' + token };
  const ret = await post(url, params, { headers });
  return Promise.resolve(ret);
};

export const updateEnvironmentStatus = async (
  token: string,
  demoEnvironmentId: number,
  status: string
): Promise<DemoEnvironment> => {
  const url = rootUrl + '/unites/demoenv/status/update';
  const params = {
    demoEnvironmentId,
    status,
  };
  const headers = { Authorization: 'Bearer ' + token };
  const ret = await post(url, params, { headers });
  return Promise.resolve(ret.demoEnvironmentDetail);
};

export const updateLendingStatus = async (
  token: string,
  demoLending: DemoLending
): Promise<DemoLending> => {
  const url = rootUrl + '/unites/demolending/status/update';
  const params = {
    demoLending,
  };
  const headers = { Authorization: 'Bearer ' + token };
  const ret = await post(url, params, { headers });
  return Promise.resolve(ret.demoLending);
};
