import { SpottyContract } from '@/domain/SpottyContract/SpottyContract';
import { SpottyDetailForm } from './SpottyDetailForm';

// モデルから契約詳細フォームへの変換
export const model2form = (obj: SpottyContract): SpottyDetailForm => {
  if (obj.options) {
    obj.options.map(opt => {
      opt.data = JSON.parse(String(opt.data));
      opt.data['using'] = true;
      opt.mode = 'edit';
      return opt;
    });
  }
  return {
    spottyId: obj.spottyId,
    endUserName: obj.endUser.customerName,
    salesAgentId: obj.salesAgent ? obj.salesAgent.customerId.toString() : '0',
    plan: { planId: obj.planId, planData: obj.planData },
    url: obj.url,
    siteId: obj.siteId,
    startDate: obj.startDate.substr(0, 10),
    billingStartDate: obj.billingStartDate.substr(0, 10),
    endDate: obj.endDate ? obj.endDate.substr(0, 10) : '',
    closingDay: obj.closingDay,
    salesMemo: obj.salesMemo,
    options: obj.options,
    initialAccount: obj.initialAccount,
    salesAgentKeywords: obj.salesAgent ? obj.salesAgent.customerName : '',
  };
};
