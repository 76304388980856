



















































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import DatePicker from '@/components/molecules/DatePicker.vue';
import { Event } from '@/domain/DashBoard/Calender';

@Component({
  components: {
    DatePicker,
  },
})
export default class ContractCalendar extends Vue {
  @Prop({ type: String, default: false })
  private calendarRef!: string;
  @Prop({ type: Object, default: false })
  private events!: any;
  private calendarYear!: number;
  private calendarMonth!: number;
  @Prop({ type: String, default: false })
  private calendarValue!: string;
  private value!: string;
  @Prop({ type: String, default: false })
  private initialDate!: string;
  private HoverSetting = {
    hoverFlag: false,
    style: {
      position: 'absolute',
      top: '10px',
      left: '10px',
      width: '320px',
      'z-index': 2,
    },
  };
  private ExpandSetting = {
    expandFlag: false,
    date: '',
    style: {
      position: 'absolute',
      top: '10px',
      left: '10px',
      width: '320px',
      'z-index': 1,
    },
  };
  private detailTarget = { idx: 0, date: '' };
  private get detailEvent(): Event {
    const events = this.events[this.detailTarget.date];
    if (!events) return new Event();
    return events[this.detailTarget.idx];
  }
  private calendarDate!: string;
  private get calendarDate_() {
    return this.calendarYear + '年 ' + this.calendarMonth + '月';
  }
  private created() {
    const pieces = this.calendarValue.split('-');
    this.calendarYear = Number(pieces[0]);
    this.calendarMonth = Number(pieces[1]);
    this.value = this.calendarValue;
    this.calendarDate = this.calendarYear + '年 ' + this.calendarMonth + '月';
  }
  /**
   * 操作
   */
  public move(direction: string) {
    const calendar = this.$refs[this.calendarRef] as any;
    if (direction == 'next') {
      calendar.next();
      this.calendarMonth++;
      if (this.calendarMonth > 12) {
        this.calendarMonth = 1;
        this.calendarYear++;
      }
    }
    if (direction == 'prev') {
      calendar.prev();
      this.calendarMonth--;
      if (this.calendarMonth == 0) {
        this.calendarMonth = 12;
        this.calendarYear--;
      }
    }
    this.calendarDate = this.calendarYear + '年 ' + this.calendarMonth + '月';
    // TODO: forceUpdateは使いたくないので、強制更新せずにリアクティブに表示月の移動ができるように修正する
    this.$forceUpdate();
  }
  private expand(mouseEvent: MouseEvent, event: Event, date: string) {
    // 拡大表示の位置
    const offsetX = -450;
    const offsetY = -30;
    this.ExpandSetting.style.top =
      mouseEvent.pageY + offsetY - mouseEvent.offsetY + 'px';
    this.ExpandSetting.style.left =
      mouseEvent.pageX + offsetX - mouseEvent.offsetX + 'px';

    this.ExpandSetting.date = date;
    this.ExpandSetting.expandFlag = true;
  }
  private shrink() {
    this.ExpandSetting.date = '';
    this.ExpandSetting.expandFlag = false;
    this.HoverSetting.hoverFlag = false;
  }
  private eventDetail(
    hoverEvent: MouseEvent,
    event: Event,
    date: string,
    idx: number
  ) {
    if (this.detailTarget.date == date && this.detailTarget.idx == idx) {
      this.detailTarget.date = '';
      this.HoverSetting.hoverFlag = false;
      return;
    }
    // 詳細表示の位置
    const offsetX = -400;
    const offsetY = -30;
    this.HoverSetting.style.top =
      hoverEvent.pageY + offsetY - hoverEvent.offsetY + 'px';
    this.HoverSetting.style.left =
      hoverEvent.pageX + offsetX - hoverEvent.offsetX + 'px';

    this.detailTarget.date = date;
    this.detailTarget.idx = idx;
    this.HoverSetting.hoverFlag = true;
  }
  private closeDetail() {
    this.detailTarget.date = '';
    this.HoverSetting.hoverFlag = false;
  }
  private openContractDetail(event: Event) {
    if (!event.id) return;
    const detailUrl = event.division
      ? '/' + event.product + '/' + event.division + '/detail/' + event.id
      : '/' + event.product + '/detail/' + event.id;
    window.open(detailUrl);
  }
  private mouseLeave() {
    this.HoverSetting.hoverFlag = false;
  }
}
