






















































































import vue from 'vue';
import { Component, Provide, Inject, Vue, Watch } from 'vue-property-decorator';
import ActionMenu from '@/components/molecules/ActionMenu.vue';
import DatePicker from '@/components/molecules/DatePicker.vue';
import { Account } from '@/domain/Account';
import EnvironmentListCriteria from './components/DemoEnvironmentList/EnvironmentListCriteria.vue';
import EnvironmentListCard from './components/DemoEnvironmentList/EnvironmentListCard.vue';
import ReservedLendingList from './components/DemoEnvironmentList/ReservedLendingList.vue';
import DemoEnvironmentCard from '@/components/demoenv/DemoEnvironmentCard.vue';
import LendingCard from '@/components/demoenv/LendingCard.vue';
import { mapActions, mapState } from 'vuex';
import * as api from '@/api/demoenv';
import * as sessionStore from '@/stores/session';
import { DemoEnvironment, DemoLending } from '@/domain/DemoEnv/Types';
import * as Table from './table';
import { model2formLending } from './model2form';
import { form2modelLending } from '@/views/demoenv/form2model';
import * as envstatus from '@/domain/DemoEnv/EnvironmentStatus';
import * as lendstatus from '@/domain/DemoEnv/LendingStatus';
import { DemoEnvironmentListInterface } from './components/DemoEnvironmentList/Property';
import { DemoEnvironmentApiInterface } from './interfaces';

@Component({
  components: {
    ActionMenu,
    DatePicker,
    EnvironmentListCriteria,
    EnvironmentListCard,
    ReservedLendingList,
    DemoEnvironmentCard,
    LendingCard,
  },
  computed: {
    ...mapState('accountInfo', ['account']),
    ...mapState('demoManagementBoard', {
      product: 'product',
      demoenvs: 'demoenvs',
      demoLendings: 'demoLendings',
      editMode: 'edit',
    }),
  },
  methods: {
    ...mapActions('demoManagementBoard', ['editModeActivate']),
    ...mapActions('alertArea', ['success', 'fail', 'warning']),
    ...mapActions('demoEnvironmentList', ['activateItem', 'setSearchParams']),
    ...mapActions('demoLendingNew', {
      addLendingDialogStart: 'dialogStart',
      setNewDemoLendingTargetEnv: 'setTargetEnv',
    }),
    ...mapActions('demoLendingReservation', {
      setReserveDemoLendingTargetEnv: 'setTargetEnv',
      reserveLendingDialogStart: 'dialogStart',
    }),
  },
})
export default class DemoEnvironmentList extends Vue {
  @Inject('demoEnvironmentListProps')
  private props!: DemoEnvironmentListInterface;
  @Inject('demoEnvApiClient')
  private client!: DemoEnvironmentApiInterface;

  private product!: number;
  private demoenvs!: DemoEnvironment[];
  private demoenvsTableData: DemoEnvironment[] = [];
  @Watch('demoenvs')
  private updateDemoEnvironmentsTableData() {
    this.demoenvsTableData = Table.environmentsFilter(
      this.environmentList,
      this.props.criteria.status,
      this.props.criteria.envName,
      this.props.criteria.envRole
    );
  }
  private get environmentList(): DemoEnvironment[] {
    if (this.demoenvs.length == 0) return [] as DemoEnvironment[];
    return this.demoenvs;
  }
  private selectedDemoEnv: DemoEnvironment = vue.observable<DemoEnvironment>(
    new DemoEnvironment()
  );
  private demoLendings!: DemoLending[];
  private selectedDemoLending: DemoLending = vue.observable<DemoLending>(
    new DemoLending()
  );
  private reserveDialog = false;
  private reserveDialogScene = '';
  private reserveTargetEnvironment: DemoEnvironment = new DemoEnvironment();
  private addLendingDialogStart!: (
    callback: (lending: DemoLending) => void
  ) => {};
  private setNewDemoLendingTargetEnv!: (newId: number) => {};
  private setReserveDemoLendingTargetEnv!: (newId: number) => {};
  private reserveLendingDialogStart!: () => {};
  private setSearchParams!: (params: any) => {};
  private editMode!: boolean;
  private editModeActivate!: (value: boolean) => {};
  private account!: Account;
  private success!: (message: string) => void;
  private fail!: (message: string) => void;
  private warning!: (message: string) => void;
  private mounted() {
    // 戻るボタンイベントの追加
    window.addEventListener('popstate', this.init);
  }

  private async created() {
    this.init();
  }

  @Watch('product')
  private init() {
    // プロパティ初期化
    this.selectedDemoEnv = new DemoEnvironment();
    this.selectedDemoLending = new DemoLending();

    try {
      const urlQuery = this.$route.query;
      const target = this.$route.params.tabTarget;
      if (target == 'environments') {
        if (urlQuery.demoEnvId) {
          const targetId = Number(urlQuery.demoEnvId);
          this.openDemoEnvironment(targetId);
        }
        if (urlQuery.demoLendingId) {
          const targetId = Number(urlQuery.demoLendingId);
          const arg = new DemoLending();
          arg.demoLendingId = targetId;
          this.openDemoLending(arg);
        }
      }
      if (urlQuery.status) {
        this.props.criteria.status = urlQuery.status as envstatus.EnvironmentStatus;
      }
    } catch {
      // do nothing
    }
  }
  @Provide('reserveDialogStart')
  private reserveDialogStart(env: DemoEnvironment) {
    if (this.editMode && !confirm('編集内容を破棄してもよろしいですか？'))
      return;
    this.editModeActivate(false);
    this.reserveDialogScene = 'reserveMethodSelection';
    this.reserveDialog = true;
    this.reserveTargetEnvironment = env;
  }
  private reserveDialogClick(selection: string) {
    if (selection == 'reserveNewLending') {
      this.reserveDialog = false;
      this.addLendingDialogStart(this._addLendingCallBack);
      this.setNewDemoLendingTargetEnv(this.reserveTargetEnvironment.demoEnvId);
    }
    if (selection == 'reserveFromList') {
      this.reserveDialog = false;
      this.reserveLendingDialogStart();
      this.setReserveDemoLendingTargetEnv(
        this.reserveTargetEnvironment.demoEnvId
      );
    }
  }

  private _addLendingCallBack(lending: DemoLending) {
    if (lending.lendingStatus == lendstatus.WAITING) {
      this.openDemoLending(lending);
      return;
    }
    if (lending.demoEnvId != 0) {
      this.openDemoEnvironment(lending.demoEnvId);
      return;
    }
    this.openDemoLending(lending);
  }
  private activateItem!: (id: number) => {};

  @Provide('releaseLending')
  private async releaseLending(lending: DemoLending) {
    if (
      !confirm(
        '表示貸出データに対し、使用している環境からの解除を行いますがよろしいですか？\n(再割り当て可能です。)'
      )
    ) {
      return;
    }
    try {
      await api.assignLendingEnvironment(
        sessionStore.getToken(),
        lending.demoEnvId,
        lending.demoLendingId,
        false
      );

      // 貸出ステータスを初期化する
      lending.lendingStatus = lendstatus.WAITING;
      await api.updateLendingStatus(
        sessionStore.getToken(),
        form2modelLending(lending)
      );

      // 環境ステータスを初期化する
      await api.updateEnvironmentStatus(
        sessionStore.getToken(),
        lending.demoEnvId,
        envstatus.AVAILABLE
      );
      if (this.selectedDemoLending.demoLendingId == lending.demoLendingId)
        this.openDemoLending(lending);
      this.success('環境からの割当解除が完了しました。');
      this.$emit('update', true);
    } catch {
      this.fail('環境からの割当解除に失敗しました。');
      // 失敗した場合は再ロードする
      this.$emit('update', true);
    }
  }
  @Provide('openDemoEnvironment')
  private async openDemoEnvironment(demoEnvId: number) {
    if (this.editMode && !confirm('編集内容を破棄してもよろしいですか？'))
      return;
    this.editModeActivate(false);
    this.selectedDemoEnv = new DemoEnvironment();
    if (this.selectedDemoLending.demoEnvId == demoEnvId)
      this.selectedDemoLending = new DemoLending();
    if (demoEnvId == 0) {
      return;
    }
    this.props.editorDisplay = 'environment';
    try {
      this.selectedDemoEnv = await this.client.describeEnvironment(demoEnvId);
      this.setSearchParams({ demoEnvId: this.selectedDemoEnv.demoEnvId });
      this.$forceUpdate();
    } catch {
      this.fail('環境情報の取得に失敗しました。');
    }
  }
  @Provide('openDemoLending')
  private async openDemoLending(lending: DemoLending) {
    if (this.editMode && !confirm('編集内容を破棄してもよろしいですか？'))
      return;
    if (lending.demoLendingId == 0) {
      this.selectedDemoLending = new DemoLending();
      return;
    }
    this.editModeActivate(false);
    try {
      const demoLending = await this.client.describeDemoLending(
        lending.demoLendingId
      );
      this.selectedDemoLending = model2formLending(demoLending);

      if (
        lending.lendingStatus == lendstatus.LENDING ||
        lending.lendingStatus == lendstatus.END_OF_USE
      ) {
        this.openDemoEnvironment(this.selectedDemoLending.demoEnvId);
        return;
      }

      this.setSearchParams({
        demoLendingId: this.selectedDemoLending.demoLendingId,
      });
      this.props.editorDisplay = 'lending';
      this.$forceUpdate();
    } catch {
      this.fail('貸出情報の取得に失敗しました。');
      this.selectedDemoLending = new DemoLending();
    }
  }
  private active(env: DemoEnvironment) {
    this.activateItem(env.demoEnvId);
    this.updateDemoEnvironmentsTableData();
  }

  // Emit
  private emitLendingUpdate(lending: DemoLending) {
    this.$emit('update', lending);
    if (
      this.selectedDemoLending.demoLendingId == lending.demoLendingId ||
      this.selectedDemoEnv.demoEnvId == lending.demoEnvId
    ) {
      this.selectedDemoLending = new DemoLending();
      this.openDemoLending(lending);
      return;
    }
  }
  private emitDelete(name: string) {
    this.selectedDemoLending = new DemoLending();
    this.$emit(name, true);
  }
  private emitEnvironmentUpdate(env: DemoEnvironment) {
    this.$emit('update', true);
    this.openDemoEnvironment(env.demoEnvId);
  }
  private emitLendingDelete() {
    this.openDemoLending(new DemoLending());
    this.$emit('update', true);
  }
}
