






























































































import { Component, Vue } from 'vue-property-decorator';
import ActionMenu from '@/components/molecules/ActionMenu.vue';
import CustomerSearchSelector from '@/components/customer/CustomerSearchSelector.vue';
import PlatonOptionEditor from '@/components/platon/PlatonOptionEditor.vue';
import DatePicker from '@/components/molecules/DatePicker.vue';
import { mapActions, mapState } from 'vuex';
import * as sessionStore from '@/stores/session';
import { DemoLending } from '@/domain/DemoEnv/Types';
import { dateDisplay } from '@/domain/DemoEnv/Date';
import { newCheckPoints } from './LendingStatusCheckpoints';
import * as lendstatus from '@/domain/DemoEnv/LendingStatus';
@Component({
  components: {
    ActionMenu,
    CustomerSearchSelector,
    DatePicker,
    PlatonOptionEditor,
  },
  computed: {
    ...mapState('demoManagementBoard', {
      product: 'product',
      editMode: 'edit',
    }),
    ...mapState('demoLendingStatus', [
      'statusUpdateDialog',
      'endedDialog',
      'endDate',
      'newLendingStatus',
      'demoLending',
    ]),
    endDate: {
      get() {
        return this.$store.getters['demoLendingStatus/endDate'];
      },
      set(val: string) {
        this.$store.dispatch('demoLendingStatus/setEndDate', val);
      },
    },
  },
  methods: {
    ...mapActions('demoLendingStatus', [
      'switchStatusUpdateDialog',
      'switchEndedDialog',
      'update',
    ]),
    ...mapActions('alertArea', ['success', 'fail', 'warning']),
  },
})
export default class LendingStatusChangeDialog extends Vue {
  private product!: number;
  private newLendingStatus!: string;
  private demoLending!: DemoLending;
  private get lendingStatusItems() {
    return lendstatus.getLendingStatusItems(this.demoLending);
  }
  private statusUpdateDialog!: boolean;
  private switchStatusUpdateDialog!: (value: boolean) => {};
  private endDate!: string;
  private endedDialog!: boolean;
  private switchEndedDialog!: (value: boolean) => {};
  private setEndDate!: (endDate: string) => {};
  private get lendingCheckPoint() {
    const cp = newCheckPoints(this.product);
    if (this.newLendingStatus == lendstatus.WAITING) {
      return cp.waiting;
    }
    if (this.newLendingStatus == lendstatus.LENDING) {
      return cp.lending;
    }
    return {};
  }
  private get lendingStatusSetting() {
    return lendstatus.getLendingStatusSetting;
  }
  private get lendingStatusItem() {
    return (status: string) => {
      const item = this.lendingStatusItems.filter(l => {
        return l.value == status;
      });
      if (!status || item.length == 0) return '';
      return item[0].label;
    };
  }
  private get dateDisplay() {
    return dateDisplay;
  }
  private editMode!: boolean;
  private success!: (message: string) => void;
  private fail!: (message: string) => void;
  private warning!: (message: string) => void;

  private update!: (token: string) => {};
  private async lendingStatusChanged(action: string) {
    if (action == 'confirm') {
      // ダイアログでのステータス変更確定時の処理
      this.switchStatusUpdateDialog(false);
      this.switchEndedDialog(false);

      try {
        this.update(sessionStore.getToken());
        this.success('貸出ステータスを更新しました。');
      } catch {
        action = 'cancel';
        this.fail('ステータスの更新に失敗しました。');
      }
    }
    if (action == 'cancel') {
      // ダイアログでのステータス変更確定時の処理
      this.switchStatusUpdateDialog(false);
      this.switchEndedDialog(false);
      return;
    }
  }
}
