






















import { Component, Vue, Emit, Prop } from 'vue-property-decorator';
import { mapActions } from 'vuex';
import { Version } from '@/views/libra/LibraNewBasic/Form';
import { versionDivisions } from '@/domain/LibraContract/LibraVersion';

@Component({
  methods: mapActions('alertArea', ['success', 'fail']),
})
export default class LibraVersionSelector extends Vue {
  private success!: (message: string) => void;
  private fail!: (message: string) => void;

  @Prop({ type: Number, required: true })
  private division!: number;

  @Prop({ type: Object, required: true })
  private value!: Version;

  @Emit()
  // eslint-disable-next-line
  private input(val: Version) {
    // Parent action
  }

  private get divisions() {
    return versionDivisions;
  }

  /**
   * 操作
   */
  private async changeDivision(val: string) {
    this.input({ versionDivision: val, versionLabel: '' });
  }

  private async changeVersion(val: string) {
    this.input({
      versionDivision: this.value.versionDivision,
      versionLabel: val,
    });
  }
}
