import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import selfSettingStore from './modules/selfSettingStore';
import accountInfo from './modules/accountInfo';
import alertArea from './modules/alertArea';
import platonNew from './modules/platonNew';
import platonOtegaruList from './modules/platonOtegaruList';
import platonOnPremiseList from './modules/platonOnPremiseList';
import libraNew from './modules/libraNew';
import libraOtegaruList from './modules/libraOtegaruList';
import libraOnPremiseList from './modules/libraOnPremiseList';
import spottyNew from './modules/spottyNew';
import spottyList from './modules/spottyList';
import demoManagementBoard from './modules/demoManagementBoard';
import demoEnvironmentList from './modules/demoEnvironmentList';
import demoLendingNew from './modules/demoLendingNew';
import demoLendingReservation from './modules/demoLendingReservation';
import demoLendingList from './modules/demoLendingList';
import demoLendingStatus from './modules/demoLendingStatus';

Vue.use(Vuex);

const store: StoreOptions<{}> = {
  state: {},
  modules: {
    selfSettingStore,
    alertArea,
    accountInfo,
    platonNew,
    platonOtegaruList,
    platonOnPremiseList,
    libraNew,
    libraOtegaruList,
    libraOnPremiseList,
    spottyNew,
    spottyList,
    demoManagementBoard,
    demoEnvironmentList,
    demoLendingNew,
    demoLendingReservation,
    demoLendingList,
    demoLendingStatus,
  },
};

export default new Vuex.Store<{}>(store);
