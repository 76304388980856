







import { Component, Prop, Vue } from 'vue-property-decorator';
import { DemoLending } from '@/domain/DemoEnv/Types';
import LendingStatusChip from '@/components/demoenv/LendingStatusChip.vue';
import * as lendstatus from '@/domain/DemoEnv/LendingStatus';
@Component({
  components: {
    LendingStatusChip,
  },
})
export default class LendingStatusSelector extends Vue {
  @Prop({ type: Object, required: true })
  private demoLending!: DemoLending;
  @Prop({ type: Boolean, required: true })
  private onLoan!: boolean;
  @Prop({ type: Boolean, required: true })
  private onPendingVerification!: boolean;

  public created() {
    // 初期貸出ステータスの設定
    if (this.onLoan || this.onPendingVerification) {
      // 貸出中の場合
      this.demoLending.lendingStatus = lendstatus.WAITING;
      return;
    }
    this.demoLending.lendingStatus = lendstatus.LENDING;
  }

  private get initialLendingStatusItems() {
    if (this.onLoan) return [lendstatus.WAITING_STYLE];
    return lendstatus.INITIAL_LENDING_STATUS_ITEMS;
  }
}
