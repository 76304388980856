


































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import ActionMenu from '@/components/molecules/ActionMenu.vue';
import DatePicker from '@/components/molecules/DatePicker.vue';
import { Account } from '@/domain/Account';
import LendingCard from '@/components/demoenv/LendingCard.vue';
import { mapActions, mapState } from 'vuex';
import * as api from '@/api/demoenv';
import * as sessionStore from '@/stores/session';
import { getProductStyle } from '@/domain/DemoEnv/Product';
import {
  DemoEnvironment,
  DemoLending,
  isDemoLending,
} from '@/domain/DemoEnv/Types';
import * as Table from './table';
import { model2formLending } from './model2form';
import * as lendstatus from '@/domain/DemoEnv/LendingStatus';

@Component({
  components: {
    ActionMenu,
    DatePicker,
    LendingCard,
  },
  computed: {
    ...mapState('accountInfo', ['account']),
    ...mapState('demoManagementBoard', {
      product: 'product',
      demoenvs: 'demoenvs',
      demoLendings: 'demoLendings',
      editMode: 'edit',
    }),
  },
  methods: {
    ...mapActions('demoManagementBoard', ['editModeActivate']),
    ...mapActions('alertArea', ['success', 'fail', 'warning']),
    ...mapActions('demoLendingList', ['setSearchParams']),
  },
})
export default class DemoLendingList extends Vue {
  private product!: string;
  private get getProductStyle() {
    return getProductStyle;
  }
  private demoenvs!: DemoEnvironment[];
  private get demoEnvironment() {
    return (demoEnvId: number) => {
      const env = this.demoenvs.filter(env => {
        return env.demoEnvId == demoEnvId;
      });
      if (env.length == 0) return new DemoEnvironment();
      return env[0];
    };
  }
  private lendingsHeader!: any[];
  private demoLendings!: DemoLending[];
  // TODO: ↓リスト表示用なので冗長なので、検索条件が変わる度にListAPIで一覧を再取得するように変更する(ListAPIに条件が渡せるようになったら変更)
  private lendingsTableData: DemoLending[] = [];
  @Watch('demoLendings')
  private updateLendingsTableData() {
    let responsibleUserName = '';
    if (this.lendingListCriteria.myLending)
      responsibleUserName =
        this.account.familyName + ' ' + this.account.givenName;
    this.lendingsTableData = Table.lendingsFilter(
      this.demoLendings,
      this.lendingListCriteria.status,
      this.lendingListCriteria.userName,
      responsibleUserName
    );
  }
  private selectedDemoLending: DemoLending = new DemoLending();
  private lendingStatusItems = lendstatus.LENDING_STATUS_ITEMS;
  private get countLendingStatus() {
    return (status: string): any => {
      const lendings = this.demoLendings.filter(l => {
        return l.lendingStatus == status;
      });
      if (lendings.length == 0) return '0';
      return lendings.length;
    };
  }
  private lendingListCriteria!: Table.LendingListCriteria;
  private setSearchParams!: (params: any) => {};
  private editMode!: boolean;
  private editModeActivate!: (value: boolean) => {};
  private account!: Account;
  private success!: (message: string) => void;
  private fail!: (message: string) => void;
  private warning!: (message: string) => void;
  private get lendingStatusSetting() {
    return lendstatus.getLendingStatusSetting;
  }
  private get userClassLabel() {
    return (userClass: string) => {
      if (userClass == 'customer') return '一般顧客';
      if (userClass == 'lw') return 'LW';
      return 'その他';
    };
  }
  private class2rgb(classString: string, opacity: number): string {
    return lendstatus.class2rgb(classString, opacity);
  }
  private mounted() {
    window.addEventListener('popstate', this.init);
  }
  private async created() {
    const lendingsTable = new Table.LendingsTable();
    this.lendingsHeader = lendingsTable.header;
    this.lendingListCriteria = lendingsTable.criteria;
  }
  @Watch('product')
  private init() {
    // プロパティ初期化
    this.selectedDemoLending = new DemoLending();

    try {
      const urlQuery = this.$route.query;
      const targetId = urlQuery.demoLendingId
        ? Number(urlQuery.demoLendingId)
        : 0;
      const target = this.$route.params.tabTarget;
      if (target == 'lendings' && targetId > 0) {
        const arg = new DemoLending();
        arg.demoLendingId = targetId;
        this.openDemoLending(arg);
      }
    } catch {
      // do nothing
    }
  }
  private async openDemoLending(lending: DemoLending) {
    if (this.editMode && !confirm('編集内容を破棄してもよろしいですか？'))
      return;
    this.editModeActivate(false);
    if (lending.demoLendingId == 0) {
      this.selectedDemoLending = new DemoLending();
      return;
    }
    try {
      const ret = await api.describeDemoLending(
        sessionStore.getToken(),
        lending.demoLendingId
      );
      if (!isDemoLending(ret)) throw 'error';
      this.selectedDemoLending = model2formLending(ret);
      this.setSearchParams({
        demoLendingId: this.selectedDemoLending.demoLendingId,
      });
      this.$forceUpdate();
    } catch {
      this.fail('貸出情報の取得に失敗しました。');
      this.selectedDemoLending = new DemoLending();
    }
  }
  private openDemoEnvironmentDetail(demoEnvId: number) {
    window.open('./environments?demoEnvId=' + demoEnvId);
  }
  private emitDelete(name: string) {
    this.selectedDemoLending = new DemoLending();
    this.$emit(name, true);
  }
  private emitUpdate(name: string, lending: any) {
    this.openDemoLending(lending);
    this.$emit(name, true);
  }
  private actionMenu = [
    {
      icon: 'cloud_download',
      text: 'CSVでダウンロードする',
      color: 'secondary',
      click: () => {
        this.downloadList();
      },
    },
  ];

  private info!: (message: string) => void;

  private async downloadList() {
    try {
      await api.donwnloadLending(
        sessionStore.getToken(),
        this.demoenvs[0].productId
      );
      this.success('CSVダウンロードに成功しました');
    } catch (e) {
      this.fail('CSVダウンロードに失敗しました error=[' + e + ']');
    }
  }
}
