import {
  DemoEnvironment,
  isDemoEnvironment,
  DemoLending,
  isDemoLending,
} from '@/domain/DemoEnv';
import { DemoEnvironmentApiInterface } from '../interfaces';
import * as demoApi from '@/api/demoenv';

export class DemoEnvironmentAPI implements DemoEnvironmentApiInterface {
  constructor(public getToken = () => '') {}
  addDemoLending(demoLending: DemoLending): Promise<DemoLending> {
    return demoApi.addDemoLending(this.getToken(), demoLending);
  }
  async describeEnvironment(demoEnvId: number): Promise<DemoEnvironment> {
    const ret = await demoApi.describeEnvironment(this.getToken(), demoEnvId);
    if (!isDemoEnvironment(ret))
      throw 'Received data is not type DemoEnvironment';
    if (ret.demoEnvId === 0)
      throw 'Environment id is invalid.  Some error must have happened on backend.';
    return ret;
  }
  async describeDemoLending(demoLendingId: number): Promise<DemoLending> {
    const ret = await demoApi.describeDemoLending(
      this.getToken(),
      demoLendingId
    );
    if (!isDemoLending(ret)) throw 'Received data is not type DemoLending';
    if (ret.demoLendingId === 0)
      throw 'Lending id is invalid. Some error must have happened on backend.';
    return ret;
  }
  updateEnvironments(environments: DemoEnvironment[]) {
    return demoApi.updateEnvironments(this.getToken(), environments);
  }
  updateEnvironmentStatus(
    demoEnvironmentId: number,
    status: string
  ): Promise<DemoEnvironment> {
    return demoApi.updateEnvironmentStatus(
      this.getToken(),
      demoEnvironmentId,
      status
    );
  }
  updateDemoLendings(demoLendings: DemoLending[]): Promise<void> {
    return demoApi.updateDemoLendings(this.getToken(), demoLendings);
  }
  updateLendingStatus(demoLending: DemoLending): Promise<DemoLending> {
    return demoApi.updateLendingStatus(this.getToken(), demoLending);
  }
}
