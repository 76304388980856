

















import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { SelfSetting } from '@/domain/SelfSetting';
import * as sessionStore from '@/stores/session';

@Component({
  computed: {
    ...mapGetters('selfSettingStore', ['selfSetting']),
  },
})
export default class RelatedApplicationLinks extends Vue {
  private selfSetting!: SelfSetting;
  private items: {
    id: string;
    label: string;
    url: string;
    visible: boolean;
  }[] = [];

  private get activeLinkNumber(): number {
    if (!this.items) {
      return 0;
    }
    return this.items.filter(item => item.visible).length;
  }

  private moveTo(url: string) {
    window.location.href = url;
  }

  private created() {
    this.items = [
      {
        id: 'statcenter',
        label: '統計情報',
        url: this.selfSetting.statcenterUrl,
        visible: this.selfSetting.displayStatcenter && this.authCloudTeam(),
      },
      {
        id: 'customerSuccess',
        label: 'カスタマーサクセス',
        url: this.selfSetting.customerSuccessUrl,
        visible:
          this.selfSetting.displayCustomerSuccess && this.authCloudTeam(),
      },
    ];
  }

  private authCloudTeam(): boolean {
    const account = sessionStore.getAccount();
    return account.departments.some(department => {
      return department.departmentCode === 'cloud-unit';
    });
  }
}
