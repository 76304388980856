import { SpottyAccount } from '@/domain/SpottyContract/SpottyAccount';

export interface Plan {
  planId: number;
  planData: string;
}

export interface SpottyNewBasicForm {
  endUserId: string;
  salesAgentId: string;
  plan: Plan;
  url: string;
  siteId: string;
  startDate: string;
  billingStartDate: string;
  closingDay: number;
  initialVersion: string;
  initialAccount: SpottyAccount;
  salesMemo: string;

  endUserKeywords: string;
  salesAgentKeywords: string;
}

export const defaultSpottyNewBasicForm = {
  endUserId: '',
  salesAgentId: '0',
  plan: { planId: 0, planData: '' },
  url: 'https://',
  siteId: '',
  startDate: '',
  billingStartDate: '',
  closingDay: 99,
  initialVersion: '',
  initialAccount: new SpottyAccount(),
  salesMemo: '',

  endUserKeywords: '',
  salesAgentKeywords: '',
};
