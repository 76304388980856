import { post } from './io';
import { clean } from './helper_keywords';
import { Customer } from '@/domain/Customer';

const rootUrl = process.env.VUE_APP_URL + '/base';

export const list = async (
  token: string,
  limit: number,
  offset: number,
  keywords: string
): Promise<Customer[]> => {
  const url = rootUrl + '/unites/customer/list';
  const params = {
    limit,
    offset,
    keywords: clean(keywords),
  };
  const headers = { Authorization: 'Bearer ' + token };

  const ret = await post(url, params, { headers });
  return Promise.resolve(ret.customers);
};

export const detail = async (
  token: string,
  customerId: number
): Promise<Customer> => {
  const url = rootUrl + '/unites/customer/detail';
  const params = {
    customerId,
  };
  const headers = { Authorization: 'Bearer ' + token };

  const ret = await post(url, params, { headers });

  return Promise.resolve(
    new Customer(
      ret.customer.customerId,
      ret.customer.customerName,
      ret.customer.customerAlias
    )
  );
};

export const add = async (
  token: string,
  customer: Customer
): Promise<Customer> => {
  const url = rootUrl + '/unites/customer/add';
  const params = {
    customer,
  };
  const headers = { Authorization: 'Bearer ' + token };
  const ret = await post(url, params, { headers });
  return Promise.resolve(ret.customer);
};

export const update = async (
  token: string,
  customer: Customer
): Promise<Customer> => {
  const url = rootUrl + '/unites/customer/update';
  const params = {
    customer,
  };
  const headers = { Authorization: 'Bearer ' + token };
  const ret = await post(url, params, { headers });
  return Promise.resolve(ret.customer);
};

export const remove = async (
  token: string,
  customerId: number
): Promise<void> => {
  const url = rootUrl + '/unites/customer/delete';
  const params = {
    customerId,
  };
  const headers = { Authorization: 'Bearer ' + token };
  await post(url, params, { headers });
  return Promise.resolve();
};
