




















































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import { mapActions } from 'vuex';
import DatePicker from '@/components/molecules/DatePicker.vue';
import ContractCalendar from '@/components/dashboard/ContractCalendar.vue';
import Help from '@/components/billing/Help.vue';
import Navigation from '@/components/Navigation.vue';
import * as platonAPI from '@/api/platon.ts';
import * as libraAPI from '@/api/libra.ts';
import * as spottyAPI from '@/api/spotty.ts';
import * as sessionStore from '@/stores/session';
import dayjs from 'dayjs';
import { PlatonContract } from '@/domain/PlatonContract/PlatonContract';
import { LibraContract } from '@/domain/LibraContract/LibraContract';
import { SpottyContract } from '@/domain/SpottyContract/SpottyContract';
import {
  UpcomingTable,
  ContractData,
  UpcomingTableHeader,
} from '@/domain/DashBoard/table';
import { weekday, calenderTypes } from '@/domain/DashBoard/Calender';
import { Event, dateString } from '@/domain/DashBoard/Calender';

@Component({
  components: {
    DatePicker,
    Help,
    Navigation,
    ContractCalendar,
  },
  methods: mapActions('alertArea', ['success', 'fail']),
})
export default class HomeDashboard extends Vue {
  /**
   * プロパティ
   */
  private helpDialogShow = false;
  private serviceName = '';
  private networkBegin: string = dayjs().format('YYYY-MM-01');
  private networkEnd: string = dayjs()
    .add(1, 'month')
    .subtract(dayjs().date(), 'day')
    .format('YYYY-MM-DD');
  private uniqueLoginYear: number = dayjs().year();
  private uniqueLoginMonth: number = dayjs().month() + 1;
  private networkUsageTableFlag = false;
  private networkUsageHeader!: any[];
  private platonNetworkUsage!: any;
  private platonNetworkUsageSeries: any[] = [];

  private platonTableHeader!: any[];
  private platonContracts: PlatonContract[] = [];

  private libraTableHeader!: any[];
  private libraContracts: LibraContract[] = [];

  private spottyTableHeader!: any[];
  private spottyContracts: SpottyContract[] = [];

  private upComingTableHeader!: any[];
  private upComingTable: UpcomingTable = new UpcomingTable([], []);

  private optionsTypes: any = {};

  private calendarSetting: any = {
    date1: '',
    date2: '',
    year1: 2020,
    month1: 1,
    year2: 2020,
    month2: 1,
    initialDate1: '',
    initialDate2: '',
  };

  private get events() {
    const events: Event[] = [];
    this.platonContracts.map(c => {
      events.push(
        new Event(
          'start',
          c.endUserName,
          c.startDate.substr(0, 10),
          this.productStyle('platon').color,
          'Platon',
          'otegaru',
          c.platonId,
          c
        )
      );
      if (!c.endDate) return;
      events.push(
        new Event(
          'expired',
          c.endUserName,
          c.endDate.substr(0, 10),
          this.productStyle('platon').color,
          'Platon',
          'otegaru',
          c.platonId,
          c
        )
      );
    });
    this.libraContracts.map(c => {
      events.push(
        new Event(
          'start',
          c.endUserName,
          c.startDate.substr(0, 10),
          this.productStyle('libra').color,
          'Libra',
          'otegaru',
          c.libraId,
          c
        )
      );
      if (!c.endDate) return;
      events.push(
        new Event(
          'expired',
          c.endUserName,
          c.endDate.substr(0, 10),
          this.productStyle('libra').color,
          'Libra',
          'otegaru',
          c.libraId,
          c
        )
      );
    });
    this.spottyContracts.map(c => {
      events.push(
        new Event(
          'start',
          c.endUserName,
          c.startDate.substr(0, 10),
          this.productStyle('spotty').color,
          'Spotty',
          '',
          c.spottyId,
          c
        )
      );
      if (!c.endDate) return;
      events.push(
        new Event(
          'expired',
          c.endUserName,
          c.endDate.substr(0, 10),
          this.productStyle('spotty').color,
          'Spotty',
          '',
          c.spottyId,
          c
        )
      );
    });
    const datesEvents = {} as any;
    events.map(e => {
      if (!datesEvents[e.start]) datesEvents[e.start] = [];
      datesEvents[e.start].push(e);
    });
    return datesEvents;
  }
  private get upComingContracts() {
    const now = new Date();
    const contracts: ContractData[] = [];
    this.platonContracts.map(c => {
      const dt = new Date(c.startDate);
      if (dt >= now)
        contracts.push(new ContractData('platon', 'otegaru', c.platonId, c));
    });
    this.libraContracts.map(c => {
      const dt = new Date(c.startDate);
      if (dt >= now)
        contracts.push(new ContractData('libra', 'otegaru', c.libraId, c));
    });
    this.spottyContracts.map(c => {
      const dt = new Date(c.startDate);
      if (dt >= now)
        contracts.push(new ContractData('spotty', '', c.spottyId, c));
    });
    return contracts;
  }
  private rnd(a: any, b: any) {
    return Math.floor((b - a + 1) * Math.random()) + a;
  }
  private get countNewContract() {
    return (product: string, division: string) => {
      const now = new Date();
      const oneMonthAgo = new Date();
      oneMonthAgo.setMonth(now.getMonth() - 1);
      if (product == 'platon' && division == 'otegaru') {
        const counts = this.platonContracts.filter(c => {
          const dt = new Date(c.startDate);
          return dt >= oneMonthAgo && dt <= now;
        });
        return counts.length;
      } else if (product == 'libra' && division == 'otegaru') {
        const counts = this.libraContracts.filter(c => {
          const dt = new Date(c.startDate);
          return dt >= oneMonthAgo && dt <= now;
        });
        return counts.length;
      } else if (product == 'spotty') {
        const counts = this.spottyContracts.filter(c => {
          const dt = new Date(c.startDate);
          return dt >= oneMonthAgo && dt <= now;
        });
        return counts.length;
      }
      return 0;
    };
  }
  private get countCanceledContract() {
    return (product: string, division: string) => {
      const now = new Date();
      const oneMonthAgo = new Date();
      oneMonthAgo.setMonth(now.getMonth() - 1);
      if (product == 'platon' && division == 'otegaru') {
        const counts = this.platonContracts.filter(c => {
          if (!c.endDate) return false;
          const dt = new Date(c.endDate);
          return dt >= oneMonthAgo && dt <= now;
        });
        return counts.length;
      }
      if (product == 'libra' && division == 'otegaru') {
        const counts = this.libraContracts.filter(c => {
          if (!c.endDate) return false;
          const dt = new Date(c.endDate);
          return dt >= oneMonthAgo && dt <= now;
        });
        return counts.length;
      }
      if (product == 'spotty') {
        const counts = this.spottyContracts.filter(c => {
          if (!c.endDate) return false;
          const dt = new Date(c.endDate);
          return dt >= oneMonthAgo && dt <= now;
        });
        return counts.length;
      }
    };
  }
  private get productStyle() {
    return (product: string) => {
      if (product == 'platon') {
        return { color: 'primary' };
      }
      if (product == 'libra') {
        return { color: 'red lighten-1 white--text' };
      }
      if (product == 'spotty') {
        return { color: 'success' };
      }
      return {};
    };
  }
  private calenderValue: any = null;
  private weekday = weekday;
  private calenderType = 'month';
  private calenderTypes = calenderTypes;
  private success!: (message: string) => void;
  private fail!: (message: string) => void;

  private async created() {
    const thisMonth = new Date();
    const nextMonth = new Date();
    nextMonth.setMonth(nextMonth.getMonth() + 1);
    this.calendarSetting.date1 = dateString(thisMonth, 'yyyy-MM-dd');
    this.calendarSetting.year1 = thisMonth.getFullYear();
    this.calendarSetting.month1 = thisMonth.getMonth();
    this.calendarSetting.date2 = dateString(nextMonth, 'yyyy-MM-dd');
    this.calendarSetting.year2 = nextMonth.getFullYear();
    this.calendarSetting.month2 = nextMonth.getMonth() + 1;
    this.calendarSetting.initialDate1 = dateString(thisMonth, 'yyyy年M月');
    this.calendarSetting.initialDate2 = dateString(nextMonth, 'yyyy年M月');
    this.networkUsageTableFlag = true;
    this.networkUsageHeader = [
      {
        text: '顧客名',
        align: 'start',
        sortable: false,
        value: 'host',
      },
      {
        text: '年',
        align: 'start',
        sortable: false,
        value: 'year',
      },
      {
        text: '月',
        align: 'start',
        sortable: false,
        value: 'month',
      },
      {
        text: '流量(byte)',
        align: 'start',
        sortable: false,
        value: 'value',
      },
    ];
    this.platonTableHeader = [
      { text: 'PlatonID', value: 'platonId' },
      { text: 'ユーザー名', value: 'endUserName' },
      { text: 'URL', value: 'url' },
      { text: '開始', value: 'startDate' },
      { text: '終了', value: 'endDate' },
    ];
    this.platonContracts.length = 0;
    const platons = await platonAPI.listOtegaru(
      sessionStore.getToken(),
      0,
      0,
      '',
      'all'
    );
    platons.forEach(contract => {
      this.platonContracts.push(contract);
    });
    this.libraTableHeader = [
      { text: 'PlatonID', value: 'platonId' },
      { text: 'ユーザー名', value: 'endUserName' },
      { text: 'URL', value: 'url' },
      { text: '開始', value: 'startDate' },
      { text: '終了', value: 'endDate' },
    ];
    this.libraContracts.length = 0;
    const libras = await libraAPI.listOtegaru(
      sessionStore.getToken(),
      0,
      0,
      '',
      'all'
    );
    libras.forEach(contract => {
      this.libraContracts.push(contract);
    });
    this.spottyTableHeader = [
      { text: 'PlatonID', value: 'platonId' },
      { text: 'ユーザー名', value: 'endUserName' },
      { text: 'URL', value: 'url' },
      { text: '開始', value: 'startDate' },
      { text: '終了', value: 'endDate' },
    ];
    this.spottyContracts.length = 0;
    const spotties = await spottyAPI.list(
      sessionStore.getToken(),
      0,
      0,
      '',
      'all'
    );
    spotties.forEach(contract => {
      this.spottyContracts.push(contract);
    });

    this.upComingTable = new UpcomingTable(
      UpcomingTableHeader,
      this.upComingContracts
    );
  }
  /**
   * 操作
   */
  private move(direction: string) {
    const calendar1 = this.$refs['calendar1'] as any;
    const calendar2 = this.$refs['calendar2'] as any;
    calendar1.move(direction);
    calendar2.move(direction);
  }
  private customers(data: any) {
    const customer: any = {};
    // eslint-disable-next-line
    data.map((d: any, idx: number) => {
      customer[d.host] = d.host;
    });
    return customer;
  }
  private getEventColor(event: any) {
    return event.color;
  }
  private toggleHelpDialog() {
    this.helpDialogShow = !this.helpDialogShow;
  }
  private changeService(serviceName: string) {
    this.serviceName = serviceName;
  }
}
