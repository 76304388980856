import { OptionsApiInterface } from '../interfaces';
import { OptionData } from '@/components/options/OptionData';

import * as optionsApi from '@/api/options';

export class OptionsAPI implements OptionsApiInterface {
  constructor(public getToken = () => '') {}
  listOtegaruPlatonOptionsTypes(version: string): Promise<OptionData[]> {
    return optionsApi.listOteagruPlatonOptionsTypes(this.getToken(), version);
  }
  listOtegaruLibraOptionsTypes(version: string): Promise<OptionData[]> {
    return optionsApi.listOteagruLibraOptionsTypes(this.getToken(), version);
  }
  listSpottyOptionsTypes(version: string): Promise<OptionData[]> {
    return optionsApi.listSpottyOptionsTypes(this.getToken(), version);
  }
}
