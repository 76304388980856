



























































































































import vue from 'vue';
import { Component, Vue, Provide } from 'vue-property-decorator';
import ActionMenu from '@/components/molecules/ActionMenu.vue';
import DatePicker from '@/components/molecules/DatePicker.vue';
import LendingReservation from '@/components/demoenv/LendingReservation.vue';
import LendingStatusHelp from '@/components/demoenv/LendingStatusHelp.vue';
import LendingStatusChangeDialog from '@/components/demoenv/LendingStatusChangeDialog.vue';
import Dashboard from './Dashboard.vue';
import DemoEnvironmentList from './DemoEnvironmentList.vue';
import DemoLendingList from './DemoLendingList.vue';
import DemoGanttChart from './DemoGanttChart.vue';
import {
  AlertInterface,
  DemoEnvironmentApiInterface,
  NewDemoLendingInterface,
} from '@/views/demoenv/interfaces';
import {
  AlertAdaptor,
  CustomerAPI,
  OptionsAPI,
  DemoEnvironmentAPI,
  NewDemoLendingAdaptor,
} from './adaptors';
import {
  DemoEnvironmentListInterface,
  DemoEnvironmentListProps,
} from './components/DemoEnvironmentList/Property';
import { Account } from '@/domain/Account';
import DemoEnvironmentCard from '@/components/demoenv/DemoEnvironmentCard.vue';
import LendingCard from '@/components/demoenv/LendingCard.vue';
import { mapActions, mapState } from 'vuex';
import * as sessionStore from '@/stores/session';
import { PLATON, LIBRA, SPOTTY } from '@/domain/Product';
import { PRODUCTS_STYLES, getProductStyle } from '@/domain/DemoEnv/Product';
import { DemoEnvironment, DemoLending } from '@/domain/DemoEnv';
import NewDemoLending from './components/NewDemoLending/NewDemoLending.vue';

@Component({
  components: {
    ActionMenu,
    DatePicker,
    Dashboard,
    DemoEnvironmentList,
    DemoLendingList,
    DemoGanttChart,
    DemoEnvironmentCard,
    LendingCard,
    NewDemoLending,
    LendingStatusChangeDialog,
    LendingReservation,
    LendingStatusHelp,
  },
  computed: {
    ...mapState('demoManagementBoard', {
      demoenvs: 'demoenvs',
      demoLendings: 'demoLendings',
      editMode: 'edit',
    }),
    ...mapState('accountInfo', ['account']),
    ...mapState('demoEnvironmentList', {
      environmentsSearchParamas: 'searchParams',
    }),
    ...mapState('demoLendingList', {
      lendingsSearchParamas: 'searchParams',
    }),
    product: {
      get() {
        return this.$store.getters['demoManagementBoard/product'];
      },
      set(val: boolean) {
        this.$store.dispatch('demoManagementBoard/changeProduct', val);
      },
    },
    newDemoLendingDialog: {
      get() {
        return this.$store.getters['demoLendingNew/dialog'];
      },
      set(val: boolean) {
        this.$store.dispatch('demoLendingNew/switchDialog', val);
      },
    },
    reservationDemoLendingDialog: {
      get() {
        return this.$store.getters['demoLendingReservation/dialog'];
      },
      set(val: boolean) {
        this.$store.dispatch('demoLendingReservation/switchDialog', val);
      },
    },
    lendingStatusHelper: {
      get() {
        return this.$store.getters['demoManagementBoard/lendingStatusHelper'];
      },
      set(val: boolean) {
        this.$store.dispatch(
          'demoManagementBoard/displayLendingStatusHelper',
          val
        );
      },
    },
    ...mapState('demoLendingNew', { newDemoLending: 'demoLending' }),
  },
  methods: {
    ...mapActions('alertArea', ['success', 'fail', 'warning']),
    ...mapActions('demoManagementBoard', [
      'changeProduct',
      'displayLendingStatusHelper',
      'listDemoEnvironments',
      'listDemoLendings',
    ]),

    ...mapActions('demoLendingNew', {
      addDemoLending: 'submit',
      addLendingDialogEnd: 'dialogEnd',
    }),
  },
})
export default class DemoEnv extends Vue {
  private product!: number; // default=platon
  private productStyles = PRODUCTS_STYLES;
  private get getProductStyle() {
    return getProductStyle;
  }
  private demoenvs!: DemoEnvironment[];
  private demoLendings!: DemoLending[];
  private environmentsSearchParamas!: string;
  private lendingsSearchParamas!: string;
  private tabTarget = 'dashboard';
  private editMode!: boolean;
  private account!: Account;

  // 新規貸出ダイアログ
  private newDemoLending!: DemoLending;
  private newDemoLendingAdaptor!: NewDemoLendingInterface;
  private addDemoLending!: (
    client: DemoEnvironmentApiInterface
  ) => Promise<DemoLending>;
  private addLendingDialogEnd!: () => {};

  private success!: (message: string) => void;
  private fail!: (message: string) => void;
  private warning!: (message: string) => void;
  private mounted() {
    window.addEventListener('popstate', this.init);
  }

  @Provide()
  private myAccount: Account = sessionStore.getAccount();
  @Provide()
  get alert(): AlertInterface {
    return new AlertAdaptor(this.success, this.fail, this.warning);
  }
  @Provide()
  customerApiClient = new CustomerAPI(sessionStore.getToken);
  @Provide()
  optionsApiClient = new OptionsAPI(sessionStore.getToken);
  @Provide()
  demoEnvApiClient = new DemoEnvironmentAPI(sessionStore.getToken);
  @Provide('demoEnvironmentListProps')
  private demoEnvironmentListProps = vue.observable<
    DemoEnvironmentListInterface
  >(new DemoEnvironmentListProps().init());

  private created() {
    // 新規貸出コンポーネント
    this.newDemoLendingAdaptor = Vue.observable<NewDemoLendingInterface>(
      new NewDemoLendingAdaptor(this.addDemoLending, this.addLendingDialogEnd)
    );

    this.init();
  }
  private init() {
    const product = this.$route.params.product;
    if (product == 'platon') {
      this.product = PLATON;
    } else if (product == 'libra') {
      this.product = LIBRA;
    } else if (product == 'spotty') {
      this.product = SPOTTY;
    } else {
      this.product = this.productStyles[0].value;
    }
    const tabTarget = this.$route.params.tabTarget;
    if (tabTarget) this.tabTarget = tabTarget;

    // デモ環境一覧の取得
    try {
      this.initDemoEnvironmentList();
    } catch {
      this.fail('デモ環境一覧の取得に失敗しました。');
    }

    // 貸出情報一覧の取得
    try {
      this.initDemoLendingList();
    } catch {
      this.fail('貸出情報一覧の取得に失敗しました。');
    }
  }
  private changeProduct!: (product: string) => {};
  private changeProductEvent(product: string) {
    // 製品変更イベント
    this.demoEnvironmentListProps.init();
    this.changeProduct(product);
    this.updateHistory('');
    this.contentReload();
  }
  private changeTab() {
    let searchParams = '';
    if (this.tabTarget == 'environments') {
      searchParams = this.environmentsSearchParamas;
    } else if (this.tabTarget == 'lendings') {
      searchParams = this.lendingsSearchParamas;
    }
    this.updateHistory(searchParams);
    this.$forceUpdate();
  }
  private updateHistory(searchParams: string) {
    if (searchParams) searchParams = '?' + searchParams;
    const url =
      '/demoenv/' +
      getProductStyle(this.product).name +
      '/' +
      this.tabTarget +
      searchParams;
    history.pushState(
      {
        product: getProductStyle(this.product).name,
        tabTarget: this.tabTarget,
      },
      'home',
      url
    );
  }
  private openLink(url: string) {
    window.open(url);
  }
  private contentReload() {
    this.initDemoEnvironmentList();
    this.initDemoLendingList();
    this.$forceUpdate();
  }
  private listDemoEnvironments!: (token: string) => {};
  private initDemoEnvironmentList() {
    this.listDemoEnvironments(sessionStore.getToken());
  }
  private listDemoLendings!: (token: string) => {};
  private initDemoLendingList() {
    this.listDemoLendings(sessionStore.getToken());
  }
  // eslint-disable-next-line
  private emitDelete(name: string) {
    this.contentReload();
  }
  // eslint-disable-next-line
  private emitUpdate(name: string) {
    this.contentReload();
  }
  private displayLendingStatusHelper!: (value: boolean) => {};
  private openLendingStatusHelper() {
    this.displayLendingStatusHelper(true);
  }
}
