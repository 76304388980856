

















































































































































































































import { Component, Vue } from 'vue-property-decorator';
import ActionMenu from '@/components/molecules/ActionMenu.vue';
import CustomerSearchSelector from '@/components/customer/CustomerSearchSelector.vue';
import LendingStatusHelp from '@/components/demoenv/LendingStatusHelp.vue';
import DatePicker from '@/components/molecules/DatePicker.vue';
import * as dateHelper from '@/domain/DemoEnv/Date';
import { mapActions, mapState } from 'vuex';
import { getProductStyle } from '@/domain/DemoEnv/Product';
import { DemoEnvironment, DemoLending } from '@/domain/DemoEnv/Types';
import * as envstatus from '@/domain/DemoEnv/EnvironmentStatus';
import * as lendstatus from '@/domain/DemoEnv/LendingStatus';

@Component({
  components: {
    ActionMenu,
    CustomerSearchSelector,
    DatePicker,
    LendingStatusHelp,
  },
  computed: {
    ...mapState('demoManagementBoard', {
      product: 'product',
      demoenvs: 'demoenvs',
      demoLendings: 'demoLendings',
      editMode: 'edit',
    }),
    ...mapState('accountInfo', ['account']),
  },
  methods: mapActions('alertArea', ['success', 'fail', 'warning']),
})
export default class Dashboard extends Vue {
  private product!: number;
  private get getProductStyle() {
    return getProductStyle;
  }
  private demoenvs!: DemoEnvironment[];
  private demoLendings!: DemoLending[];
  private howToUse = {
    imgs: {
      reservation: require('@/assets/demoenv/reservation.png'),
      assign: require('@/assets/demoenv/assign.png'),
      reservedList: require('@/assets/demoenv/reserved_list.png'),
      overallAssigned: require('@/assets/demoenv/overall_assinged.png'),
    },
  };
  private displayDateRange = 10;
  private get pastDates(): any[] {
    const today = new Date();
    const dates = [] as any[];
    for (let i = 0; i < this.displayDateRange; i++) {
      dates.push({
        year: today.getFullYear(),
        month: today.getMonth() + 1,
        day: today.getDate(),
      });
      today.setDate(today.getDate() - 1);
    }
    return dates;
  }
  private get recentActivities(): any[] {
    const activities = [] as any[];
    this.pastDates.map(date => {
      const activity = {
        date,
        contents: [] as any[],
      };
      this.demoenvs.map(env => {
        if (
          env.updatedAt.substr(0, 10) ==
          dateHelper.dateNumberFormat(date.year, date.month, date.day)
        ) {
          activity.contents.push({
            type: 'envStatusUpdate',
            title: '環境ステータスが更新されました。',
            info: '環境名: ' + env.name,
            value: envstatus.getEnvironmentStatusSetting(env.environmentStatus)
              .label,
          });
        }
      });
      if (activity.contents.length == 0) return;
      activities.push(activity);
    });
    return activities;
  }
  private get demoLendingsExpiredInWeek(): any {
    const ret = [] as any;
    const today = new Date();

    const targetLendings = this.demoLendings.filter(l => {
      if (l.lendingStatus != lendstatus.LENDING || !l.endDate) return;
      const weekbefore = new Date(l.endDate);
      weekbefore.setDate(weekbefore.getDate() - 8);
      return today > weekbefore;
    });

    targetLendings.map(lending => {
      if (!lending.endDate) return;
      const endDate = new Date(lending.endDate);
      const diffTime = endDate.getTime() - today.getTime();
      const diffDay = Math.floor(diffTime / (1000 * 60 * 60 * 24));
      ret.push({
        icon:
          endDate < today
            ? 'mdi-fire'
            : 'mdi-numeric-' + String(diffDay + 1) + '-box',
        demoLending: lending,
      });
    });
    return ret;
  }
  private get demoLendingsInUse(): any[] {
    return this.demoLendings.filter(l => {
      return l.assigned;
    });
  }
  private environmentItems = envstatus.ENVIRONMENT_STATUS_ITEMS;
  private get countEnvStatus() {
    return (status: string): number => {
      return this.environmentList.filter(env => {
        return env.environmentStatus == status;
      }).length;
    };
  }
  private get environmentList(): DemoEnvironment[] {
    if (this.demoenvs.length == 0) return [] as DemoEnvironment[];
    return this.demoenvs.filter(env => {
      return env.productId == this.product;
    });
  }
  private get envStatusSetting() {
    return envstatus.getEnvironmentStatusSetting;
  }
  private get dateDisplaySlash(): (dateString: string) => string {
    return dateHelper.dateDisplay;
  }
  private openEnvironmentList(url: string) {
    window.open(url);
  }
  private openLendingOnEnvironment(demoEnvironmentId: number) {
    const url = './environments?demoEnvId=' + String(demoEnvironmentId);
    window.open(url);
  }
}
