




























































import { Component, Vue } from 'vue-property-decorator';
import ActionMenu from '@/components/molecules/ActionMenu.vue';
import Navigation from '@/components/Navigation.vue';
import { mapActions } from 'vuex';
import * as api from '@/api/platon.ts';
import { PlatonDivision } from '@/domain/PlatonContract/Types';
import { PlatonVersion } from '@/domain/PlatonContract/PlatonVersion';
import { PlatonInstaller } from '@/domain/PlatonContract/PlatonInstaller';
import { VersionUpForm } from './PlatonVersionUpForm';
import * as sessionStore from '@/stores/session';

@Component({
  components: {
    ActionMenu,
    Navigation,
  },
  methods: mapActions('alertArea', ['success', 'fail']),
})
export default class PlatonVersionUp extends Vue {
  private actionMenu = [
    {
      icon: 'arrow_back',
      text: '一覧に戻る',
      color: '',
      click: () => {
        this.returnList();
      },
    },
  ];

  private vm = new VersionUpForm();

  private success!: (message: string) => void;
  private fail!: (message: string) => void;

  private get PlatonDivision() {
    return PlatonDivision;
  }

  private get division(): number {
    return parseInt(this.$route.params.division, 10);
  }

  private get chipColor(): string {
    if (this.division === PlatonDivision.Otegaru) {
      return 'blue white--text';
    }
    return 'blue white--text darken-4';
  }

  private get contractLabel(): string {
    if (this.division === PlatonDivision.Otegaru) {
      return 'お手軽Platon';
    }
    return 'オンプレPlaton';
  }

  private async created() {
    await this.reload();
  }

  private async reload() {
    const id = parseInt(this.$route.params.id, 10);

    if (this.division === PlatonDivision.Otegaru) {
      const model = await api.detailOtegaru(sessionStore.getToken(), id);
      this.vm = new VersionUpForm(
        model.platonId,
        model.plan.division,
        model.endUser.customerName,
        model.version.versionLabel,
        model.version.updateDate.substr(0, 10)
      );
    }
    if (this.division === PlatonDivision.OnPremise) {
      const model = await api.detailOnPremise(sessionStore.getToken(), id);
      this.vm = new VersionUpForm(
        model.platonId,
        model.plan.division,
        model.endUser.customerName,

        model.version.versionLabel,
        model.version.updateDate.substr(0, 10),
        '',
        '',
        model.installer.versionLabel
      );
    }
  }
  private async submitVersion() {
    const model = new PlatonVersion(
      this.vm.updateVersion,
      this.vm.updateDate + 'T00:00:00Z'
    );
    try {
      await api.updateVersion(sessionStore.getToken(), this.vm.platonId, model);
      this.success('バージョンを更新しました');
    } catch {
      this.fail('バージョンの更新に失敗しました');
    }

    await this.reload();
  }

  private async submitInstaller() {
    const model = new PlatonInstaller(this.vm.updateInstaller);
    try {
      await api.updateInstaller(
        sessionStore.getToken(),
        this.vm.platonId,
        model
      );
      this.success('提供インストーラーバージョンを更新しました');
    } catch {
      this.fail('提供インストーラーバージョンの変更に更新しました');
    }

    await this.reload();
  }

  private returnList() {
    if (this.division === PlatonDivision.Otegaru) {
      this.$router.push('../../otegaru/list');
    }
    if (this.division === PlatonDivision.OnPremise) {
      this.$router.push('../../onpremise/list');
    }
  }
}
