











































import { Component, Vue } from 'vue-property-decorator';
import ActionMenu from '@/components/molecules/ActionMenu.vue';
import Navigation from '@/components/Navigation.vue';
import { mapState, mapActions, mapGetters } from 'vuex';
import { SelfSetting } from '@/domain/SelfSetting';
import { SpottyOption } from '../../domain/SpottyContract/SpottyOption';
import { SpottyContract } from '../../domain/SpottyContract/SpottyContract';
import { SpottyNewOptionsForm } from './SpottyNewOptions/SpottyNewOptionsForm';
import * as sessionStore from '@/stores/session';
import * as optionsApi from '@/api/options';
import { OptionData } from '@/components/options/OptionData';
import {
  newOptionTypesVersions,
  maxVerions,
} from '@/components/options/OptionsTypesHelper';
import OptionCardPlane from '@/components/options/OptionCardPlane.vue';

@Component({
  components: {
    ActionMenu,
    Navigation,
    OptionCardPlane,
  },
  computed: {
    ...mapGetters('selfSettingStore', ['selfSetting']),
    ...mapState('spottyNew', ['model']),
    ...mapGetters('spottyNew', ['contractLabel']),
  },
  methods: {
    ...mapActions('spottyNew', ['updateOptions', 'submit']),
    ...mapActions('alertArea', ['success', 'fail']),
  },
})
export default class SpottyNewOptions extends Vue {
  /**
   * mapping
   */
  private selfSetting!: SelfSetting;
  private model!: SpottyContract;
  private updateOptions!: (vm: SpottyNewOptionsForm) => void;
  private submit!: (token: string) => void;
  private contractLabel!: () => string;
  private success!: (message: string) => void;
  private fail!: (message: string) => void;

  /**
   * プロパティ
   */
  private vm = new SpottyNewOptionsForm();
  private processing = false;
  private options: OptionData[] = [];

  private formVersions: { [key: string]: string }[] = [];
  private targetVersion = '';

  private actionMenu = [
    {
      type: 'button',
      icon: 'arrow_back',
      text: '契約基本情報入力に戻る',
      color: '',
      click: () => {
        this.clickReturn();
      },
    },
    {
      type: 'submit',
      icon: 'arrow_forward',
      text: '以上の内容で契約を追加する',
      color: 'primary',
      click: () => {
        // only submit
      },
    },
  ];
  private get optionsTypes() {
    this.sortOptions();
    return this.options;
  }

  private sortOptions() {
    let i = 0;
    let j = 1;
    let preRowNum = 0;
    let nowRowNum = 0;
    for (i = 0; i < this.options.length; i++) {
      for (j = this.options.length - 1; i < j; j--) {
        preRowNum = this.options[j - 1].template.rowNumber;
        nowRowNum = this.options[j].template.rowNumber;
        if (preRowNum > nowRowNum) {
          const deleteList = this.options.splice(j, 1);
          this.options.splice(j - 1, 0, deleteList[0]);
        }
      }
    }
  }

  /**
   * ライフサイクル
   */
  private async created() {
    let optionsTypesList: OptionData[] = [];
    optionsTypesList = await optionsApi.listSpottyOptionsTypes(
      sessionStore.getToken(),
      ''
    );
    this.options.length = 0;
    optionsTypesList.map(ot => {
      this.options.push(ot);
    });
    this.sortOptions();

    this.formVersions = newOptionTypesVersions(this.options);
    this.targetVersion = maxVerions(this.formVersions);

    this.updateVM();
    this.$forceUpdate();
  }

  private update() {
    this.vm.optionData.map(opt => {
      opt.optionVersion = this.targetVersion;
    });
    this.updateOptions(this.vm);
  }

  private updateVM() {
    // オプションバージョン上書き
    this.options = this.options.filter(opt => {
      return opt.version == this.targetVersion;
    });

    // マスタデータをオプション型に変換してフォームオブジェクトに埋め込む
    this.vm = new SpottyNewOptionsForm();
    const optionFormMode = 'new';
    this.options.forEach(opt => {
      if (!opt.template.items) {
        this.vm.optionData.push(
          new SpottyOption(
            optionFormMode,
            0,
            0,
            opt.typeId,
            opt.version,
            '',
            null,
            {}
          )
        );
        return;
      }
      const datamap: { [key: string]: string } = {};
      opt.template.items.forEach(item => {
        datamap[item.name] = '';
      });

      // 申込書からの取込みの場合など、オプションの値が入っている場合
      const initialOption = this.model.options.filter(modelOption => {
        return modelOption.optionsTypeId == opt.typeId;
      });
      if (initialOption.length != 0) {
        datamap.using = 'true';
        Object.keys(initialOption[0].data).map(key => {
          datamap[key] = initialOption[0].data[key];
        });
        this.vm.optionData.push(
          new SpottyOption(
            optionFormMode,
            0,
            0,
            initialOption[0].optionsTypeId,
            this.targetVersion,
            '',
            null,
            datamap
          )
        );
        return;
      }
      this.vm.optionData.push(
        new SpottyOption(
          optionFormMode,
          0,
          0,
          opt.typeId,
          opt.version,
          '',
          null,
          datamap
        )
      );
    });
  }
  private async loadOptionsTypes() {
    const optionsTypesList = await optionsApi.listSpottyOptionsTypes(
      sessionStore.getToken(),
      this.targetVersion
    );
    this.options.length = 0;
    optionsTypesList.map(ot => {
      this.options.push(ot);
    });
    this.updateVM();
  }

  /**
   * 操作
   */
  private clickReturn() {
    this.$router.push('basic');
  }
  private removeOption() {
    return; // do nothing
  }
  private async submitForm() {
    if (this.processing) {
      return;
    }
    this.processing = true;

    try {
      await this.submit(sessionStore.getToken());
    } catch {
      this.fail('契約の追加に失敗しました');
      return;
    } finally {
      this.processing = false;
    }
    this.success('契約を追加しました');
    this.$router.push('../list');
  }
}
