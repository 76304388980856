import { ActionContext } from 'vuex';
import { AlertState } from '@/stores/types.ts';
import { Alert } from '@/domain/Alert';

const state: AlertState = {
  alerts: [],
};

const getters = {};

const actions = {
  success(context: ActionContext<Alert, {}>, message: string) {
    const alert = new Alert('success', message, true);
    context.commit('setAlert', alert);
  },
  fail(context: ActionContext<Alert, {}>, message: string) {
    const alert = new Alert('error', message, true, 10);
    context.commit('setAlert', alert);
  },
  warning(context: ActionContext<Alert, {}>, message: string) {
    const alert = new Alert('warning', message, true, 20);
    context.commit('setAlert', alert);
  },
  info(context: ActionContext<Alert, {}>, message: string) {
    const alert = new Alert('info', message, true, 10);
    context.commit('setAlert', alert);
  },
  clearAlert(context: ActionContext<Alert, {}>) {
    context.commit('clearAlert');
  },
};

const mutations = {
  setAlert(st: AlertState, alert: Alert) {
    st.alerts.push(alert);
    st.alerts = Object.assign([], st.alerts); // to redraw
    setTimeout(() => {
      st.alerts.shift();
      st.alerts = Object.assign([], st.alerts); // to redraw
    }, alert.secound * 1000);
  },

  clearAlert(st: AlertState) {
    st.alerts = [];
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
