
































import { Component, Prop, Vue } from 'vue-property-decorator';
import { ValidationProvider } from 'vee-validate';
import PlatonOptionEditor from '@/components/platon/PlatonOptionEditor.vue';
import LibraOptionEditor from '@/components/libra/LibraOptionEditor.vue';
import LendingStatusChip from '@/components/demoenv/LendingStatusChip.vue';
import CustomerSearchSelector from '@/components/customer/CustomerSearchSelector.vue';
import DatePicker from '@/components/molecules/DatePicker.vue';
import TextField from '@/components/molecules/TextField.vue';
import FormSection from './FormSection.vue';
import { dateObjFormat, RangeDate, dateDisplay } from '@/domain/DemoEnv/Date';

@Component({
  components: {
    ValidationProvider,
    CustomerSearchSelector,
    PlatonOptionEditor,
    LibraOptionEditor,
    LendingStatusChip,
    TextField,
    DatePicker,
    FormSection,
  },
})
export default class LendingDatePicker extends Vue {
  @Prop({ type: Object, required: true })
  private lendingDate!: RangeDate;
  @Prop({ type: Boolean, required: true })
  private editMode!: boolean;

  private get lendingDateDisplay(): string {
    return [
      dateDisplay(this.lendingDate.startDate),
      '～',
      this.lendingDate.endDate ? dateDisplay(this.lendingDate.endDate) : '未定',
    ].join(' ');
  }
  private get dateDisplaySlash(): (dateString: string) => string {
    return dateDisplay;
  }

  private get daysDeltaEqual() {
    return (): boolean => {
      const date = new Date(this.lendingDate.startDate);
      const after = new Date(this.lendingDate.startDate);
      after.setMonth(date.getMonth() + 1);
      if (date.getDate() > after.getDate()) {
        after.setDate(0);
      }
      return this.lendingDate.endDate == dateObjFormat(after);
    };
  }

  private setEndDate() {
    const date = new Date(this.lendingDate.startDate);
    const after = new Date(this.lendingDate.startDate);
    after.setMonth(date.getMonth() + 1);
    if (date.getDate() > after.getDate()) {
      after.setDate(0);
    }
    this.lendingDate.endDate = dateObjFormat(after);
    this.$emit('update', null);
  }
}
