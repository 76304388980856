







































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { DemoEnvironment, DemoLending } from '@/domain/DemoEnv/Types';
import {
  GanttChartSetting,
  DateProps,
  GanttEventMenu,
  GanttEventMenuItem,
  LendingEvent,
} from './Types';

@Component({
  components: {},
})
export default class GanttCalendar extends Vue {
  @Prop({ type: Object, required: true })
  private setting!: GanttChartSetting;

  @Prop({ type: String, required: true })
  private width!: string;

  @Prop({ type: Array, required: true })
  private days!: DateProps[];

  @Prop({ type: Function, required: true })
  private makeLendingEvent!: (
    env: DemoEnvironment,
    year: number,
    month: number,
    day: number
  ) => LendingEvent[];

  @Prop({ type: Object, required: true })
  private selectedDemoLending!: DemoLending;

  @Prop({ type: Function, required: true })
  private eventClickEvent!: (demoLendingId: number) => any;

  @Prop({ type: Object, required: true })
  private eventMenu!: GanttEventMenu;

  @Prop({ type: Function, required: true })
  private eventMenuFunction!: (eventItem: GanttEventMenuItem) => {};

  @Prop({ type: Array, required: true })
  private environmentList!: DemoEnvironment[];

  @Prop({ type: Function, required: true })
  private mouseLeaveGanttEvent!: (event: Event, env: DemoEnvironment) => {};

  private get events() {
    return this.makeLendingEvent;
  }
  private get isSelectedDate() {
    return (date: any, environmentId: number) => {
      const selectedDate = this.eventMenu.selectedDate;
      return (
        this.eventMenu.selectedEnv.demoEnvId == environmentId &&
        date.year == selectedDate.year &&
        date.month == selectedDate.month &&
        date.day == selectedDate.day
      );
    };
  }

  private get eventRowNumber() {
    // イベントの行番号の取得
    return (envId: number, lendingId: number) => {
      if (!this.setting.rowNumber[envId]) return 0;
      if (!this.setting.rowNumber[envId][lendingId]) return 0;
      return this.setting.rowNumber[envId][lendingId];
    };
  }

  private eventClickDate(
    clickEvent: MouseEvent,
    env: DemoEnvironment,
    date: any
  ) {
    // メニュー表示の位置
    const target = clickEvent.target as any;
    if (!target) return;

    // オフセットを設定(クリック位置に応じて、メニューが画面外に出ないように)
    let offsetTop = 0;
    let offsetLeft = 0;
    if (clickEvent.pageX > 900) {
      offsetLeft = -200;
    }
    if (clickEvent.pageY > 650) {
      offsetTop = -200;
    }

    this.eventMenu.style.top =
      clickEvent.pageY + offsetTop + this.setting.baseRowHeight + 'px';
    this.eventMenu.style.left = clickEvent.pageX + offsetLeft + 'px';

    this.eventMenu.selectedEnv = env;
    this.eventMenu.selectedDate = date;
    this.eventMenu.show = true;
  }
}
