import { OptionData } from './OptionData';

export function newOptionTypesVersions(options: OptionData[]) {
  const versionsDict: any = {};
  options.map(opt => {
    versionsDict[opt.version] = 1;
  });
  return Object.keys(versionsDict).map(v => {
    // v-selectのitemsとして使うのでlabel: valueの連想配列で返す
    return { label: v, value: v };
  });
}

export function maxVerions(versions: { [key: string]: string }[]) {
  let maxVerions = '';
  versions.map(v => {
    if (v.value > maxVerions) maxVerions = v.value;
  });
  return maxVerions;
}

export function maxOptionTypeID(options: OptionData[]): number {
  let maxId = 0;
  options.map(opt => {
    if (opt.typeId > maxId) maxId = opt.typeId;
  });
  return maxId;
}

export function checkDuplicateTypeId(
  targetId: number,
  options: OptionData[],
  exceptIdx: number
) {
  const result = options.map((opt, idx) => {
    if (idx == exceptIdx) return false;
    return opt.typeId == targetId;
  });
  return result.indexOf(true) == -1;
}

function validateJSON(json: any) {
  try {
    if (typeof json == 'string') {
      JSON.parse(json);
      return true;
    }
    if (typeof json == 'object') {
      JSON.parse(JSON.stringify(json));
      return true;
    }
  } catch {
    return false;
  }
  return true;
}

export function validateOptionsData(options: OptionData[]) {
  for (let i = 0; i < options.length; i++) {
    // JSONのフォーマット確認
    if (options[i].template.items) {
      options[i].template.items.map(item => {
        return validateJSON(item);
      });
    }
  }
}
