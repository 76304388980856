export const AVAILABLE = 'available';
export const LENDING = 'lending';
export const INITIALIZING = 'initializing';
export const INITIAL_STATE = 'initialstate';

export type EnvironmentStatus =
  | typeof AVAILABLE
  | typeof LENDING
  | typeof INITIALIZING
  | typeof INITIAL_STATE;

export const AVAILABLE_LABEL = 'フリー';
export const LENDING_LABEL = '貸出中';
export const INITIALIZING_LABEL = '準備中';
export const INITIAL_STATE_LABEL = '確認待ち'; // システム的には初期化状態だが、運用上は確認待ち状態

export const DEFAULT_ENVIRONMENT_STATUS = {
  value: '',
  label: '不明',
  color: 'primary',
};

export const ENVIRONMENT_STATUS_ITEMS = [
  {
    value: AVAILABLE,
    label: AVAILABLE_LABEL,
    color: 'success',
  },
  {
    value: LENDING,
    label: LENDING_LABEL,
    color: 'primary',
  },
  {
    value: INITIALIZING,
    label: INITIALIZING_LABEL,
    color: 'warning',
  },
  {
    value: INITIAL_STATE,
    label: INITIAL_STATE_LABEL,
    color: 'warning',
  },
];

export function getEnvironmentStatusSetting(status: string): any {
  const setting = ENVIRONMENT_STATUS_ITEMS.filter(item => {
    return item.value == status;
  });
  if (setting.length != 0) return setting[0];
  return DEFAULT_ENVIRONMENT_STATUS;
}
