



























import { Component, Prop, Vue } from 'vue-property-decorator';
import { DemoEnvironment } from '@/domain/DemoEnv/Types';
import * as envstatus from '@/domain/DemoEnv/EnvironmentStatus';

@Component
export default class EnvironmentStatusMenu extends Vue {
  @Prop({ type: Object, required: true })
  private demoenv!: DemoEnvironment;
  @Prop({ type: Array, required: false })
  private items!: { [key: string]: string }[];
  @Prop({ type: Boolean, required: false })
  private disabled!: boolean;
  @Prop({ type: Boolean, required: false })
  private editMode!: boolean;
  @Prop({ type: Function, required: true })
  private updateStatus!: (status: string) => void;

  // その他のプロパティ
  @Prop({ type: Boolean, required: false, default: false })
  private large!: boolean;
  @Prop({ type: Boolean, required: false, default: false })
  private small!: boolean;

  private get envStatusSetting() {
    return envstatus.getEnvironmentStatusSetting;
  }
}
