export class VersionUpForm {
  constructor(
    public spottyId = 0,
    public endUserName = '',

    public currentVersion = '',
    public lastUpdateDate = '',
    public updateVersion = '',
    public updateDate = ''
  ) {}
}
