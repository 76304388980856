


















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import DatePicker from '@/components/molecules/DatePicker.vue';
import OptionCardPlane from '@/components/options/OptionCardPlane.vue';
import { PlatonDivision } from '@/domain/PlatonContract/Types';
import { OptionData, OptionTemplate } from '@/components/options/OptionData';
import { newOptionTypesVersions } from '@/components/options/OptionsTypesHelper';
import { SelfSetting } from '@/domain/SelfSetting';
import { PlatonOption } from '@/domain/PlatonContract/PlatonOption';
import * as sessionStore from '@/stores/session';
import * as optionsApi from '@/api/options';
@Component({
  components: {
    DatePicker,
    OptionCardPlane,
  },
  computed: {
    ...mapGetters('selfSettingStore', ['selfSetting']),
  },
})
export default class PlatonOptionEditor extends Vue {
  private selfSetting!: SelfSetting;
  @Prop({ type: Array, required: true })
  private options!: PlatonOption[];
  @Prop({ type: Number, required: true })
  private division!: number;
  @Prop({ type: String, required: true })
  private siteId!: string;
  private optionsTypes: OptionData[] = [];
  private types: any[] = [];
  private optionTypeVersion = '';
  private optionTypeVersions!: { [key: string]: string }[];
  private selectingType = 0;
  @Prop({ type: Boolean, required: true })
  private optionsTypesLoadFlag!: boolean;
  @Prop({ type: Boolean, required: false })
  private disabled!: boolean;
  @Prop({ type: Boolean, required: false })
  private readonly!: boolean;

  @Watch('optionsTypesLoadFlag')
  public async loadOptionsTypes() {
    // 親コンポーネントのデータ準備完了タイミングに合わせるため、optionsTypesLoadFlagをWatchする(FlagがTrue=>データ準備OK)
    // 契約データのオプションのバージョンと同じバージョンのものを、オプションマスタから取得してくる
    let optionsTypesList: OptionData[] = [];
    this.optionsTypes.length = 0;

    if (this.division == PlatonDivision.OnPremise) {
      optionsTypesList = await optionsApi.listOnPremisePlatonOptionsTypes(
        sessionStore.getToken(),
        ''
      );
      optionsTypesList.map(ot => {
        this.optionsTypes.push(ot);
      });
    }
    if (this.division == PlatonDivision.Otegaru) {
      optionsTypesList = await optionsApi.listOteagruPlatonOptionsTypes(
        sessionStore.getToken(),
        ''
      );
      optionsTypesList.map(ot => {
        this.optionsTypes.push(ot);
      });
    }

    this.optionTypeVersions = newOptionTypesVersions(this.optionsTypes);
    this.optionTypeVersion = this.optionTypeVersions.slice(-1)[0].value;
    if (this.options.length > 0)
      this.optionTypeVersion = this.options[0].optionVersion;

    if (this.division == PlatonDivision.OnPremise) {
      optionsTypesList = await optionsApi.listOnPremisePlatonOptionsTypes(
        sessionStore.getToken(),
        this.optionTypeVersion
      );
    }
    if (this.division == PlatonDivision.Otegaru) {
      optionsTypesList = await optionsApi.listOteagruPlatonOptionsTypes(
        sessionStore.getToken(),
        this.optionTypeVersion
      );
    }
    optionsTypesList.map(ot => {
      this.optionsTypes.push(ot);
    });
    this.sortOptionsTypes();
    this.types.length = 0;
    this.optionsTypes.map(optType => {
      this.types.push({
        value: Number(optType.typeId),
        label: optType.optionTypeName,
      });
    });
    this.selectingType = this.types[0] ? this.types[0].value : 0;
  }
  private created() {
    this.loadOptionsTypes();
  }
  private get optionType(): Function {
    const optionsTypes = this.optionsTypes;
    return function(typeId: number): OptionData {
      if (!optionsTypes || optionsTypes.length === 0)
        return new OptionData(
          0,
          'オプションがデータベースに存在しません',
          '',
          new OptionTemplate('v001', '', [])
        );
      const optionType = optionsTypes.filter(optType => {
        if (optType.typeId == typeId) return optType;
      })[0];
      if (!optionType)
        new OptionData(
          0,
          'オプションがデータベースに存在しません',
          '',
          new OptionTemplate('v001', '', [])
        );
      return optionType;
    };
  }
  private get sortedOptions() {
    if (this.options.length == 0) return [];
    this.sortOptions();
    return this.options;
  }
  private sortOptions() {
    if (!this.options) return;
    if (this.optionsTypes.length == 0) return;
    let i = 0;
    let j = 1;
    let preRowNum = 0;
    let nowRowNum = 0;
    for (i = 0; i < this.options.length; i++) {
      for (j = this.options.length - 1; i < j; j--) {
        preRowNum = this.optionType(this.options[j - 1].optionsTypeId).template
          .rowNumber;
        nowRowNum = this.optionType(this.options[j].optionsTypeId).template
          .rowNumber;
        if (preRowNum > nowRowNum) {
          const deleteList = this.options.splice(j, 1);
          this.options.splice(j - 1, 0, deleteList[0]);
        }
      }
    }
  }
  private sortOptionsTypes() {
    if (!this.optionsTypes) return;
    let i = 0;
    let j = 1;
    let preRowNum = 0;
    let nowRowNum = 0;
    for (i = 0; i < this.optionsTypes.length; i++) {
      for (j = this.optionsTypes.length - 1; i < j; j--) {
        preRowNum = this.optionsTypes[j - 1].template.rowNumber;
        nowRowNum = this.optionsTypes[j].template.rowNumber;
        if (preRowNum > nowRowNum) {
          const deleteList = this.optionsTypes.splice(j, 1);
          this.optionsTypes.splice(j - 1, 0, deleteList[0]);
        }
      }
    }
  }
  private update() {
    // オプション項目の値が変更されたときのイベント
    // nothing to do
  }

  // newOption 選択したオプションを契約に追加
  private newOption() {
    this.options.push(
      new PlatonOption(
        'default',
        0,
        0,
        this.selectingType,
        this.optionTypeVersion,
        '',
        null,
        {
          using: true,
        }
      )
    );
  }

  // removeOption 選択したオプションを契約から削除
  private removeOption(typeId: number, idx: number) {
    this.options.splice(idx, 1);
    this.$forceUpdate();
  }
}
