




















import { Component, Vue, Prop, Inject } from 'vue-property-decorator';
import { DemoEnvironment } from '@/domain/DemoEnv/Types';
import EnvironmentStatusChip from '@/components/demoenv/EnvironmentStatusChip.vue';
import EnvironmentStatusMenu from '@/components/demoenv/EnvironmentStatusMenu.vue';
import { DemoEnvironmentApiInterface } from '../../interfaces';
import * as envstatus from '@/domain/DemoEnv/EnvironmentStatus';
@Component({
  components: {
    EnvironmentStatusChip,
    EnvironmentStatusMenu,
  },
})
export default class EnvironmentStatusSelector extends Vue {
  @Prop({ type: Object, required: true })
  private demoenv!: DemoEnvironment;
  @Prop({ type: Boolean, required: false, default: false })
  private disabled!: boolean;
  @Prop({ type: Boolean, required: false, default: false })
  private editMode!: boolean;

  @Inject('demoEnvApiClient')
  private client!: DemoEnvironmentApiInterface;

  private available = {
    value: envstatus.AVAILABLE,
    label: envstatus.AVAILABLE_LABEL + 'に変更する',
  };

  private initialState = {
    value: envstatus.INITIAL_STATE,
    label: envstatus.INITIAL_STATE_LABEL + 'に変更する',
  };
  private get statusMenuItems(): { [key: string]: string }[] {
    if (this.demoenv.environmentStatus == envstatus.AVAILABLE) {
      return [this.initialState];
    }
    if (this.demoenv.environmentStatus == envstatus.INITIAL_STATE) {
      return [this.available];
    }
    return [];
  }

  private get menuDisplay(): boolean {
    if (
      this.demoenv.environmentStatus == envstatus.AVAILABLE ||
      this.demoenv.environmentStatus == envstatus.INITIAL_STATE
    ) {
      return true;
    }
    return false;
  }
  private async updateStatus(status: string) {
    try {
      const demoenv = await this.client.updateEnvironmentStatus(
        this.demoenv.demoEnvId,
        status
      );
      this.$emit('update', demoenv);
      this.$emit('success', '環境ステータスを更新しました。');
    } catch {
      this.$emit('fail', '環境ステータスの更新に失敗しました');
    }
  }
}
