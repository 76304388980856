











































































import { Component, Vue } from 'vue-property-decorator';
import ActionMenu from '@/components/molecules/ActionMenu.vue';
import Navigation from '@/components/Navigation.vue';
import { mapActions } from 'vuex';
import * as api from '@/api/libra.ts';
import { LibraDivision } from '@/domain/LibraContract/Types';
import {
  LibraVersion,
  versionDivisions,
} from '@/domain/LibraContract/LibraVersion';
import { LibraInstaller } from '@/domain/LibraContract/LibraInstaller';
import { VersionUpForm } from './LibraVersionUpForm';
import * as sessionStore from '@/stores/session';

@Component({
  components: {
    ActionMenu,
    Navigation,
  },
  methods: mapActions('alertArea', ['success', 'fail']),
})
export default class LibraVersionUp extends Vue {
  private actionMenu = [
    {
      icon: 'arrow_back',
      text: '一覧に戻る',
      color: '',
      click: () => {
        this.returnList();
      },
    },
  ];

  private vm = new VersionUpForm();

  private success!: (message: string) => void;
  private fail!: (message: string) => void;

  private get division(): number {
    return parseInt(this.$route.params.division, 10);
  }

  private get chipColor(): string {
    if (this.division === LibraDivision.Otegaru) {
      return 'red white--text';
    }
    return 'red white--text darken-4';
  }

  private get LibraDivision() {
    return LibraDivision;
  }

  private get versionDivisions() {
    return versionDivisions;
  }

  private get contractLabel(): string {
    const division = parseInt(this.$route.params.division, 10);
    if (division === LibraDivision.Otegaru) {
      return 'お手軽Libra';
    }
    return 'オンプレLibra';
  }

  private async created() {
    await this.reload();
  }

  private async reload() {
    const id = parseInt(this.$route.params.id, 10);
    const division = parseInt(this.$route.params.division, 10);

    if (division === LibraDivision.Otegaru) {
      const model = await api.detailOtegaru(sessionStore.getToken(), id);
      this.vm = new VersionUpForm(
        model.libraId,
        model.plan.division,
        model.endUser.customerName,

        model.version.versionDivision,
        model.version.versionLabel,
        model.version.updateDate.substr(0, 10)
      );
    }
    if (division === LibraDivision.OnPremise) {
      const model = await api.detailOnPremise(sessionStore.getToken(), id);
      this.vm = new VersionUpForm(
        model.libraId,
        model.plan.division,
        model.endUser.customerName,

        model.version.versionDivision,
        model.version.versionLabel,
        model.version.updateDate.substr(0, 10),
        '',
        '',
        '',

        model.installer.versionDivision,
        model.installer.versionLabel
      );
    }
  }
  private async submitVersion() {
    const model = new LibraVersion(
      this.vm.updateVersionDivision,
      this.vm.updateVersion,
      this.vm.updateDate + 'T00:00:00Z'
    );
    try {
      await api.updateVersion(sessionStore.getToken(), this.vm.libraId, model);
      this.success('バージョンを更新しました');
    } catch {
      this.fail('バージョンの更新に失敗しました');
    }

    await this.reload();
  }

  private async submitInstaller() {
    const model = new LibraInstaller(
      this.vm.updateInstallerDivision,
      this.vm.updateInstaller
    );
    try {
      await api.updateInstaller(
        sessionStore.getToken(),
        this.vm.libraId,
        model
      );
      this.success('提供インストーラーバージョンを更新しました');
    } catch {
      this.fail('提供インストーラーバージョンの変更に更新しました');
    }

    await this.reload();
  }

  private returnList() {
    const division = parseInt(this.$route.params.division, 10);
    if (division === LibraDivision.Otegaru) {
      this.$router.push('../../otegaru/list');
    }
    if (division === LibraDivision.OnPremise) {
      this.$router.push('../../onpremise/list');
    }
  }
}
