














import { Component, Vue } from 'vue-property-decorator';
import { mapState } from 'vuex';
import { Alert } from '@/domain/Alert';

@Component({
  computed: mapState('alertArea', ['alerts']),
})
export default class AlertArea extends Vue {
  private alerts!: { [index: string]: Alert };
}
