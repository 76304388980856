import { post } from './io';
import { OptionData } from '@/components/options/OptionData';

const rootUrl = process.env.VUE_APP_URL + '/base';

export const listOnPremisePlatonOptionsTypes = async (
  token: string,
  version: string
): Promise<OptionData[]> => {
  const url = rootUrl + '/unites/platon/options/onpremise/list';
  const params = { version };
  const headers = { Authorization: 'Bearer ' + token };
  const ret = await post(url, params, { headers });
  return Promise.resolve(ret.options);
};

export const updateOnPremisePlatonOptionsTypes = async (
  token: string,
  options: OptionData[]
): Promise<OptionData[]> => {
  const url = rootUrl + '/unites/platon/options/onpremise/update';
  const params = {
    options,
  };
  const headers = { Authorization: 'Bearer ' + token };
  const ret = await post(url, params, { headers });
  return Promise.resolve(ret.options);
};

export const listOteagruPlatonOptionsTypes = async (
  token: string,
  version: string
): Promise<OptionData[]> => {
  const url = rootUrl + '/unites/platon/options/otegaru/list';
  const params = { version };
  const headers = { Authorization: 'Bearer ' + token };
  const ret = await post(url, params, { headers });
  return Promise.resolve(ret.options);
};

export const updateOteagruPlatonOptionsTypes = async (
  token: string,
  options: OptionData[]
): Promise<OptionData[]> => {
  const url = rootUrl + '/unites/platon/options/otegaru/update';
  const params = {
    options,
  };
  const headers = { Authorization: 'Bearer ' + token };
  const ret = await post(url, params, { headers });
  return Promise.resolve(ret.options);
};

export const listOnPremiseLibraOptionsTypes = async (
  token: string,
  version: string
): Promise<OptionData[]> => {
  const url = rootUrl + '/unites/libra/options/onpremise/list';
  const params = { version };
  const headers = { Authorization: 'Bearer ' + token };
  const ret = await post(url, params, { headers });
  return Promise.resolve(ret.options);
};

export const updateOnPremiseLibraptionsTypes = async (
  token: string,
  options: OptionData[]
): Promise<OptionData[]> => {
  const url = rootUrl + '/unites/libra/options/onpremise/update';
  const params = {
    options,
  };
  const headers = { Authorization: 'Bearer ' + token };
  const ret = await post(url, params, { headers });
  return Promise.resolve(ret.options);
};

export const listOteagruLibraOptionsTypes = async (
  token: string,
  version: string
): Promise<OptionData[]> => {
  const url = rootUrl + '/unites/libra/options/otegaru/list';
  const params = { version };
  const headers = { Authorization: 'Bearer ' + token };
  const ret = await post(url, params, { headers });
  return Promise.resolve(ret.options);
};

export const updateOteagruLibraOptionsTypes = async (
  token: string,
  options: OptionData[]
): Promise<OptionData[]> => {
  const url = rootUrl + '/unites/libra/options/otegaru/update';
  const params = {
    options,
  };
  const headers = { Authorization: 'Bearer ' + token };
  const ret = await post(url, params, { headers });
  return Promise.resolve(ret.options);
};

export const listSpottyOptionsTypes = async (
  token: string,
  version: string
): Promise<OptionData[]> => {
  const url = rootUrl + '/unites/spotty/options/list';
  const params = { version };
  const headers = { Authorization: 'Bearer ' + token };
  const ret = await post(url, params, { headers });
  return Promise.resolve(ret.options);
};

export const updateSpottyOptionsTypes = async (
  token: string,
  options: OptionData[]
): Promise<OptionData[]> => {
  const url = rootUrl + '/unites/spotty/options/update';
  const params = {
    options,
  };
  const headers = { Authorization: 'Bearer ' + token };
  const ret = await post(url, params, { headers });
  return Promise.resolve(ret.options);
};
