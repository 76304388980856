







































































































































































































































































































































































































































































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import ActionMenu from '@/components/molecules/ActionMenu.vue';
import CustomerSearchSelector from '@/components/customer/CustomerSearchSelector.vue';
import DatePicker from '@/components/molecules/DatePicker.vue';
import { mapActions, mapState } from 'vuex';
import * as lendstatus from '@/domain/DemoEnv/LendingStatus';
import { PLATON, LIBRA, SPOTTY } from '@/domain/Product';
@Component({
  components: {
    ActionMenu,
    CustomerSearchSelector,
    DatePicker,
  },
  computed: {
    ...mapState('demoManagementBoard', {
      product: 'product',
      demoenvs: 'demoenvs',
      demoLendings: 'demoLendings',
      editMode: 'edit',
    }),
    ...mapState('accountInfo', ['account']),
  },
  methods: mapActions('alertArea', ['success', 'fail', 'warning']),
})
export default class LendingStatusHelper extends Vue {
  private product!: string;
  @Prop({ type: String, required: false })
  private role!: string;
  @Prop({ type: String, required: false })
  private status!: string;
  private PLATON = PLATON;
  private LIBRA = LIBRA;
  private SPOTTY = SPOTTY;
  private lendingStatusItems = lendstatus.LENDING_STATUS_ITEMS.slice(
    0,
    lendstatus.LENDING_STATUS_ITEMS.length - 1
  );

  private openEnvironmentList(url: string) {
    window.open(url);
  }
}
