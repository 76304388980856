
















































































import { Component, Vue } from 'vue-property-decorator';
import { ValidationObserver } from 'vee-validate';
import ActionMenu from '@/components/molecules/ActionMenu.vue';
import CustomerSearchSelector from '@/components/customer/CustomerSearchSelector.vue';
import DatePicker from '@/components/molecules/DatePicker.vue';
import Select from '@/components/molecules/Select.vue';
import TextField from '@/components/molecules/TextField.vue';
import Navigation from '@/components/Navigation.vue';
import LibraAccountEditor from '@/components/libra/LibraAccountEditor.vue';
import LibraOptionEditor from '@/components/libra/LibraOptionEditor.vue';
import LibraPlanSelector from '@/components/libra/LibraPlanSelector.vue';
import { mapActions } from 'vuex';
import * as api from '@/api/libra.ts';
import * as customerApi from '@/api/customer';
import { LibraDivision } from '@/domain/LibraContract/Types';
import { LibraContract } from '@/domain/LibraContract/LibraContract';
import { closingDays } from '@/domain/ClosingDay';
import { Customer } from '@/domain/Customer';
import { defaultLibraDetailForm } from './LibraDetail/LibraDetailForm';
import * as sessionStore from '@/stores/session';
import { form2model } from './LibraDetail/form2model';
import { model2form } from './LibraDetail/model2form';
import { OptionData } from '@/components/options/OptionData';
import dayjs from 'dayjs';

@Component({
  components: {
    ValidationObserver,
    ActionMenu,
    CustomerSearchSelector,
    DatePicker,
    Select,
    TextField,
    Navigation,
    LibraAccountEditor,
    LibraOptionEditor,
    LibraPlanSelector,
  },
  methods: mapActions('alertArea', ['success', 'fail', 'warning']),
})
export default class LibraOtegaruDetail extends Vue {
  private actionMenu = [
    {
      icon: 'arrow_back',
      text: '一覧に戻る',
      color: '',
      click: () => {
        this.returnList();
      },
    },
    {
      icon: 'note_add',
      type: 'submit',
      text: '以上の内容で契約を更新する',
      color: 'primary',
      click: () => {
        /*nothing todo*/
      },
    },
    {
      icon: 'delete_forever',
      type: 'button',
      text: '',
      color: 'error',
      click: async () => {
        await this.delete();
      },
    },
  ];

  private closingDays = closingDays;
  private closingDayDisabled = true;
  private salesAgents: Customer[] = [];
  private optionsTypes: OptionData[] = [];
  private get LibraDivision() {
    return LibraDivision;
  }
  private optionsTypesLoadFlag = false;
  private model: LibraContract | null = null;
  private vm = defaultLibraDetailForm;
  private versionCount = 0;

  private success!: (message: string) => void;
  private fail!: (message: string) => void;
  private warning!: (message: string) => void;

  private async created() {
    const id = parseInt(this.$route.params.id, 10);
    this.model = await api.detailOtegaru(sessionStore.getToken(), id);
    this.vm = model2form(this.model);

    await this.searchSalesAgents(this.vm.salesAgentKeywords);

    // 変更可否確認のためのカウント
    const contractLog = await api.listLibraContractLogs(
      sessionStore.getToken(),
      0,
      0,
      id
    );
    this.versionCount = contractLog.length;
    this.optionsTypesLoadFlag = true;
    this.closingDayDisabled = dayjs(this.vm.startDate) <= dayjs();
    this.$forceUpdate();
  }

  private async reload() {
    const id = parseInt(this.$route.params.id, 10);
    this.model = await api.detailOtegaru(sessionStore.getToken(), id);
    this.vm = model2form(this.model);

    await this.searchSalesAgents(this.vm.salesAgentKeywords);

    // 変更可否確認のためのカウント
    const contractLog = await api.listLibraContractLogs(
      sessionStore.getToken(),
      0,
      0,
      id
    );
    this.versionCount = contractLog.length;
    this.optionsTypesLoadFlag = true;
    this.closingDayDisabled = dayjs(this.vm.startDate) <= dayjs();
    this.$forceUpdate();
  }

  private updateSalesAgentKeywords(keywords: string) {
    this.vm.salesAgentKeywords = keywords;
  }

  private async addCustomer(keywords: string) {
    if (keywords === '') {
      this.fail('顧客名が入力されていません');
      return;
    }

    const customer = new Customer(0, keywords);
    try {
      await customerApi.add(sessionStore.getToken(), customer);
      this.success('顧客を追加しました');
    } catch (e) {
      this.fail(e.response.data.message);
    }

    this.searchSalesAgents(this.vm.salesAgentKeywords);
  }

  private async submit(validate: any) {
    validate().then(async (result: any) => {
      if (!result.isValid) {
        return;
      }
      try {
        const beforeLog = await api.listLibraContractLogs(
          sessionStore.getToken(),
          0,
          0,
          this.vm.libraId
        );
        if (this.versionCount !== beforeLog.length) {
          this.fail('契約編集画面を表示してから、データに変更があったようです');
          this.fail('お手数ですがリロードしてから再入力をお願いします');
          return;
        }
        await api.updateOtegaru(
          sessionStore.getToken(),
          form2model(this.model, this.vm)
        );
        this.versionCount++;
        const afterLog = await api.listLibraContractLogs(
          sessionStore.getToken(),
          0,
          0,
          this.vm.libraId
        );
        if (this.versionCount !== afterLog.length) {
          this.warning('ほぼ同時に別の更新があったようです');
          this.warning(
            '現在のデータが正常であるか、リロードしてご確認ください'
          );
          return;
        }
        this.success('閲覧中の契約情報を更新しました');
        this.reload();
      } catch (e) {
        this.fail('閲覧中の契約情報の更新に失敗しました');
      }
    });
  }

  private returnList() {
    this.$router.push('../list');
  }

  private async searchSalesAgents(keywords: string) {
    if (keywords === '') {
      this.salesAgents = [];
    } else {
      try {
        this.salesAgents = await customerApi.list(
          sessionStore.getToken(),
          5,
          0,
          keywords
        );
      } catch {
        this.fail('代理店一覧の取得に失敗しました');
        return;
      }
    }
    this.salesAgents.push(new Customer(0, '代理店を指定しない'));
  }
  private async delete() {
    if (
      !confirm(
        'この契約を完全に削除して、無かったことにしますか？ \n(契約の終了時は「利用終了日」に日付を入れて更新してください)'
      )
    ) {
      return;
    }
    if (!confirm('本当に削除します。いいですね？')) {
      return;
    }

    try {
      await api.deleteOtegaru(sessionStore.getToken(), this.vm.libraId);
      this.success('閲覧中の契約情報を削除しました');
      this.$router.push('../list');
    } catch {
      this.fail('閲覧中の契約情報の削除に失敗しました');
    }
  }
}
