import { PlatonContract } from '@/domain/PlatonContract/PlatonContract';
import { PlatonDetailForm } from './PlatonDetailForm';

// 契約詳細フォームからモデルへの変換
export const form2model = (
  model: PlatonContract | null,
  form: PlatonDetailForm
): PlatonContract => {
  if (model === null) {
    throw new Error();
  }

  const obj = JSON.parse(JSON.stringify(form)) as PlatonDetailForm;
  model.salesAgentId = parseInt(obj.salesAgentId, 10);
  model.planId = obj.plan.planId;
  model.planData = obj.plan.planData;
  model.url = obj.url;
  model.siteId = obj.siteId;
  model.startDate = obj.startDate + 'T00:00:00Z';
  model.billingStartDate = obj.billingStartDate + 'T00:00:00Z';
  model.endDate = obj.endDate ? obj.endDate + 'T00:00:00Z' : null;
  model.closingDay = obj.closingDay;
  model.salesMemo = obj.salesMemo;
  model.server = obj.server;
  model.server.installedDate = obj.server.installedDate
    ? obj.server.installedDate + 'T00:00:00Z'
    : null;
  model.options = obj.options
    .filter(opt => {
      return opt.data != null;
    })
    .map(opt => {
      opt.platonId = obj.platonId;
      if (!opt.startDate) {
        opt.startDate = obj.startDate + 'T00:00:00Z';
      }
      return opt;
    });
  return model;
};
