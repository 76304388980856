































































import { Component, Vue, Prop, Inject } from 'vue-property-decorator';
import { ListCard } from './Types';
import { DemoEnvironment } from '@/domain/DemoEnv/Types';
import EnvironmentStatusSelector from './EnvironmentStatusSelector.vue';
import * as lendstatus from '@/domain/DemoEnv/LendingStatus';
import ActionMenu from '@/components/molecules/ActionMenu.vue';
import { mapActions } from 'vuex';
import * as api from '@/api/demoenv';
import * as sessionStore from '@/stores/session';

@Component({
  components: {
    EnvironmentStatusSelector,
    ActionMenu,
  },
  methods: {
    ...mapActions('alertArea', ['success', 'fail', 'info']),
  },
})
export default class EnvironmentListCard extends Vue {
  @Prop({ type: Object, required: true })
  private demoenv!: DemoEnvironment;
  @Prop({ type: Boolean, required: true })
  private selected!: boolean;
  @Prop({ type: Boolean, required: true })
  private editMode!: boolean;

  private get memoFirstLine() {
    return (memo: string) => {
      if (!memo) return '';
      const pieces = memo.split('\n');
      if (pieces.length == 0) return '';
      return pieces[0];
    };
  }
  private class2rgb(classString: string, opacity: number): string {
    return lendstatus.class2rgb(classString, opacity);
  }
  private classPrefix = ListCard.classPrefix;

  @Inject('openDemoEnvironment')
  private open!: (id: number) => Promise<void>;
  @Inject('reserveDialogStart')
  private reserve!: (env: DemoEnvironment) => void;

  private actionMenu = [
    {
      icon: 'cloud_download',
      text: 'CSVでダウンロードする',
      color: 'secondary',
      click: () => {
        this.downloadList();
      },
    },
  ];

  private success!: (message: string) => void;
  private fail!: (message: string) => void;
  private info!: (message: string) => void;

  private async downloadList() {
    try {
      await api.donwnloadDemo(sessionStore.getToken(), this.demoenv.productId);
      this.success('CSVダウンロードに成功しました');
    } catch (e) {
      this.fail('CSVダウンロードに失敗しました error=[' + e + ']');
    }
  }
}
