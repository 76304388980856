import { post, binary } from './io';
import { clean } from './helper_keywords';
import { SpottyContract } from '@/domain/SpottyContract/SpottyContract';
import { SpottyVersion } from '@/domain/SpottyContract/SpottyVersion';
import { SpottyContractLog } from '@/domain/SpottyContract/SpottyContractLog';

const rootUrl = process.env.VUE_APP_URL + '/base';

export const list = async (
  token: string,
  limit: number,
  offset: number,
  keywords: string,
  contractStatus: string
): Promise<SpottyContract[]> => {
  const url = rootUrl + '/unites/spotty/list';
  const params = {
    limit,
    offset,
    keywords: clean(keywords),
    contractStatus,
  };
  const headers = { Authorization: 'Bearer ' + token };
  const ret = await post(url, params, { headers });
  return Promise.resolve(ret.spotties);
};

export const donwnload = async (
  token: string,
  limit: number,
  offset: number,
  keywords: string,
  contractStatus: string
): Promise<SpottyContract[]> => {
  const url = rootUrl + '/unites/spotty/list/download';
  const params = {
    limit,
    offset,
    keywords: clean(keywords),
    contractStatus,
  };
  const headers = { Authorization: 'Bearer ' + token };
  const ret = await binary(url, params, {
    headers,
    responseType: 'arraybuffer',
  });
  return Promise.resolve(ret.spotties);
};

export const add = async (
  token: string,
  spotty: SpottyContract
): Promise<SpottyContract> => {
  const url = rootUrl + '/unites/spotty/add';
  const params = {
    spotty,
  };
  const headers = { Authorization: 'Bearer ' + token };
  const ret = await post(url, params, { headers });
  return Promise.resolve(ret.spotty);
};

export const deleteOne = async (
  token: string,
  spottyId: number
): Promise<SpottyContract> => {
  const url = rootUrl + '/unites/spotty/delete';
  const params = {
    spottyId,
  };
  const headers = { Authorization: 'Bearer ' + token };
  const ret = await post(url, params, { headers });
  return Promise.resolve(ret.libra);
};

export const detail = async (
  token: string,
  spottyId: number
): Promise<SpottyContract> => {
  const url = rootUrl + '/unites/spotty/detail';
  const params = {
    spottyId,
  };
  const headers = { Authorization: 'Bearer ' + token };
  const ret = await post(url, params, { headers });
  return Promise.resolve(ret.spotty);
};

export const update = async (
  token: string,
  spotty: SpottyContract
): Promise<SpottyContract> => {
  const url = rootUrl + '/unites/spotty/update';
  const params = {
    spotty,
  };
  const headers = { Authorization: 'Bearer ' + token };
  const ret = await post(url, params, { headers });
  return Promise.resolve(ret.spotty);
};

export const parseSheet = async (
  token: string,
  file: File
): Promise<SpottyContract> => {
  const url = rootUrl + '/unites/spotty/parsesheet';
  const headers = {
    Authorization: 'Bearer ' + token,
    'Content-Type': 'multipart/form-data',
  };
  const params = new FormData();
  params.append('sheet', file);

  const ret = await post(url, params, { headers });
  return Promise.resolve(ret.spotty);
};

export const listSpottyContractLogs = async (
  token: string,
  limit: number,
  offset: number,
  spottyId: number
): Promise<SpottyContractLog[]> => {
  const url = rootUrl + '/unites/spotty/logs';
  const params = {
    limit,
    offset,
    spottyId,
  };
  const headers = { Authorization: 'Bearer ' + token };
  const ret = await post(url, params, { headers });
  return Promise.resolve(ret.logs);
};

export const updateVersion = async (
  token: string,
  spottyId: number,
  version: SpottyVersion
): Promise<void> => {
  const url = rootUrl + '/unites/spotty/version/update';
  const params = {
    spottyId,
    version,
  };
  const headers = { Authorization: 'Bearer ' + token };
  await post(url, params, { headers });
  return Promise.resolve();
};
