export function randomString(
  length: number,
  upper: boolean,
  lower: boolean,
  number: boolean,
  symbol: boolean
): string {
  let S = '';
  if (upper) S += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  if (lower) S += 'abcdefghijklmnopqrstuvwxyz';
  if (number) S += '0123456789';
  if (symbol) S += '!@_&#+-';
  return Array.from(Array(length))
    .map(() => S[Math.floor(Math.random() * S.length)])
    .join('');
}

export function todayString(): string {
  const hiduke = new Date();
  const year = hiduke.getFullYear();
  const month = '00' + (hiduke.getMonth() + 1);
  const day = '00' + hiduke.getDate();
  return year + '-' + month.slice(-2) + '-' + day.slice(-2);
}
