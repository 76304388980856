









































import { Component, Vue, Prop } from 'vue-property-decorator';
import ActionMenu from '@/components/molecules/ActionMenu.vue';
import Navigation from '@/components/Navigation.vue';
import OptionCardPlane from '@/components/options/OptionCardPlane.vue';

@Component({
  components: {
    ActionMenu,
    Navigation,
    OptionCardPlane,
  },
})
export default class GanttDaysHeader extends Vue {
  @Prop({ type: String, required: false })
  private width!: string;
  @Prop({ type: String, required: false })
  private leftOffset!: string;
  @Prop({ type: Array, required: true })
  private months!: any[];
  @Prop({ type: Array, required: true })
  private days!: any[];
  @Prop({ type: Number, required: true })
  private startMonth!: number;
  @Prop({ type: Number, required: true })
  private startDay!: number;
}
