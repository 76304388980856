export class Plan {
  constructor(
    public planId = 1,
    public productId = 1,
    public division = 1,
    public planName = ''
  ) {}
}

export const otegaruPlans = [
  { value: 1, label: '従量課金制' },
  { value: 2, label: '月額定額制 月間利用者数' },
  { value: 19, label: '月額定額制 年間利用者数' },
  { value: 3, label: '月額定額制 同時接続数' },
  { value: 4, label: '特別プラン' },
];

export const perUseType = ['タイプA', 'タイプB', 'タイプC', '完全流量プラン'];

export const onpremisePlans = [
  { value: 5, label: 'パッケージ販売(買取) ライセンス30名+' },
  { value: 6, label: '年間定額制' },
  { value: 7, label: 'レベニューシェア' },
];

export class OtegaruMonthlyFlatRateYearlyUsers {
  constructor(public yearlyUsersNumber = 0, public closingMonth = 3) {}
}

export function getPlanDataValue(planId: number, planData: any): any {
  if (planId == 19) {
    if (planData instanceof OtegaruMonthlyFlatRateYearlyUsers)
      return String(planData.yearlyUsersNumber);
    if (typeof planData == 'string') {
      const data = JSON.parse(planData) as OtegaruMonthlyFlatRateYearlyUsers;
      return String(data.yearlyUsersNumber);
    }
  }
  return planData;
}
