export class OptionData {
  typeId: number;
  optionTypeName: string;
  version: string;
  template: OptionTemplate;
  edit: boolean;
  valid: any[];
  constructor(
    typeId: number,
    optionTypeName: string,
    version: string,
    template: OptionTemplate
  ) {
    this.typeId = typeId;
    this.optionTypeName = optionTypeName;
    this.version = version;
    this.template = template;
    this.edit = false;
    this.valid = [];
  }
}

export class OptionTemplate {
  version: string;
  rowNumber: number;
  divisions: string[];
  templateType: string;
  items: OptionItem[];
  constructor(
    templateVersion: string,
    templateType: string,
    items: OptionItem[]
  ) {
    this.version = templateVersion;
    if (items == null) items = [];
    this.rowNumber = 99;
    this.divisions = [];
    this.templateType = templateType;
    this.items = items;
  }
}

export class OptionItem {
  label: string;
  name: string;
  valueType: string;
  default: string;
  required: boolean;
  readonly: boolean;
  vcol: number;
  setting: any;

  constructor(
    label: string,
    name: string,
    valueType: string,
    _default: string,
    req: boolean,
    ronly: boolean,
    vcol: number,
    setting: any
  ) {
    this.label = label;
    this.name = name;
    this.valueType = valueType;
    this.default = _default;
    this.required = req;
    this.readonly = ronly;
    this.vcol = vcol;
    this.setting = setting;
  }
}
