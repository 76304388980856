import { DemoLendingFilter } from './Lending';
import { DemoLendingOption, isDemoLendingOption } from './LendingOption';
import { RangeDate } from './Date';
import * as lendstatus from './LendingStatus';
import * as user from './User';

export type Tag = 'main' | 'admin' | 'user' | 'login';

export function isTag(value: unknown): value is Tag {
  return (
    typeof value === 'string' &&
    (value === 'main' ||
      value === 'admin' ||
      value === 'user' ||
      value === 'login')
  );
}

export class DemoURL {
  constructor(
    public demoUrlId = 0,
    public demoEnvId = 0,
    public url = 'https://',
    public label = '',
    public loginId = '',
    public loginPassword = '',
    public tags = [] as Tag[],
    public updatedAt = ''
  ) {}
}

export function isDemoURL(obj: unknown): obj is DemoURL {
  if (typeof obj !== 'object') return false;
  if (obj == null) return false;
  return (
    typeof (obj as DemoURL).demoUrlId === 'number' &&
    typeof (obj as DemoURL).demoEnvId === 'number' &&
    typeof (obj as DemoURL).url === 'string' &&
    typeof (obj as DemoURL).label === 'string' &&
    typeof (obj as DemoURL).loginId === 'string' &&
    typeof (obj as DemoURL).loginPassword === 'string' &&
    typeof (obj as DemoURL).tags === 'object' &&
    (obj as DemoURL).tags.filter(tag => {
      return !isTag(tag);
    }).length === 0 &&
    typeof (obj as DemoURL).updatedAt === 'string'
  );
}

export class DemoLending implements RangeDate {
  constructor(
    public demoLendingId = 0,
    public customerId = '' as any,
    public customerName = '',
    public userClass: user.UserClass = user.CUSTOMER,
    public lendingStatus: lendstatus.LendingStatus = lendstatus.WAITING,
    public startDate = '',
    public endDate = '' as string | null,
    public responsibleUserName = '',
    public options: DemoLendingOption[] = [],
    public contractResult = '',
    public memo = '',
    public assigned = false,
    public demoEnvId = 0
  ) {}
  public addOption(optionData: DemoLendingOption): boolean {
    if (!isDemoLendingOption(optionData)) return false;
    if (typeof optionData.data === 'string') return false;
    if (!optionData.data.using || optionData.data.using === 'false')
      return false;
    const option = new DemoLendingOption(
      optionData.mode,
      optionData.optionId,
      optionData.optionsTypeId,
      optionData.productId,
      'otegaru',
      optionData.optionVersion,
      this.demoLendingId,
      optionData.data
    );
    this.options.push(option);
    return true;
  }
}

export function isDemoLending(obj: unknown): obj is DemoLending {
  return (
    typeof (obj as DemoLending).demoLendingId === 'number' &&
    typeof (obj as DemoLending).customerId === 'number' &&
    typeof (obj as DemoLending).customerName === 'string' &&
    user.isUserClass((obj as DemoLending).userClass) &&
    lendstatus.isLendingStatus((obj as DemoLending).lendingStatus) &&
    typeof (obj as DemoLending).startDate === 'string' &&
    (obj as DemoLending).endDate !== undefined &&
    typeof (obj as DemoLending).responsibleUserName === 'string' &&
    typeof (obj as DemoLending).options === 'object' &&
    (obj as DemoLending).options.filter(opt => !isDemoLendingOption(opt))
      .length === 0 &&
    typeof (obj as DemoLending).contractResult === 'string' &&
    typeof (obj as DemoLending).memo === 'string' &&
    typeof (obj as DemoLending).assigned === 'boolean' &&
    typeof (obj as DemoLending).demoEnvId === 'number'
  );
}

export class DemoEnvironment implements DemoLendingFilter {
  constructor(
    public demoEnvId = 0,
    public productId = 0,
    public name = '',
    public role = '',
    public environmentStatus = 'available',
    public adminId = 0,
    public adminName = '',
    public memo = '',
    public createdAt = '',
    public updatedAt = '',
    public urls = [] as DemoURL[],
    public lendings = [] as DemoLending[],
    public active = true
  ) {}
  public assignedFilter(assign?: boolean): DemoLending[] {
    if (this.lendings.length == 0) return [];
    return this.lendings.filter(l => {
      return l.assigned == assign;
    });
  }
  public sortLendingsByStartDate(desc?: boolean) {
    this.lendings = this.lendings.sort((a: DemoLending, b: DemoLending) => {
      const aDate = new Date(a.startDate);
      const bDate = new Date(b.startDate);
      if (desc) return bDate.getTime() - aDate.getTime();
      return aDate.getTime() - bDate.getTime();
    });
    return true;
  }
}

export function isDemoEnvironment(obj: unknown): obj is DemoEnvironment {
  return (
    typeof (obj as DemoEnvironment).demoEnvId === 'number' &&
    typeof (obj as DemoEnvironment).productId === 'number' &&
    typeof (obj as DemoEnvironment).name === 'string' &&
    typeof (obj as DemoEnvironment).role === 'string' &&
    typeof (obj as DemoEnvironment).environmentStatus === 'string' &&
    typeof (obj as DemoEnvironment).adminId === 'number' &&
    typeof (obj as DemoEnvironment).adminName === 'string' &&
    typeof (obj as DemoEnvironment).memo === 'string' &&
    typeof (obj as DemoEnvironment).createdAt === 'string' &&
    typeof (obj as DemoEnvironment).updatedAt === 'string' &&
    typeof (obj as DemoEnvironment).urls === 'object' &&
    (obj as DemoEnvironment).urls.filter(url => !isDemoURL(url)).length === 0 &&
    typeof (obj as DemoEnvironment).lendings === 'object' &&
    (obj as DemoEnvironment).lendings.filter(lending => !isDemoLending(lending))
      .length === 0
  );
}
