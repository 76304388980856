






















import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { SelfSetting } from '@/domain/SelfSetting';

@Component({
  computed: {
    ...mapGetters('selfSettingStore', ['selfSetting']),
  },
})
export default class Navigation extends Vue {
  private selfSetting!: SelfSetting;
  private items: {
    id: string;
    label: string;
    path: string;
    icon: string;
    visible: boolean;
  }[] = [];

  private created() {
    this.items = [
      {
        id: 'dashboard',
        label: 'ダッシュボード',
        path: '/dashboard',
        icon: 'dashboard',
        visible: true,
      },
      {
        id: 'customer',
        label: '顧客/代理店',
        path: '/customer/list',
        icon: 'domain',
        visible: true,
      },
      // {
      //   id: 'raisense',
      //   label: 'ライセンス区分管理',
      //   path: '/raisense',
      // },
      {
        id: 'platonOtegaru',
        label: 'お手軽Platon契約',
        path: '/platon/otegaru/list',
        icon: 'dashboard',
        visible: true,
      },
      {
        id: 'platonOnPremise',
        label: 'オンプレPlaton契約',
        path: '/platon/onpremise/list',
        icon: 'dashboard',
        visible: true,
      },
      {
        id: 'libraOtegaru',
        label: 'お手軽Libra契約',
        path: '/libra/otegaru/list',
        icon: 'library_books',
        visible: true,
      },
      {
        id: 'libraOnpremise',
        label: 'オンプレLibra契約',
        path: '/libra/onpremise/list',
        icon: 'library_books',
        visible: true,
      },
      {
        id: 'spotty',
        label: 'Spotty契約',
        path: '/spotty/list',
        icon: 'call_made',
        visible: true,
      },
      {
        id: 'platonDemo',
        label: 'デモ環境',
        path: '/demoenv/platon/dashboard',
        icon: 'dashboard',
        visible: this.selfSetting.displayDemoenvironment,
      },
      {
        id: 'billing',
        label: '課金根拠',
        path: '/billing',
        icon: 'euro',
        visible: true,
      },
      {
        id: 'aboutSystem',
        label: 'このシステムについて',
        path: '/about',
        icon: 'kitchen',
        visible: true,
      },
      // {
      //   id: 'statistics',
      //   label: '集計 (開発中モック)',
      //   path: '/statistics',
      //   // icon: 'bar_chart',
      //   icon: 'close',
      // },
      {
        id: 'option',
        label: 'オプション管理',
        path: '/options',
        icon: 'kitchen',
        visible: this.selfSetting.displayOptionsTypes,
      },
    ];
  }

  private open(path: string) {
    if (path !== this.$router.currentRoute.path) {
      this.$router.push(path);
    }
  }
}
