import { ActionContext } from 'vuex';
import { DemoEnvironmentListState } from '@/stores/types.ts';

const state: DemoEnvironmentListState = {
  demoenvs: [],
  searchParams: '',
};

const getters = {};

const actions = {
  setSearchParams(
    context: ActionContext<DemoEnvironmentListState, {}>,
    params: any
  ) {
    const urlParams = new URLSearchParams();
    Object.keys(params).map(key => {
      urlParams.set(key, params[key]);
    });
    const searchParams = urlParams.toString();
    const url = new URL(location.href);
    if (url.searchParams.toString() != searchParams)
      history.pushState(params, '', url.pathname + '?' + searchParams);
    context.commit('storeSearchParams', searchParams);
  },
};

const mutations = {
  storeSearchParams(st: DemoEnvironmentListState, searchParams: string) {
    st.searchParams = searchParams;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
