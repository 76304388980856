















import { Component, Vue } from 'vue-property-decorator';
import { mapActions } from 'vuex';
import * as accountApi from '@/api/account';
import * as sessionStore from '@/stores/session';

@Component({
  methods: {
    ...mapActions('accountInfo', ['setAccount', 'clearAccount']),
    ...mapActions('alertArea', ['success', 'fail']),
  },
})
export default class LoginBox extends Vue {
  private success!: (message: string) => void;
  private fail!: (message: string) => void;

  private created() {
    sessionStore.deleteToken();
  }

  private async googleLogin() {
    try {
      const target = await accountApi.googleLogin();
      window.location.href = target;
    } catch {
      this.fail('ログインに失敗しました');
      this.fail('バックエンドが動いていないかも？ 管理者に連絡してください');
    }
  }
}
