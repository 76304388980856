import {
  DemoEnvironment,
  DemoLending,
  DemoURL,
  EnvironmentStatus,
} from '@/domain/DemoEnv';

// eslint-disable-next-line
function _isNotEnded(status: string, nothing: string): boolean {
  if (status != 'ended') return true;
  return false;
}

function _equal(left: string, right: string): boolean {
  return left == right;
}

function _contains(target: string, search: string | null): boolean {
  if (!search) return true;
  return target.indexOf(search) != -1;
}

function _urlFilter(urls: DemoURL[], searchWord: string | null): boolean {
  if (!searchWord) return true;
  return (
    urls.filter(url => {
      return (
        _contains(url.url, searchWord) ||
        _contains(url.loginId, searchWord) ||
        _contains(url.loginPassword, searchWord)
      );
    }).length !== 0
  );
}
function _lendingFilter(
  lendings: DemoLending[],
  searchWord: string | null
): boolean {
  if (!searchWord) return true;
  return (
    lendings.filter(lending => {
      return (
        _contains(lending.customerName, searchWord) ||
        _contains(lending.responsibleUserName, searchWord)
      );
    }).length !== 0
  );
}

function _keywordFilter(
  env: DemoEnvironment,
  keywordsText: string | null
): boolean {
  if (!keywordsText) return true;
  // eslint-disable-next-line
  const k = / |　|\"/g;
  const keywords = keywordsText.split(k).filter(w => w != '');
  return (
    keywords.filter(keyword => {
      if (!keywords) return false;
      return (
        _contains(env.name, keyword) || // 環境名
        _lendingFilter(env.lendings, keyword) || // 貸出情報
        _contains(env.memo, keyword) || // 備考
        _urlFilter(env.urls, keyword) // URL
      );
    }).length == keywords.length
  );
}

export function environmentsFilter(
  environments: DemoEnvironment[],
  status: EnvironmentStatus | 'all',
  envName: string | null,
  envRole: string
): DemoEnvironment[] {
  let statusFilter = _equal;
  if (status == 'all') statusFilter = _isNotEnded;
  let envRoleFilter = _equal;
  if (!envRole || envRole == '')
    // eslint-disable-next-line
    envRoleFilter = (a: string, b: string) => {
      return true;
    };
  return environments.filter(env => {
    return (
      statusFilter(env.environmentStatus, status) &&
      envRoleFilter(env.role, envRole) &&
      _keywordFilter(env, envName)
    );
  });
}

export class EnvironmentListCriteria {
  constructor(
    public status: EnvironmentStatus | 'all' = 'all',
    public envName: string | null = '',
    public envRole: string = ''
  ) {}
}

export class DemoEnvsTable {
  constructor(
    public header = [
      {
        text: '環境名',
        value: 'name',
      },
      {
        text: 'ステータス',
        value: 'status',
      },
      {
        text: '貸出状況',
        value: 'lendings',
      },
      // {
      //   text: "種別",
      //   value: "role"
      // },
      {
        text: '予約件数',
        value: 'reservedLendingsNumber',
      },
      // {
      //   text: "URL",
      //   value: "urls"
      // },
      // {
      //   text: "作成日",
      //   value: "createdAt"
      // },
      // {
      //   text: "",
      //   value: "product"
      // }
    ],
    public criteria = new EnvironmentListCriteria()
  ) {}
}

export function lendingsFilter(
  lendings: DemoLending[],
  status: any,
  userName: string,
  responsibleUserName: string
): DemoLending[] {
  let statusFilter = _equal;
  let userNameFilter = _contains;
  // eslint-disable-next-line
  let responsibleUserFilter = (name: string) => {
    return true;
  };
  if (status == 'not_ended') statusFilter = _isNotEnded;
  if (!userName || userName == '')
    // eslint-disable-next-line
    userNameFilter = (a: string, b: string | null): boolean => {
      return true;
    };
  if (responsibleUserName != '')
    responsibleUserFilter = (name: string) => {
      return name == responsibleUserName;
    };
  return lendings.filter(l => {
    return (
      statusFilter(l.lendingStatus, status) &&
      userNameFilter(l.customerName, userName) &&
      responsibleUserFilter(l.responsibleUserName)
    );
  });
}

export class LendingListCriteria {
  constructor(
    public status = 'not_ended',
    public userName = '',
    public myLending = false
  ) {}
}

export class LendingsTable {
  constructor(
    public header = [
      {
        text: '利用者',
        value: 'customerName',
      },
      {
        text: 'ステータス',
        value: 'lendingStatus',
      },
      {
        text: '環境',
        value: 'demoEnvId',
      },
      // {
      //   text: "区分",
      //   value: "userClass"
      // },
      {
        text: '開始日',
        value: 'startDate',
      },
      {
        text: '終了日',
        value: 'endDate',
      },
      {
        text: '',
        value: 'memo',
      },
    ],
    public criteria = new LendingListCriteria()
  ) {}
}
