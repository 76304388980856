


























import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { Customer } from '@/domain/Customer';

@Component
export default class CustomerListTable extends Vue {
  /**
   * プロパティ
   */
  private headers = [
    {
      text: '顧客名',
      value: 'customerName',
      sortable: true,
    },
    {
      text: '顧客別称',
      value: 'customerAlias',
      sortable: true,
    },
    {
      text: '',
      value: 'control',
      sortable: false,
    },
  ];

  /**
   * 親から受け取る
   */
  @Prop({ type: Array, required: true })
  private customers!: Customer[];

  @Emit()
  // eslint-disable-next-line
  private openedit(customerId: number) {
    // Parent action
  }
}
