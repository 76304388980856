






















































































































import { Component, Vue, Prop, Inject } from 'vue-property-decorator';
import { ReservedLendingListType } from './Types';
import { DemoEnvironment, DemoLending } from '@/domain/DemoEnv/Types';
import LendingStatusChip from '@/components/demoenv/LendingStatusChip.vue';
import { dateDisplay } from '@/domain/DemoEnv/Date';
import * as envstatus from '@/domain/DemoEnv/EnvironmentStatus';
import * as lendstatus from '@/domain/DemoEnv/LendingStatus';
import * as Table from '../../table';
import { DemoEnvironmentListInterface } from './Property';

@Component({
  components: {
    LendingStatusChip,
  },
})
export default class ReservedLendingList extends Vue {
  @Inject('demoEnvironmentListProps')
  readonly props!: DemoEnvironmentListInterface;

  @Prop({ type: Object, required: true })
  readonly demoenv!: DemoEnvironment;
  @Prop({ type: Boolean, required: false, default: false })
  readonly editMode!: boolean;
  @Prop({ type: Number, required: true, default: 0 })
  readonly selectedEnvironmentId!: number;
  @Prop({ type: Number, required: true, default: 0 })
  readonly selectedLendingId!: number;

  private lendingsHeader!: any[];

  private get lendingStatusSetting() {
    return lendstatus.getLendingStatusSetting;
  }
  private get nextLendingStatus() {
    return lendstatus.getNextLendingStatus;
  }

  private class2rgb(classString: string, opacity: number): string {
    return lendstatus.class2rgb(classString, opacity);
  }
  private get isEnvAvailable() {
    return (env: DemoEnvironment): boolean => {
      return env.environmentStatus !== envstatus.INITIAL_STATE;
    };
  }
  private get isUnderLeace() {
    return (env: DemoEnvironment): boolean => {
      const lending = env.lendings.filter(l => {
        return lendstatus.isUnderLease(l.lendingStatus);
      });
      return lending.length != 0;
    };
  }
  private get isExpired() {
    return (endDate: string): boolean => {
      if (!endDate) return false;
      endDate = endDate.substr(0, 10);
      if (new Date(endDate) < new Date()) return true;
      return false;
    };
  }

  private get isRowSelected() {
    // 行をハイライトする条件
    return (item: DemoLending): boolean => {
      if (!item || this.selectedLendingId === 0) return false;
      return (
        // 選択されていてかつ、貸出情報カードが表示されているとき
        (this.selectedLendingId == item.demoLendingId &&
          this.props.editorDisplay == 'lending') ||
        // 選択されていてかつ、予約先環境も選択されているとき
        (this.selectedEnvironmentId == item.demoEnvId &&
          item.lendingStatus == lendstatus.LENDING &&
          this.props.editorDisplay == 'environment')
      );
    };
  }

  private get dateDisplay() {
    return dateDisplay;
  }
  private classPrefix = ReservedLendingListType.classPrefix;

  created() {
    const lendingsTable = new Table.LendingsTable();
    this.lendingsHeader = lendingsTable.header.filter(item => {
      return item.value != 'demoEnvId';
    });
  }

  @Inject('openDemoLending')
  private open!: (lending: DemoLending) => Promise<void>;
  @Inject('releaseLending')
  private releaseLending!: (lending: DemoLending) => Promise<void>;
}
