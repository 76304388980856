import { SpottyAccount } from '@/domain/SpottyContract/SpottyAccount';
import { SpottyOption } from '@/domain/SpottyContract/SpottyOption';
export interface SpottyDetailForm {
  spottyId: number;
  endUserName: string;
  salesAgentId: string;
  plan: Plan;
  url: string;
  siteId: string;
  startDate: string;
  billingStartDate: string;
  endDate: string;
  closingDay: number;
  salesMemo: string;
  options: SpottyOption[];
  initialAccount: SpottyAccount;
  salesAgentKeywords: string;
}

export const defaultSpottyDetailForm = {
  spottyId: 0,
  endUserName: '',
  salesAgentId: '0',
  plan: { planId: 0, planData: '' },
  url: '',
  siteId: '',
  startDate: '',
  billingStartDate: '',
  endDate: '',
  closingDay: 24,
  salesMemo: '',
  options: [] as SpottyOption[],
  initialAccount: new SpottyAccount(),
  salesAgentKeywords: '',
};
export interface Plan {
  planId: number;
  planData: string;
}
