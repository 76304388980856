import { SpottyContract } from '@/domain/SpottyContract/SpottyContract';
import { SpottyDetailForm } from './SpottyDetailForm';

// 契約詳細フォームからモデルへの変換
export const form2model = (
  model: SpottyContract | null,
  form: SpottyDetailForm
): SpottyContract => {
  if (model === null) {
    throw new Error();
  }

  const obj = JSON.parse(JSON.stringify(form)) as SpottyDetailForm;
  model.salesAgentId = parseInt(obj.salesAgentId, 10);
  model.planId = obj.plan.planId;
  model.planData = obj.plan.planData;
  model.url = obj.url;
  model.siteId = obj.siteId;
  model.startDate = obj.startDate + 'T00:00:00Z';
  model.billingStartDate = obj.billingStartDate + 'T00:00:00Z';
  model.endDate = obj.endDate ? obj.endDate + 'T00:00:00Z' : null;
  model.salesMemo = obj.salesMemo;
  model.initialAccount = obj.initialAccount;
  model.options = obj.options
    .filter(opt => {
      return opt.data != null;
    })
    .map(opt => {
      opt.spottyId = obj.spottyId;
      if (!opt.startDate) {
        opt.startDate = obj.startDate + 'T00:00:00Z';
      }
      return opt;
    });

  return model;
};
