









import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

@Component
export default class KeywordSearch extends Vue {
  /**
   * 親から受け取る
   */
  @Prop({ type: String, required: true })
  private value!: string;

  @Prop({ type: Array, required: true })
  private targetLabel!: string[];

  @Emit()
  // eslint-disable-next-line
  private input(val: string) {
    // Parent action
  }

  /**
   * プロパティ
   */
  private get label() {
    return 'キーワード検索 (' + this.targetLabel.join('|') + ')';
  }
}
