import { ActionContext } from 'vuex';
import { SelfSettingState } from '@/stores/types.ts';
import { defaultSelfSetting, SelfSetting } from '@/domain/SelfSetting';

const state: SelfSettingState = {
  selfSetting: defaultSelfSetting,
};

const getters = {
  selfSetting(st: SelfSettingState): SelfSetting {
    return st.selfSetting;
  },
};

const actions = {
  setSelfSetting(context: ActionContext<SelfSetting, {}>, opt: SelfSetting) {
    context.commit('setSelfSetting', opt);
  },
};

const mutations = {
  setSelfSetting(st: SelfSettingState, opt: SelfSetting) {
    st.selfSetting = opt;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
